import BigNumber from 'bignumber.js';

import { MoneyFormatter } from '@/helpers/formatters/money';
import {
  AmountMode,
  type TransactionAmount,
  TransactionAmountType
} from '@/references/axios/transactions/types';
import type { CurrencyCode } from '@/references/currency';
import { i18n } from '@/references/i18n';

/**
 * Specific formatter for on ramp
 * @param amount
 * @param currency
 * @param minDecimal shoulb be more than 1
 * @param maxDecimals
 * @param amountMode
 * @param showSign
 * @returns
 */
export function formatFloatAmount(
  amount: BigNumber.Value,
  currency: CurrencyCode,
  minDecimal: number,
  maxDecimals: number,
  amountMode: AmountMode = AmountMode.ValueOnly,
  showSign = false
): string {
  const initialAmountAsBigNumber = new BigNumber(new BigNumber(amount));
  let amountAsBigNumber = initialAmountAsBigNumber;
  let regularDecimals = minDecimal;
  let isReallySmall = true;
  for (let i = minDecimal; i <= maxDecimals; i++) {
    if (initialAmountAsBigNumber.isGreaterThan(new BigNumber('0.' + '0'.repeat(i - 1) + '1'))) {
      amountAsBigNumber = new BigNumber(initialAmountAsBigNumber.toFixed(i, BigNumber.ROUND_DOWN));
      regularDecimals = i;
      isReallySmall = false;
      break;
    } else {
      regularDecimals = i;
    }
  }

  if (amountAsBigNumber.isNaN()) {
    return Number.NaN.toString(10);
  }

  let formatted;
  const base = new MoneyFormatter(i18n.global.locale, currency);

  if (isReallySmall) {
    formatted = initialAmountAsBigNumber.toString(10).replace(/0.00([0]+)(\d{3})\d*$/, '0.00...$2');
    formatted = `${
      base.getCurrencySymbol(i18n.global.locale, {
        currency
      }).symbol
    }${formatted}`;
  } else {
    switch (amountMode) {
      case AmountMode.SymbolFirst:
        formatted = base.formatCurrency(amountAsBigNumber.toNumber(), 'short', {
          maximumFractionDigits: regularDecimals,
          minimumFractionDigits: amountAsBigNumber.isInteger() ? 0 : regularDecimals
        });
        break;
      case AmountMode.CodeLast:
        formatted = base.formatCurrency(amountAsBigNumber.toNumber(), 'short-explicit', {
          maximumFractionDigits: regularDecimals,
          minimumFractionDigits: amountAsBigNumber.isInteger() ? 0 : regularDecimals
        });
        break;
      default:
        formatted = base
          .formatCurrency(amountAsBigNumber.toNumber(), 'short', {
            maximumFractionDigits: regularDecimals,
            minimumFractionDigits: amountAsBigNumber.isInteger() ? 0 : regularDecimals,
            as: 'number'
          })
          .replace(base.getCurrencySymbol(i18n.global.locale, { currency }).symbol, '');
    }
  }

  if (showSign && amountAsBigNumber.isPositive()) {
    return `+${formatted}`;
  }

  return formatted;
}

/**
 * Formats amount with provided decimals and currency using current locale settings
 *
 * Takes accountMode into account to return the formatted string
 *
 * Will return {@link Number.NaN} as a string if provided {@link BigNumber.Value} could not be represented as JS Number instance
 * @param amount - an amount to format
 * @param decimals - decimals
 * @param currency - currency code
 * @param amountMode - mode to format into
 * @param showSign - force show sign
 */
export function formatAmount(
  amount: BigNumber.Value,
  decimals: number,
  currency: CurrencyCode,
  amountMode: AmountMode = AmountMode.ValueOnly,
  showSign = false
): string {
  const amountAsBigNumber = new BigNumber(
    new BigNumber(amount).toFixed(decimals, BigNumber.ROUND_DOWN)
  );
  if (amountAsBigNumber.isNaN()) {
    return Number.NaN.toString(10);
  }

  const base = new MoneyFormatter(i18n.global.locale, currency);
  let formatted;
  switch (amountMode) {
    case AmountMode.SymbolFirst:
      formatted = base.formatCurrency(amountAsBigNumber.toNumber(), 'short', {
        maximumFractionDigits: decimals,
        minimumFractionDigits: amountAsBigNumber.isInteger() ? 0 : decimals
      });
      break;
    case AmountMode.CodeLast:
      formatted = base.formatCurrency(amountAsBigNumber.toNumber(), 'short-explicit', {
        maximumFractionDigits: decimals,
        minimumFractionDigits: amountAsBigNumber.isInteger() ? 0 : decimals
      });
      break;
    default:
      formatted = base
        .formatCurrency(amountAsBigNumber.toNumber(), 'short', {
          maximumFractionDigits: decimals,
          minimumFractionDigits: amountAsBigNumber.isInteger() ? 0 : decimals,
          as: 'number'
        })
        .replace(base.getCurrencySymbol(i18n.global.locale, { currency }).symbol, '');
  }

  if (showSign && amountAsBigNumber.isPositive()) {
    return `+${formatted}`;
  }

  return formatted;
}

/**
 * Formats amount with provided decimals and currency using current locale settings
 *
 * Takes accountMode into account to return the formatted string
 * @param amount - an amount to format
 * @param decimals - decimals
 * @param currency - token symbol
 * @param amountMode - mode to format into
 * @param showSign - force show sign
 * @param minDecimals
 */
export function formatTokenAmount(
  amount: BigNumber.Value,
  decimals: number,
  currency: string,
  amountMode: Exclude<AmountMode, AmountMode.SymbolFirst> = AmountMode.ValueOnly,
  showSign = false,
  minDecimals = 0
): string {
  if (amount === '') {
    return '';
  }

  switch (amountMode) {
    case AmountMode.CodeLast:
      return `${formatToCryptoInner(amount, decimals, minDecimals, showSign)} ${currency}`;
    case AmountMode.ValueOnly:
    default:
      return formatToCryptoInner(amount, decimals, minDecimals, showSign);
  }
}

function formatToCryptoInner(
  amount: BigNumber.Value,
  maxDecimals: number,
  minDecimals: number = 0,
  showSign = false
): string {
  const bn = new BigNumber(amount);
  let mask = new BigNumber(10).pow(-maxDecimals);
  const processedMaxDecimals = bn.abs().gte(mask) ? maxDecimals : 18;
  const formatter = new Intl.NumberFormat(i18n.global.locale, {
    style: 'decimal',
    maximumFractionDigits: processedMaxDecimals,
    minimumFractionDigits: minDecimals,
    signDisplay: showSign ? 'exceptZero' : 'auto'
  });

  const formatted = formatter.format(
    new BigNumber(bn.toFixed(processedMaxDecimals, BigNumber.ROUND_DOWN)).toNumber()
  );
  if (bn.isZero() || bn.abs().gte(mask)) {
    return formatted;
  }

  const withoutTailZeros = formatted.replace(/\.?0*$/, '');
  const firstNonZeroDigit = withoutTailZeros.match(/\.0+([1-9])/);
  // we need to extract the first capture group match, so perform the checks
  // if this regex found something at all, and if yes, if it has the first group
  // match
  if (firstNonZeroDigit === null || firstNonZeroDigit.length < 2) {
    return withoutTailZeros;
  }

  if (bn.isNegative()) {
    mask = mask.multipliedBy(-1);
  }

  return mask.toFixed(maxDecimals);
}

export function formatTransactionTotalAmount(
  totalAmount: TransactionAmount,
  showPlusSign = false
): string {
  if (totalAmount.notAvailable) {
    return '';
  }

  if (totalAmount.type === TransactionAmountType.Token) {
    return formatTokenAmount(
      totalAmount.amount,
      totalAmount.decimals,
      totalAmount.currency,
      AmountMode.CodeLast,
      showPlusSign
    );
  }

  return formatAmount(
    totalAmount.amount,
    totalAmount.decimals,
    totalAmount.currency,
    AmountMode.SymbolFirst,
    showPlusSign
  );
}

export function formatTokenDetailedPrice(amount: BigNumber.Value, currency: CurrencyCode) {
  const formatter = new Intl.NumberFormat(i18n.global.locale, {
    style: 'currency',
    currency,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 4,
    minimumFractionDigits: 2,
    // roundingPriority exists and is widely available but ts is complaining https://github.com/microsoft/TypeScript/issues/52072
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    roundingPriority: 'morePrecision'
  });

  return formatter.format(Number(amount ?? 0));
}
