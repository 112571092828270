import { computed, reactive, watch } from 'vue';

import { useWindowFocus } from '@vueuse/core';
import { v4 } from 'uuid';

import { captureSentryException } from '@/logs/sentry';
import { APIError } from '@/references/axios/APIError';
import { TOASTS_TIMEOUT } from '@/references/constants';
import { ExpectedError } from '@/references/ExpectedError';
import { HHError } from '@/references/HHError';

const focused = useWindowFocus();

export type ClientEECode =
  | 'popupBlocked'
  | 'switchProviderNetwork'
  | 'userRejectNetworkChange'
  | 'userRejectAuth'
  | 'checkIban'
  | 'loadRecipients'
  | 'createRecipient'
  | 'editRecipient'
  | 'removeRecipient'
  | 'otcDataFetchFailed'
  | 'unknownError'
  | 'downloadReceipt'
  | 'attachTransaction'
  | 'avatarInvalidFormat'
  | 'avatarNotSelected'
  | 'saveAvatar'
  | 'sendOTP'
  | 'resendOTP'
  | 'methodNotSupported'
  | 'userRejectSign'
  | 'userRejectTransaction'
  | 'wrongWalletChain'
  | 'swapPermitData'
  | 'brrrAddressCheck'
  | 'brrrPermitData'
  | 'brrrApprove'
  | 'brrrWithPermit'
  | 'brrrWithPermit2'
  | 'brrrWithApprove'
  | 'brrrWithApproveEstimation'
  | 'brrrWithPermitEstimation'
  | 'brrrWithPermit2Estimation'
  | 'approveEstimation'
  | 'brrrPermitExpired'
  | 'brrrBackendCheckExpired';

export type ClientEECodeMapType = Record<ClientEECode, string>;

export const ClientEECodeMap: ClientEECodeMapType = {
  popupBlocked: 'errors.popupBlocked',
  switchProviderNetwork: 'errors.switchProviderNetwork',
  userRejectNetworkChange: 'errors.userRejectNetworkChange',
  userRejectAuth: 'errors.userRejectAuth',
  checkIban: 'errors.checkIban',
  loadRecipients: 'errors.loadRecipients',
  createRecipient: 'errors.createRecipient',
  editRecipient: 'errors.editRecipient',
  removeRecipient: 'errors.removeRecipient',
  otcDataFetchFailed: 'errors.somethingWentWrong',
  downloadReceipt: 'errors.downloadReceipt',
  unknownError: 'errors.somethingWentWrong',
  attachTransaction: 'errors.attachTransaction',
  avatarInvalidFormat: 'errors.avatarInvalidFormat',
  avatarNotSelected: 'errors.avatarNotSelected',
  saveAvatar: 'errors.saveAvatar',
  sendOTP: 'errors.sendOTP',
  resendOTP: 'errors.sendOTP',
  methodNotSupported: 'errors.somethingWentWrong',
  userRejectSign: 'errors.userRejectedSign',
  userRejectTransaction: 'errors.userRejectedTransaction',
  wrongWalletChain: 'errors.wrongWalletChain',
  brrrAddressCheck: 'errors.somethingWentWrong',
  brrrPermitData: 'errors.somethingWentWrong',
  brrrApprove: 'errors.somethingWentWrong',
  brrrWithPermit: 'errors.somethingWentWrong',
  brrrWithPermit2: 'errors.somethingWentWrong',
  brrrWithApprove: 'errors.somethingWentWrong',
  brrrWithApproveEstimation: 'errors.somethingWentWrong',
  brrrWithPermitEstimation: 'errors.somethingWentWrong',
  brrrWithPermit2Estimation: 'errors.somethingWentWrong',
  approveEstimation: 'errors.somethingWentWrong',
  brrrPermitExpired: 'errors.somethingWentWrong',
  brrrBackendCheckExpired: 'errors.somethingWentWrong',
  swapPermitData: 'errors.somethingWentWrong'
};

export type ErrorModal =
  | {
      type: 'Expected';
      code: ClientEECode;
    }
  | {
      type: 'Notify';
      message: string;
      emoji?: string;
    }
  | {
      type: 'Manual';
      message: string;
      emoji?: string;
    };

export interface Toast {
  id: string;
  error: ErrorModal;
}

const state = reactive<{
  items: Toast[];
}>({
  items: []
});

let timer: ReturnType<typeof setTimeout> | null = null;

const stopTimer = (): void => {
  if (!timer) {
    return;
  }

  clearInterval(timer);
  timer = null;
};

const startTimer = (): void => {
  stopTimer();

  if (!state.items.length) {
    return;
  }

  timer = setInterval(() => {
    state.items.pop();

    if (!state.items.length) {
      stopTimer();
    }
  }, TOASTS_TIMEOUT);
};

watch(focused, (value) => {
  if (value) {
    startTimer();
  } else {
    stopTimer();
  }
});

export const useErrorModal = () => {
  const toasts = computed((): Toast[] => state.items);

  const parseMessage = (
    e: string | ExpectedError | HHError | APIError | Error | unknown
  ): ErrorModal => {
    if (e instanceof ExpectedError) {
      const code = (e as ExpectedError<ClientEECode>).getCode();
      captureSentryException(e);
      return {
        type: 'Expected',
        code: code in ClientEECodeMap ? code : 'unknownError'
      };
    } else if (e instanceof HHError || e instanceof APIError) {
      captureSentryException(e);
      return {
        type: 'Expected',
        code: 'unknownError'
      };
    } else if (e instanceof Error) {
      captureSentryException(e);
      return {
        type: 'Expected',
        code: 'unknownError'
      };
    } else if (typeof e === 'string') {
      return {
        type: 'Notify',
        message: e
      };
    } else {
      captureSentryException(e);
      return {
        type: 'Expected',
        code: 'unknownError'
      };
    }
  };

  const pureToast = (error: ErrorModal) => {
    const id = v4();
    state.items.push({ id, error });
    startTimer();
  };

  const error = (e: string, emoji?: string) => {
    return pureToast({
      type: 'Manual',
      message: e,
      emoji
    });
  };

  const notify = (text: string, emoji?: string) => {
    return pureToast({
      type: 'Manual',
      message: text,
      emoji
    });
  };

  const auto = (e: string | HHError | APIError | Error | unknown) => {
    return pureToast(parseMessage(e));
  };

  const close = (id: string) => {
    state.items = state.items.filter((item) => item.id !== id);
    startTimer();
  };

  return {
    toasts,
    error,
    notify,
    auto,
    close
  };
};
