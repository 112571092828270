<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  color?: 'red' | 'green';
}

const props = withDefaults(defineProps<Props>(), {
  color: 'red'
});

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-indicator_color_${props.color}`]: true
  })
);
</script>

<template>
  <span class="ui-indicator" :class="classList"></span>
</template>

<style scoped>
.ui-indicator {
  display: block;
  width: 12px;
  height: 12px;
  border: 2px solid var(--color-white);
  border-radius: 50%;
}

.ui-indicator_color_red {
  background: var(--color-red);
}

.ui-indicator_color_green {
  background: var(--color-green);
}
</style>
