import { computed, reactive, readonly, toRefs } from 'vue';

import { datadogLogs } from '@datadog/browser-logs';
import { flush, setUser as sentrySetUser } from '@sentry/vue';

import { useAuthAPIService } from '@/composables/services/useAuthAPIService';
import { useKYC } from '@/composables/useKYC';
import { useSettings } from '@/composables/useSettings';
import { logger } from '@/logs/datadog';
import { captureSentryException } from '@/logs/sentry';
import { APIError } from '@/references/axios/APIError';
import type { AccountDTO } from '@/references/axios/auth/types';
import { MILLISECOND } from '@/references/constants';

export type SessionAccountData = {
  accountUid: string;
  name: string;
  avatarUrl: string;
  brrrInfoAccepted: boolean;
  brrrTermsAccepted: boolean;
};

const state = reactive<{
  authorized: boolean;
  triedToConnect: boolean;
  accountData: SessionAccountData | undefined;
}>({
  authorized: false,
  triedToConnect: false,
  accountData: undefined
});

const auth = async (login: string, password: string) => {
  const service = useAuthAPIService();
  try {
    const selfData = await service.login(login, password);
    updateAccountData(selfData.accountData);
    setLoggingUserContext(selfData.accountData);
    logger.info('authenticated');

    await Promise.all([useKYC().updateKYCStatus(), useKYC().updateSynapsSessionId()]);

    logger.info('loaded first KYC data');

    useSettings().setLimits(selfData.serverSettings);
    state.authorized = true;
    // useTokens().updateTokenList();
    // useTransactions().load();
  } catch (error) {
    if (error instanceof APIError && error.code === 'WRONG_OTP_CODE') {
      throw error;
    }

    captureSentryException(new Error('Failed to log in', { cause: error }));
    throw error;
  }
};

const sendOtpLogin = async (email: string) => {
  const service = useAuthAPIService();
  try {
    await service.sendOTP(email);
    logger.info('sent login otp');
  } catch (error) {
    captureSentryException(new Error('Failed to send OTP', { cause: error }));
    throw error;
  }
};

const resendOtpLogin = async (email: string) => {
  const service = useAuthAPIService();
  try {
    await service.sendOTP(email, false);
    logger.info('resent login otp');
  } catch (error) {
    captureSentryException(new Error('Failed to send OTP', { cause: error }));
    throw error;
  }
};

const logout = async () => {
  const service = useAuthAPIService();
  try {
    await service.logout();
  } catch (error) {
    state.accountData = undefined;
    captureSentryException(new Error('Failed to log out', { cause: error }));
  } finally {
    await flush(500 * MILLISECOND);
    state.accountData = undefined;
    logger.info('logged out');
    setLoggingUserContext(null);
    datadogLogs.clearUser();
    window.location.assign('/');
  }
};

const tryConnectCached = async () => {
  const service = useAuthAPIService();
  try {
    const selfData = await service.self();
    if (selfData == null) {
      return;
    }

    updateAccountData(selfData.accountData);
    setLoggingUserContext(selfData.accountData);
    logger.info('cache authenticated');

    await Promise.all([useKYC().updateKYCStatus(), useKYC().updateSynapsSessionId()]);

    logger.info('loaded first KYC data');

    useSettings().setLimits(selfData.serverSettings);
    state.authorized = true;
    // useTokens().updateTokenList();
    // useTransactions().load();
  } catch (error) {
    captureSentryException(new Error('Failed to get self', { cause: error }));
  } finally {
    state.triedToConnect = true;
  }
};

const isAuthed = computed(() => {
  return state.authorized && state.accountData !== undefined; // TODO add proper logic here
});

function setLoggingUserContext(accountData: AccountDTO | null): void {
  if (accountData === null) {
    sentrySetUser(null);
    datadogLogs.clearUser();
    return;
  }

  sentrySetUser({
    email: accountData.email,
    id: accountData.accountUid,
    username: accountData.name
  });
  datadogLogs.setUser({
    email: accountData.email,
    id: accountData.accountUid,
    name: accountData.name
  });
}

const avatarUrl = computed((): string | undefined => state.accountData?.avatarUrl);
const name = computed((): string | undefined => state.accountData?.name);

async function setAvatar(imageBlob: Blob, fileName: string | undefined): Promise<void> {
  const service = useAuthAPIService();
  const newAvatarImageUrl = await service.setAvatar(imageBlob, fileName);
  if (state.accountData !== undefined) {
    state.accountData.avatarUrl = newAvatarImageUrl;
  }
}

function updateAccountData(accountData: AccountDTO): void {
  state.accountData = accountData;
}

function handleSessionError(error: APIError) {
  logger.info(
    'Received an invalid session event. Session is likely expired at this point',
    undefined,
    error
  );
  state.authorized = false;
}

const brrrInfoAccepted = computed(() => {
  if (state.accountData === undefined) {
    return false;
  }

  return state.accountData.brrrInfoAccepted;
});

function setBrrrInfoAccepted(value: boolean): void {
  if (state.accountData === undefined) {
    return;
  }

  state.accountData.brrrInfoAccepted = value;
}

const brrrTermsAccepted = computed(() => {
  if (state.accountData === undefined) {
    return false;
  }

  return state.accountData.brrrTermsAccepted;
});

function setBrrrTermsAccepted(value: boolean): void {
  if (state.accountData === undefined) {
    return;
  }

  state.accountData.brrrTermsAccepted = value;
}

export const useAccount = () => {
  return {
    ...toRefs(readonly(state)),
    isAuthed,
    sendOtpLogin,
    resendOtpLogin,
    auth,
    logout,
    tryConnectCached,
    avatarUrl,
    name,
    setAvatar,
    handleSessionError,
    brrrInfoAccepted,
    setBrrrInfoAccepted,
    brrrTermsAccepted,
    setBrrrTermsAccepted
  };
};
