import type { Address } from 'viem';

import type { AddressMapKey } from '@/references/addresses';
import { HHError } from '@/references/HHError';
import type { Token } from '@/references/tokens';

import type { Network, NetworkInfo } from './network';

export type NetworksConfig = Record<
  Network,
  {
    addresses: Record<AddressMapKey, Address | undefined>;
    networkInfo: NetworkInfo;
    gasEstimationMultiplier: string;
    brrrToken?: Token;
    swapTargetForBrrr?: Token;
    tokensWithAdditionalGasBuffer?: Record<Address, string>;
    tokensWithSpecificMaxPermit2Allowance?: Record<Address, string>;
    tokensRequiresZeroAllowance?: Array<Address>;
  }
>;
let config: NetworksConfig | undefined;

const setConfig = (cfg: NetworksConfig): void => {
  config = cfg;
};

const getConfig = (): NetworksConfig | undefined => {
  return config;
};

export const getGasEstimationMultiplier = (network: Network) => {
  if (config === undefined) {
    throw new HHError('Config is not defined');
  }
  if (config[network] === undefined) {
    throw new HHError('Config for this network is not defined', { payload: { network } });
  }
  if (
    config[network].gasEstimationMultiplier === undefined ||
    config[network].gasEstimationMultiplier === ''
  ) {
    throw new HHError('Empty value of gasEstimationMultiplier', {
      payload: { network, fee: config[network].gasEstimationMultiplier }
    });
  }
  return config[network].gasEstimationMultiplier;
};

export { setConfig, getConfig };
