import axios from 'axios';

export class HHError extends Error {
  shortMessage: string;
  payload: Record<string, unknown>;
  override name = 'HHError';

  constructor(
    message: string,
    { cause, payload }: { cause?: unknown; payload?: Record<string, unknown> } = {}
  ) {
    payload = payload ?? {};
    if (cause !== undefined && axios.isAxiosError(cause)) {
      payload = { ...payload, axiosErrorPayload: cause.toJSON() };
    }

    const fullMessage =
      Object.entries(payload).length < 1 ? message : `${message}: ${JSON.stringify(payload)}`;
    super(fullMessage, { cause });
    this.shortMessage = message;
    this.payload = payload;
  }

  public setPayload(payload: Record<string, unknown>): this {
    this.payload = payload;
    this.message = `${this.shortMessage}: ${JSON.stringify(payload)}`;
    return this;
  }

  public addPayload(payload: Record<string, unknown>): this {
    this.payload = { ...this.payload, ...payload };
    this.message = `${this.shortMessage}: ${JSON.stringify(payload)}`;
    return this;
  }
  public getPayload(): Record<string, unknown> {
    return this.payload;
  }

  public getWrappableMessage(): string {
    return this.shortMessage;
  }
}
