export const backgroundColors = [
  '#FF2857',
  '#FF2E92',
  '#EF2EF8',
  '#9646FE',
  '#523EFB',
  '#0178FF',
  '#00A0F0',
  '#4BA6F8',
  '#01B1C8',
  '#00B276',
  '#00C03F',
  '#62C600',
  '#F5A700',
  '#FF9000',
  '#FE5E00',
  '#FF2732',
  '#D0AA45',
  '#C97E43',
  '#002F5F',
  '#999999'
];

export const placeholderBackgroundColor = (value: string | undefined): string => {
  const text = value || 'A';
  const startPoint = 'A'.charCodeAt(0);
  const charCode = Math.abs(text.toUpperCase().charCodeAt(0) - startPoint);
  return backgroundColors[charCode % backgroundColors.length];
};

export function adjustTextColorToContrast(textColor: string, backgroundColor: string): string {
  const contrastThreshold = 4.5;

  function hexToRgb(hex: string): { r: number; g: number; b: number } {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : { r: 0, g: 0, b: 0 };
  }

  function luminance(r: number, g: number, b: number): number {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }

  function contrast(
    rgb1: { r: number; g: number; b: number },
    rgb2: { r: number; g: number; b: number }
  ): number {
    const lum1 = luminance(rgb1.r, rgb1.g, rgb1.b) + 0.05;
    const lum2 = luminance(rgb2.r, rgb2.g, rgb2.b) + 0.05;
    return lum1 > lum2 ? lum1 / lum2 : lum2 / lum1;
  }

  function lightenOrDarken(color: string, amount: number): string {
    const rgb = hexToRgb(color);
    rgb.r = Math.min(255, Math.max(0, rgb.r + amount));
    rgb.g = Math.min(255, Math.max(0, rgb.g + amount));
    rgb.b = Math.min(255, Math.max(0, rgb.b + amount));
    return `#${((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b).toString(16).slice(1)}`;
  }

  let textRgb = hexToRgb(textColor);
  const bgRgb = hexToRgb(backgroundColor);

  let attemptLighten = textColor;
  let attemptDarken = textColor;

  while (contrast(textRgb, bgRgb) < contrastThreshold) {
    attemptLighten = lightenOrDarken(attemptLighten, 10);
    const lightenContrast = contrast(hexToRgb(attemptLighten), bgRgb);

    attemptDarken = lightenOrDarken(attemptDarken, -10);
    const darkenContrast = contrast(hexToRgb(attemptDarken), bgRgb);

    if (lightenContrast >= contrastThreshold || darkenContrast >= contrastThreshold) {
      return lightenContrast > darkenContrast ? attemptLighten : attemptDarken;
    }

    textRgb = lightenContrast > darkenContrast ? hexToRgb(attemptLighten) : hexToRgb(attemptDarken);
  }

  return textColor;
}
