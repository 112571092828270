import axios, { type AxiosResponse } from 'axios';

import type { HHAPIErrorResponse } from '@/references/axios/types';

export class APIError extends Error {
  override name = 'APIError';

  public description: string;
  public code: string;
  public payload: Record<string, unknown> | undefined = undefined;
  public status: number | undefined = undefined;

  constructor(
    description: string,
    code: string,
    {
      payload,
      cause,
      status
    }: {
      payload?: Record<string, unknown>;
      cause?: unknown;
      status?: number;
    } = {}
  ) {
    const _description = description || 'no error description';
    const _code = `(${code || 'no error code'})`;

    super(`${_description} ${_code}`, { cause });
    this.description = description;
    this.code = code;
    this.payload = payload;
    this.status = status;
  }

  static fromErrorResponse<ErrorPayload>(
    response: AxiosResponse<HHAPIErrorResponse<ErrorPayload>>
  ): APIError {
    return new APIError(response.data.error, response.data.errorCode, {
      payload: {
        ...response.data.payload,
        status: response.status,
        url: axios.getUri(response.config)
      },
      status: response.status
    });
  }
}
