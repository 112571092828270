import type { Address } from '@/references/base';
import type { CurrencyCode } from '@/references/currency';
import type { Token } from '@/references/tokens';

export const enum TransactionAmountType {
  Token = 'token',
  Native = 'native'
}

type TransactionAmountNative = {
  skeleton?: boolean;
  amount: string;
  decimals: number;
  type: TransactionAmountType.Native;
  currency: CurrencyCode;
  notAvailable?: boolean;
};

type TransactionAmountToken = {
  skeleton?: boolean;
  amount: string;
  decimals: number;
  type: TransactionAmountType.Token;
  currency: string;
  iconUrl?: string;
  notAvailable?: boolean;
};

export type TransactionAmount = TransactionAmountNative | TransactionAmountToken;

export const enum Direction {
  In = 'in',
  Out = 'out'
}

export const enum DetailsItemType {
  Status = 'status',
  Text = 'text',
  LocalizedText = 'localizedText',
  Link = 'link',
  Amount = 'amount',
  TokenAmount = 'tokenAmount',
  Rate = 'rate',
  TokenRate = 'tokenRate',
  ReversedTokenRate = 'reversedTokenRate',
  TokenToTokenRate = 'tokenToTokenRate',
  Date = 'date',
  Duration = 'duration',
  Fee = 'fee'
}

type DetailsItemBase = {
  type: unknown;
  skeleton?: boolean;
  marginBottom?: boolean;
  key: string;
  value: unknown;
  iconUrl?: string;
};

export const enum AmountMode {
  SymbolFirst = 'symbolFirst',
  CodeLast = 'codeLast',
  ValueOnly = 'valueOnly'
}

export type DetailsItemStatus = DetailsItemBase & {
  type: DetailsItemType.Status;
  value: string;
};

export type DetailsItemText = DetailsItemBase & {
  type: DetailsItemType.Text;
  value: string;
};

export type DetailsItemLink = DetailsItemBase & {
  type: DetailsItemType.Link;
  value: {
    text: string;
    link: string;
  };
};

export type DetailsItemLocalizedText = DetailsItemBase & {
  type: DetailsItemType.LocalizedText;
  value: string;
};

export type DetailsItemAmount = DetailsItemBase & {
  type: DetailsItemType.Amount;
  value: {
    amount: string;
    decimals: number;
    currency: CurrencyCode;
    amountMode: AmountMode.SymbolFirst | AmountMode.CodeLast;
  };
};

export type DetailsItemTokenAmount = DetailsItemBase & {
  type: DetailsItemType.TokenAmount;
  value: {
    amount: string;
    decimals: number;
    currency: string;
    amountMode: AmountMode.CodeLast | AmountMode.ValueOnly;
  };
};

export type DetailsItemRate = DetailsItemBase & {
  type: DetailsItemType.Rate;
  value: {
    amount: string;
    decimals: number;
    inputCurrency: CurrencyCode;
    outputCurrency: CurrencyCode;
  };
};

export type DetailsItemTokenRate = DetailsItemBase & {
  type: DetailsItemType.TokenRate;
  value: {
    amount: string;
    decimals: number;
    inputCurrency: string;
    outputCurrency: CurrencyCode;
  };
};

export type DetailsItemReversedTokenRate = DetailsItemBase & {
  type: DetailsItemType.ReversedTokenRate;
  value: {
    amount: string;
    decimals: number;
    inputCurrency: CurrencyCode;
    outputCurrency: string;
  };
};

export type DetailsItemTokenToTokenRate = DetailsItemBase & {
  type: DetailsItemType.TokenToTokenRate;
  value: {
    amount: string;
    decimals: number;
    inputCurrency: string;
    outputCurrency: string;
  };
};

export const enum DetailsItemDateValueFormat {
  DateTime = 'dateTime',
  DateShort = 'dateShort'
}

export type DetailsItemDate = DetailsItemBase & {
  type: DetailsItemType.Date;
  value: {
    timestamp: number;
    format: DetailsItemDateValueFormat;
  };
};

export type DetailsItemDuration = DetailsItemBase & {
  type: DetailsItemType.Duration;
  value: number;
};

export type DetailsItemFee = DetailsItemBase & {
  type: DetailsItemType.Fee;
  value: {
    titleKey: string;
    displayedItem: DetailsItemAmount | DetailsItemTokenAmount;
    items: (DetailsItemAmount | DetailsItemTokenAmount)[];
  };
};

export type DetailsItem =
  | DetailsItemStatus
  | DetailsItemText
  | DetailsItemLink
  | DetailsItemAmount
  | DetailsItemTokenAmount
  | DetailsItemRate
  | DetailsItemTokenRate
  | DetailsItemReversedTokenRate
  | DetailsItemTokenToTokenRate
  | DetailsItemDate
  | DetailsItemLocalizedText
  | DetailsItemFee;

export type StatusDetailsItem =
  | DetailsItemText
  | DetailsItemDate
  | DetailsItemLocalizedText
  | DetailsItemDuration;

export enum FailReasonType {
  Text = 'text',
  LocalizedText = 'localizedText'
}

export type FailReason = {
  type: FailReasonType;
  value: string;
};

export type TransactionBase = {
  id: string;
  type: unknown;
  status: string;
  direction: Direction;
  iconUrl?: string;
  createdAt: number;
  clearedAt: number;
  flags: {
    pending?: boolean;
    failed?: boolean;
    bordered?: boolean;
    openable?: boolean;
    strikethrough?: boolean;
    muted?: boolean;
    incoming?: boolean;
    onChain?: boolean;
  };
  totalAmount: TransactionAmount;
  details: DetailsItem[];
  statusDetails: StatusDetailsItem[];
  expectedSuccessAt?: number;
  failReason?: FailReason;
};

export enum QuoteStatus {
  AwaitsCrypto = 'awaits-crypto',
  Processing = 'processing',
  Completed = 'completed',
  Failed = 'failed'
}

export type BeneficiaryQuote = {
  id: string;
  beneficiary: string;
  iban: string;
  bic: string;
};

export type Quote = {
  quoteId: string;
  status: QuoteStatus;
  transactionId?: string;
  transactionHash?: Address;
  accountUid: string;
  createdTs: number;
  expiredTs: number;
  cryptoReceivedTs?: number;
  resolvedTs?: number;
  expectedDeliveryTs?: number;
  beneficiary: BeneficiaryQuote;
  eurAmount: string;
  tokenAmount: string;
  token: Token;
  receiverWalletAddress: string;
  reference: string;
  transactionFee: string;
  networkFee: string;
  transferFee: string;
  failReason?: string;
};

export const enum TransactionType {
  OTC = 'otc'
}

export const enum TransactionStatusOTC {
  Pending = 'otc.pending',
  Processing = 'otc.processing',
  Failed = 'otc.failed',
  Completed = 'otc.completed'
}

export type TransactionOTCBeneficiary = {
  name: string;
};

export type TransactionOTC = TransactionBase & {
  //override base fields
  type: TransactionType.OTC;
  status: TransactionStatusOTC;
  direction: Direction.In;
  totalAmount: TransactionAmountNative;
  //custom fields
  beneficiary: TransactionOTCBeneficiary;
  transactionId?: string;
  transactionHash?: Address;
  explorerLinkUrl?: string;
  quote: Quote;
};

export type Transaction = TransactionOTC;

export type StatementDownloadResult = { filename: string; blob: Blob };
