import { computed } from 'vue';

import { useMediaQuery } from '@vueuse/core';

enum ViewBreakpoints {
  XL = 1280,
  LG = 1024,
  MD = 920,
  SM = 375,
  XS = 360
}

const lessThanXL = useMediaQuery(`(max-width: ${ViewBreakpoints.XL - 1}px)`);
const lessThanLG = useMediaQuery(`(max-width: ${ViewBreakpoints.LG - 1}px)`);
const lessThanMD = useMediaQuery(`(max-width: ${ViewBreakpoints.MD - 1}px)`);
const lessThanMDLegacy = useMediaQuery('(max-width: 879px)');
const lessThanSM = useMediaQuery(`(max-width: ${ViewBreakpoints.SM - 1}px)`);
const lessThanXS = useMediaQuery(`(max-width: ${ViewBreakpoints.XS - 1}px)`);

const isLegacyMobile = computed((): boolean => lessThanMDLegacy.value);

const isMobile = computed((): boolean => lessThanMD.value);

export const useViewBreakpoints = () => {
  return { lessThanXL, lessThanLG, lessThanMD, lessThanSM, lessThanXS, isLegacyMobile, isMobile };
};
