<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useAccount } from '@/composables/useAccount';
import { useBrrr } from '@/composables/useBrrr';
import { useTimeUnix } from '@/composables/useTimeUnix';
import { useViewBreakpoints } from '@/composables/useViewBreakpoints';
import { formatTokenAmount } from '@/helpers/formatters';
import { toDaysHoursMinutesSeconds, toHHMMSS } from '@/helpers/time';
import { AmountMode } from '@/references/axios/transactions/types';
import { RouteNames } from '@/router';
import type { SettingsLinkItem } from '@/views/SettingsView.types';

import UiButton from '@/components/ui/UiButton.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiLink from '@/components/ui/UiLink.vue';
import { type UiListPairGroup } from '@/components/ui/UiListPair.types';
import UiListPair from '@/components/ui/UiListPair.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

const {
  loaded,
  available,
  purchased,
  lastPurchase,
  startUpdating,
  stopUpdating,
  brrrToken,
  isBrrrAvailable,
  activeUntilTs,
  activeSinceTs,
  infoAccepted,
  termsAccepted
} = useBrrr();

const { t } = useI18n();
const { isAuthed } = useAccount();
const { push } = useRouter();
const { lessThanSM, lessThanXL } = useViewBreakpoints();

const { tsNow } = useTimeUnix();
const remainingTimeText = computed(() => {
  const delta = Math.max(activeUntilTs.value - tsNow.value, 0);
  if (delta === 0) {
    return undefined;
  }

  return t('remainingTime', { time: toDaysHoursMinutesSeconds(delta) });
});

const items = computed((): SettingsLinkItem[] => [
  {
    name: t('brrrWhitepaper'),
    icon: 'export',
    href: 'https://github.com/holyheld/BRRR/blob/f6b6eab3b9582120a1329b47acd5e7cc5cd94264/BRRR.pdf'
  },
  {
    name: t('brrrToken'),
    icon: 'export',
    href: 'https://github.com/holyheld/BRRR/blob/f6b6eab3b9582120a1329b47acd5e7cc5cd94264/BRRR.pdf' // TODO: change
  },
  {
    name: t('termsAndConditions'),
    icon: 'export',
    href: 'https://github.com/holyheld/BRRR/blob/f6b6eab3b9582120a1329b47acd5e7cc5cd94264/BRRR.pdf' // TODO: change
  }
]);

const availableText = computed((): string => {
  return formatTokenAmount(
    available.value,
    brrrToken.value.decimals,
    brrrToken.value.symbol,
    AmountMode.ValueOnly,
    false
  );
});

const purchasedText = computed((): string => {
  return formatTokenAmount(
    purchased.value,
    brrrToken.value.decimals,
    brrrToken.value.symbol,
    AmountMode.ValueOnly,
    false
  );
});

const lastPurchaseText = computed((): string | null => {
  if (lastPurchase.value === null) {
    return null;
  }

  return formatTokenAmount(
    lastPurchase.value,
    brrrToken.value.decimals,
    brrrToken.value.symbol,
    AmountMode.ValueOnly,
    false
  );
});

const stats = computed((): UiListPairGroup[] => {
  return [
    {
      name: t('brrrStats'),
      items: [
        {
          label: t('available'),
          value: availableText.value,
          token: brrrToken.value,
          animated: true,
          blink: !loaded.value
        },
        {
          label: t('purchased'),
          value: purchasedText.value,
          token: brrrToken.value,
          animated: true,
          blink: !loaded.value
        },
        {
          label: t('lastPurchase'),
          value: lastPurchaseText.value,
          token: brrrToken.value,
          positive: lastPurchaseText.value !== '',
          animated: true,
          blink: !loaded.value
        }
      ]
    }
  ];
});

const buttonText = computed(() => {
  if (!isBrrrAvailable.value) {
    return t('noAvailableBrrr');
  }

  if (tsNow.value < activeSinceTs.value && !lessThanSM.value) {
    const delta = Math.max(activeSinceTs.value - tsNow.value, 0);
    return t('purchaseIn', { time: toHHMMSS(delta) });
  }

  return t('purchase');
});

const isButtonDisabled = computed(() => {
  if (!isBrrrAvailable.value) {
    return true;
  }

  if (tsNow.value < activeSinceTs.value) {
    return true;
  }

  if (tsNow.value > activeUntilTs.value) {
    return true;
  }

  return false;
});

function onPurchaseClick() {
  if (isButtonDisabled.value) {
    return;
  }

  if (!infoAccepted.value) {
    push({ name: RouteNames.BrrrInfo });
    return;
  }

  if (!termsAccepted.value) {
    push({ name: RouteNames.BrrrTermsAndConditions });
    return;
  }

  push({ name: RouteNames.BrrrBuyToken });
}

onMounted(startUpdating);
onBeforeUnmount(stopUpdating);

watch(isAuthed, (nv) => {
  if (!nv) {
    stopUpdating();
    return;
  }

  startUpdating();
});
</script>

<template>
  <section class="home-info-brrr">
    <div class="home-info-brrr__card">
      <img
        class="home-info-brrr__card-image"
        :srcset="`@/assets/images/brrr@1x.jpg 1x, @/assets/images/brrr@2x.jpg 2x`"
        :src="'@/assets/images/brrr@1x.jpg'"
        alt=""
      />
      <div class="home-info-brrr__card-header">
        <UiTitle class="home-info-brrr__card-title" :level="2" size="lg" weight="medium">
          {{ t('brrr') }}
        </UiTitle>
        <UiText
          v-if="remainingTimeText !== undefined"
          class="home-info-brrr__card-time"
          size="xs"
          weight="medium"
          align="right"
        >
          {{ remainingTimeText }}
        </UiText>
      </div>
      <div class="home-info-brrr__card-footer">
        <UiButton
          class="home-info-brrr__button"
          size="sm"
          mod="transparent"
          :disabled="isButtonDisabled"
          @click="onPurchaseClick"
        >
          {{ buttonText }}
        </UiButton>
      </div>
    </div>

    <UiListPair
      v-if="isBrrrAvailable"
      class="home-info-brrr__stats"
      mod="table"
      :details="stats"
      :inverted="!lessThanXL"
    />

    <ul v-if="items.length" class="home-info-brrr__list">
      <li v-for="item in items" :key="item.name" class="home-info-brrr__item">
        <UiLink
          class="home-info-brrr__link"
          :icon="item.icon"
          :icon-size="24"
          :gap="16"
          weight="medium"
          :route="item.route ? { name: item.route } : undefined"
          :href="item.href"
          target="_blank"
          @click="item.handler"
        >
          {{ item.name }}

          <template #postfix>
            <UiIcon class="home-info-brrr__arrow" name="arrow-right-small" :size="16" muted />
          </template>
        </UiLink>
      </li>
    </ul>
  </section>
</template>

<style scoped>
.home-info-brrr__card {
  position: relative;
  display: block;
  max-width: 366px;
  margin: 0 auto 40px;
  overflow: hidden;
  color: var(--color-white);
  background-color: var(--color-black);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 12px;
}

.home-info-brrr__card::before {
  display: block;
  padding: 56.830601% 0 0;
  content: '';
}

.home-info-brrr__card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
}

.home-info-brrr__card-header,
.home-info-brrr__card-footer {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

.home-info-brrr__card-header {
  top: 0;
}

.home-info-brrr__card-title {
  margin: 0 24px 0 0;
  color: var(--color-white);
}

.home-info-brrr__card-footer {
  bottom: 0;
}

.home-info-brrr__balance {
  min-width: 0;
  margin: 0 24px 0 0;
}

.home-info-brrr__balance-label {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-info-brrr__balance-value {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.home-info-brrr__balance-value-icon {
  margin: 0 4px 0 0;
}

.home-info-brrr__balance-value-text {
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-info-brrr__stats {
  margin: 0 auto 40px;
}

.home-info-brrr__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.home-info-brrr__item:not(:last-child) {
  margin: 0 0 16px;
}

.home-info-brrr__link {
  width: 100%;
  padding: 8px 0;
}

.home-info-brrr__arrow {
  flex-shrink: 0;
  margin: 0 0 0 auto;
}

.home-info-brrr__button {
  width: auto;
}
</style>
