<script setup lang="ts">
import { computed } from 'vue';

import settingsIcon from '@/assets/icons/settings.svg';
import { useAccount } from '@/composables/useAccount';
import { useViewBreakpoints } from '@/composables/useViewBreakpoints';
import { fade } from '@/helpers/motion';
import { RouteNames } from '@/router';

import AvatarImage from '@/components/AvatarImage.vue';
import HomeInfo from '@/components/HomeInfo.vue';
import UiContainer from '@/components/ui/UiContainer.vue';
import UiIcon from '@/components/ui/UiIcon.vue';

const { isMobile } = useViewBreakpoints();
const { avatarUrl } = useAccount();

const isUserVisible = computed((): boolean => {
  return isMobile.value;
});
</script>

<template>
  <section class="home-view">
    <div v-if="isUserVisible" v-motion="fade" class="home-view__header">
      <UiContainer>
        <RouterLink class="home-view__settings-link" :to="{ name: RouteNames.Settings }">
          <AvatarImage v-if="avatarUrl" :src="avatarUrl" />
          <UiIcon v-else :name="settingsIcon" :size="24" />
        </RouterLink>
      </UiContainer>
    </div>

    <HomeInfo v-motion="fade" />
  </section>
</template>

<style scoped>
.home-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.home-view__header {
  display: flex;
  margin: 0 0 40px;
}
</style>
