<script setup lang="ts">
import { ref } from 'vue';

import { type Placement, Tooltip } from 'floating-vue';

import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

interface Props {
  shown?: boolean;
  distance?: number;
  position?: Placement;
  title?: string;
  description?: string;
  flip?: boolean;
  disabled?: boolean;
  customClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  shown: false,
  distance: 0,
  position: 'auto',
  title: '',
  description: '',
  flip: true,
  disabled: false,
  customClass: undefined
});

const emit = defineEmits<(event: 'hide') => void>();

const tooltipComponent = ref<typeof Tooltip>();

function hide(): void {
  tooltipComponent.value?.hide();
}

function show(): void {
  tooltipComponent.value?.show();
}

defineExpose({
  hide,
  show
});
</script>

<template>
  <Tooltip
    ref="tooltipComponent"
    class="ui-tip"
    :shown="props.shown"
    :auto-hide="false"
    :placement="props.position"
    :distance="props.distance"
    :arrow-padding="24"
    :flip="props.flip"
    :disabled="props.disabled"
    :popper-class="props.customClass"
    :popper-hide-triggers="['click']"
    @hide="emit('hide')"
  >
    <slot />

    <template #popper>
      <UiTitle v-if="props.title" class="ui-tip__title" :level="3">
        {{ props.title }}
      </UiTitle>
      <UiText class="ui-tip__description" size="sm" weight="medium" tag="p" muted>
        {{ props.description }}
      </UiText>
    </template>
  </Tooltip>
</template>

<style scoped>
.ui-tip__title {
  color: var(--color-white);
}
</style>
