import { computed, reactive, shallowRef, toRef } from 'vue';

import { useTimeoutPoll } from '@vueuse/core';
import axios from 'axios';

import { useBrrrAPIService } from '@/composables/services/useBrrrAPIService';
import { useAccount } from '@/composables/useAccount';
import { useSettings } from '@/composables/useSettings';
import { assert } from '@/helpers/assert';
import { fromWei, greaterThan } from '@/helpers/bigmath';
import { addSentryBreadcrumb, captureSentryException } from '@/logs/sentry';
import type { GetCachedResponseResponse, GlobalState } from '@/references/axios/brrr/types';
import { SECOND } from '@/references/constants';
import { HHError } from '@/references/HHError';
import { Network } from '@/references/network';
import type { Token } from '@/references/tokens';
import { getBrrrToken } from '@/references/tokens';

const GET_TERMS_URL = 'https://storage.googleapis.com/app_assets_cdn/resources/terms_brrr.md';
const TIMEOUT = 5 * SECOND;

const state = reactive<{
  lastHash: string;
  available: string;
  purchased: string;
  lastPurchase: string | null;
  loading: boolean;
  loaded: boolean;
}>({
  lastHash: '',
  available: '0',
  purchased: '0',
  lastPurchase: null,
  loading: false,
  loaded: false
});

const termsState = reactive<{
  loading: boolean;
  loaded: boolean;
  terms: string;
}>({
  loading: false,
  loaded: false,
  terms: ''
});

const brrrToken = shallowRef<Token>({
  address: '0x0',
  name: 'BRRR',
  network: Network.ethereum,
  symbol: 'BRRR',
  iconURL: '',
  decimals: 18
});

const isBrrrEnabled = computed(() => {
  return true;
});

const hasBrrrToBuy = computed(() => greaterThan(state.available, 0));

const isBrrrAvailable = computed(() => {
  return isBrrrEnabled.value && hasBrrrToBuy.value;
});

async function checkIfStillAvailable(): Promise<boolean> {
  // intentionally skip timestamp validations here, as client time is unreliable
  if (!greaterThan(state.available, 0)) {
    return false;
  }

  return true;
}

async function getGlobalState(
  signal?: AbortSignal
): Promise<Required<GetCachedResponseResponse<'/v2/global/brrr', GlobalState>>> {
  const service = useBrrrAPIService();
  try {
    state.loading = true;
    const data = await service.getGlobalState(signal);
    assert(
      data.domains['/v2/global/brrr'] !== undefined &&
        data.domains['/v2/global/brrr']?.data !== undefined,
      new HHError(
        'Received unexpected response: /v2/global/brrr is undefined or internal data is undefined',
        { payload: data }
      )
    );
    return data;
  } finally {
    state.loading = false;
  }
}

function handleGlobalState(data: GetCachedResponseResponse<'/v2/global/brrr', GlobalState>): void {
  const dbWrapped = data.domains['/v2/global/brrr'];
  if (dbWrapped === undefined) {
    return;
  }

  if (state.lastHash === dbWrapped?.hash) {
    // skip update to the same data
    return;
  }

  const internal: GlobalState = dbWrapped.data;
  if (internal === undefined) {
    // make ts happy -- already checked in other scope
    return;
  }

  state.lastHash = dbWrapped.hash;
  state.available = fromWei(internal.available, brrrToken.value.decimals);
  state.purchased = fromWei(internal.purchased, brrrToken.value.decimals);
  state.lastPurchase = internal.lastPurchase
    ? fromWei(internal.lastPurchase, brrrToken.value.decimals)
    : null;
  state.loaded = true;
}

const poller = useTimeoutPoll(
  async () => {
    let response;
    try {
      response = await getGlobalState();
    } catch (error) {
      if (axios.isCancel(error)) {
        // ignore cancellation errors
        return;
      }

      captureSentryException(new HHError('Failed to update BRRR global state', { cause: error }));
      return;
    }

    handleGlobalState(response);
  },
  SECOND,
  { immediate: false }
);

function startUpdating() {
  if (!poller.isActive.value) {
    poller.resume();
  }
}

function stopUpdating() {
  poller.pause();
}

async function initializeGlobalData(): Promise<void> {
  let response;
  try {
    response = await getGlobalState();
  } catch (error) {
    if (axios.isCancel(error)) {
      // ignore cancellation errors
      return;
    }

    captureSentryException(new HHError('Failed to initialize BRRR global state', { cause: error }));
    return;
  }

  handleGlobalState(response);
  addSentryBreadcrumb({
    level: 'info',
    message: 'BRRR global state is initialized'
  });
}

async function registerHandlers(): Promise<void> {
  await initializeGlobalData();
  brrrToken.value = getBrrrToken(Network.ethereum);
}

async function acceptInfo(): Promise<void> {
  const service = useBrrrAPIService();
  await service.acceptInfo();
  useAccount().setBrrrInfoAccepted(true);
}

async function acceptTerms(): Promise<void> {
  const service = useBrrrAPIService();
  await service.acceptTermsAndConditions();
  useAccount().setBrrrTermsAccepted(true);
}

async function fetchTerms(): Promise<void> {
  if (termsState.loaded) {
    return;
  }

  try {
    termsState.loading = true;
    const terms = await axios.get<string>(GET_TERMS_URL, {
      responseType: 'text',
      timeout: TIMEOUT
    });
    termsState.terms = terms.data;
    termsState.loaded = true;
  } catch (error) {
    captureSentryException(new HHError('Failed to fetch terms', { cause: error }));
  } finally {
    termsState.loading = false;
  }
}

export function useBrrr() {
  const { brrrInfoAccepted, brrrTermsAccepted } = useAccount();
  const { minBrrrAmountEur, brrrStartsAt, brrrStopsAt } = useSettings();

  return {
    available: toRef(state, 'available'),
    purchased: toRef(state, 'purchased'),
    lastPurchase: toRef(state, 'lastPurchase'),
    loading: toRef(state, 'loading'),
    loaded: toRef(state, 'loaded'),
    infoAccepted: brrrInfoAccepted,
    termsAccepted: brrrTermsAccepted,
    acceptInfo,
    acceptTerms,
    startUpdating,
    stopUpdating,
    registerHandlers,
    brrrToken,
    isBrrrEnabled,
    hasBrrrToBuy,
    isBrrrAvailable,
    termsLoading: toRef(termsState, 'loading'),
    termsLoaded: toRef(termsState, 'loaded'),
    terms: toRef(termsState, 'terms'),
    fetchTerms,
    minBrrrAmount: minBrrrAmountEur,
    activeSinceTs: brrrStartsAt,
    activeUntilTs: brrrStopsAt,
    checkIfStillAvailable
  };
}
