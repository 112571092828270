<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useSlots } from 'vue';

import { useWindowScroll } from '@vueuse/core';

import arrowLeftIcon from '@/assets/icons/arrow-left.svg';
import crossLargeIcon from '@/assets/icons/cross-large.svg';
import tipIcon from '@/assets/icons/tip.svg';

import { InnerLayoutButtonType } from '@/components/layouts/InnerLayout.types';
import TipModal from '@/components/TipModal.vue';
import UiButtonIcon from '@/components/ui/UiButtonIcon.vue';
import UiContainer from '@/components/ui/UiContainer.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

interface Props {
  title?: string;
  ghostTitle?: string;
  buttonType?: InnerLayoutButtonType | null;
  tipTitle?: string;
  tipDescription?: string;
  backgroundOnlyWhenMove?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  ghostTitle: '',
  buttonType: null,
  tipTitle: '',
  tipDescription: '',
  backgroundOnlyWhenMove: false
});

const emit = defineEmits<{
  (event: 'back'): void;
}>();

const state = reactive<{
  isTipModalOpened: boolean;
}>({
  isTipModalOpened: false
});

const { y } = useWindowScroll();
const slots = useSlots();

const isTipVisible = computed((): boolean => !!props.tipTitle && !!props.tipDescription);

const isScrolled = computed((): boolean => y.value > 0);

const isGhostTitleVisible = computed((): boolean => !!props.ghostTitle && y.value > 80);

const isHeaderVisible = computed(
  (): boolean =>
    !!props.buttonType ||
    isTipVisible.value ||
    !!props.ghostTitle ||
    !!props.title ||
    !!slots.header
);
</script>

<template>
  <div
    class="inner-layout"
    :class="{
      'inner-layout_moved': isScrolled,
      'inner-layout_background-only-when-move': props.backgroundOnlyWhenMove
    }"
  >
    <header v-if="isHeaderVisible" class="inner-layout__header">
      <UiContainer class="inner-layout__container">
        <div class="inner-layout__header-inner">
          <Transition>
            <UiButtonIcon
              v-if="props.buttonType"
              class="inner-layout__button inner-layout__button_back"
              :icon="
                props.buttonType === InnerLayoutButtonType.Back ? arrowLeftIcon : crossLargeIcon
              "
              mod="transparent"
              @click="emit('back')"
            />
          </Transition>

          <UiTitle v-if="props.title" class="inner-layout__title" :level="1" size="md">
            {{ props.title }}
          </UiTitle>
          <slot v-else name="header" />

          <Transition>
            <UiText
              v-if="isGhostTitleVisible"
              class="inner-layout__ghost-title"
              weight="bold"
              :size="['md', 'sm']"
              align="center"
            >
              {{ props.ghostTitle }}
            </UiText>
          </Transition>

          <Transition>
            <UiButtonIcon
              v-if="isTipVisible"
              class="inner-layout__button inner-layout__button_tip"
              :icon="tipIcon"
              mod="transparent-muted"
              @click="state.isTipModalOpened = true"
            />
            <slot v-else name="tip" />
          </Transition>
        </div>
      </UiContainer>
    </header>

    <main class="inner-layout__main">
      <UiContainer class="inner-layout__container">
        <slot />
      </UiContainer>
    </main>

    <TipModal v-model="state.isTipModalOpened" :title="props.tipTitle" icon-mod="question">
      <slot name="tipDescription">
        {{ props.tipDescription }}
      </slot>
    </TipModal>
  </div>
</template>

<style scoped>
.inner-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 0 40px;
}

.inner-layout__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.inner-layout__header {
  position: sticky;
  top: 0;
  z-index: var(--z-index-header);
  padding: 40px 0 16px;
  background-color: var(--color-background);
  transition:
    box-shadow var(--animation-micro) var(--animation-effect),
    background var(--animation-micro) var(--animation-effect);
}

.inner-layout_background-only-when-move .inner-layout__header {
  background-color: transparent;
}

@media (max-width: 919px) {
  .inner-layout__header {
    padding: 24px 0 16px;
  }
}

.inner-layout_moved .inner-layout__header {
  background-color: var(--color-background);
  box-shadow: var(--shadow-background);
}

.inner-layout__header-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0 40px;
}

.inner-layout__button {
  position: absolute;
}

.inner-layout__button_back {
  top: 0;
  left: -8px;
}

.inner-layout__button_tip {
  top: 0;
  right: -8px;
}

.inner-layout__title {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.inner-layout__ghost-title {
  position: absolute;
  left: 40px;
  width: calc(100% - 80px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: var(--color-background);
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity var(--animation-micro) var(--animation-effect);
  will-change: opacity;
}

.inner-layout__main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
</style>
