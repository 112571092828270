<script setup lang="ts">
import UiIcon from '@/components/ui/UiIcon.vue';
import UiText from '@/components/ui/UiText.vue';

interface Props {
  icon?: string;
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined
});
</script>

<template>
  <UiText class="ui-input-note" muted tag="p" weight="medium" size="xs">
    <UiIcon v-if="props.icon" class="ui-input-note__icon" :name="props.icon" :size="16" />
    <slot />
  </UiText>
</template>

<style scoped>
.ui-input-note {
  display: flex;
  align-items: flex-start;
}

.ui-input-note__icon {
  flex-shrink: 0;
  margin: 1px 8px 0 0;
}
</style>
