<script setup lang="ts">
import { computed } from 'vue';

import { useImage } from '@vueuse/core';

import { placeholderBackgroundColor } from '@/helpers/colors';
import { getNetwork, Network, type NetworkInfo } from '@/references/network';
import type { Token } from '@/references/tokens';

import UiText from '@/components/ui/UiText.vue';

interface Props {
  token: Token | undefined;
  size?: 'md' | 'sm';
  hideNetworkIcon?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  hideNetworkIcon: false
});

const symbol = computed((): string => (props.token ? props.token.symbol.toUpperCase() : ''));

const networkInfo = computed((): NetworkInfo | undefined => {
  if (!props.token) {
    return undefined;
  }

  if (props.size === 'sm') {
    return undefined;
  }

  return getNetwork(props.token?.network ?? Network.unknown);
});

const backgroundColor = computed((): string => placeholderBackgroundColor(props.token?.symbol));

const { isLoading: imageLoading, error: imageError } = useImage({
  src: props.token?.iconURL ?? ''
});
</script>

<template>
  <span class="token-image" :class="`token-image_size_${props.size}`">
    <span
      v-if="imageLoading || imageError"
      class="token-image__fallback"
      :style="{ backgroundColor }"
    >
      <UiText class="token-image__fallback-text" size="xs" weight="medium">
        {{ props.size === 'sm' ? symbol[0] : symbol }}
      </UiText>
    </span>
    <img
      v-else
      :src="props.token?.iconURL"
      class="token-image__image"
      :alt="props.token?.symbol || ''"
      :title="props.token?.symbol"
    />
    <img
      v-if="networkInfo && !props.hideNetworkIcon"
      class="token-image__network-image"
      :src="networkInfo.iconURL"
      :alt="networkInfo.displayedName"
      :title="networkInfo.displayedName"
    />
  </span>
</template>

<style scoped>
.token-image {
  position: relative;
  display: block;
}

.token-image_size_md {
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.token-image_size_sm {
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.token-image__image {
  display: block;
  border-radius: 50%;
}

.token-image__fallback {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: var(--color-white);
  border-radius: 50%;
}

.token-image_size_sm .token-image__fallback {
  padding: 0;
}

.token-image__fallback-text {
  max-width: 100%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

.token-image_size_md .token-image__image,
.token-image_size_md .token-image__fallback {
  width: 44px;
  height: 44px;
}

.token-image_size_sm .token-image__image,
.token-image_size_sm .token-image__fallback {
  width: 16px;
  height: 16px;
}

.token-image__network-image {
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 20px;
  height: 20px;
  margin: 0;
  border: 2px solid var(--color-white);
  border-radius: 50%;
}
</style>
