<script setup lang="ts">
import { computed } from 'vue';

import errorIcon from '@/assets/icons/error.svg';
import successIcon from '@/assets/icons/success.svg';

import UiIcon from '@/components/ui/UiIcon.vue';
import type { UiInputAlertMod } from '@/components/ui/UiInputAlert.types';
import UiText from '@/components/ui/UiText.vue';

interface Props {
  mod?: UiInputAlertMod;
  icon?: string;
  size?: 'xs' | 'sm';
}

const props = withDefaults(defineProps<Props>(), {
  mod: 'error',
  icon: undefined,
  size: 'xs'
});

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-input-alert_size_${props.size}`]: true,
    [`ui-input-alert_mod_${props.mod}`]: true,
    'ui-input-alert_with-icon-background': !props.icon
  })
);

const iconName = computed((): string => {
  if (props.icon) {
    return props.icon;
  }

  if (props.mod === 'success') {
    return successIcon;
  }

  return errorIcon;
});
</script>

<template>
  <UiText
    class="ui-input-alert"
    :class="classList"
    muted
    tag="p"
    weight="medium"
    :size="props.size"
  >
    <span class="ui-input-alert__icon-wrapper">
      <UiIcon class="ui-input-alert__icon" :name="iconName" :size="16" />
    </span>
    <slot />
  </UiText>
</template>

<style scoped>
.ui-input-alert {
  display: flex;
  align-items: flex-start;
}

.ui-input-alert_mod_error {
  color: var(--color-red);
}

.ui-input-alert_mod_success {
  color: var(--color-green);
}

.ui-input-alert_mod_warning {
  color: var(--color-orange);
}

.ui-input-alert__icon-wrapper {
  position: relative;
}

.ui-input-alert_size_xs .ui-input-alert__icon-wrapper {
  margin: 1px 8px 0 0;
}

.ui-input-alert_size_sm .ui-input-alert__icon-wrapper {
  margin: 3px 8px 0 0;
}

.ui-input-alert_with-icon-background .ui-input-alert__icon-wrapper::before {
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: var(--color-white);
  border-radius: 50%;
  content: '';
}

.ui-input-alert__icon {
  position: relative;
  flex-shrink: 0;
}
</style>
