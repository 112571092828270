export const enum KeyboardKey {
  Esc = 'Escape',
  Tab = 'Tab'
}

export const enum KeyboardCode {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  LeftArrow = 37,
  RightArrow = 39,
  Delete = 46
}

export class HashEvent extends Event {
  constructor(public hash: string) {
    super('hash_received', undefined);
  }
}

export const isOnAttr = (key: string) => /^on[^a-z]/.test(key);
