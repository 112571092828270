<script setup lang="ts">
import type { PropType } from 'vue';
import { computed, onBeforeUnmount, ref, useAttrs } from 'vue';
import { IMaskComponent } from 'vue-imask';

import { useElementSize, whenever } from '@vueuse/core';
import type { AnyMaskedOptions } from 'imask';
import { v4 } from 'uuid';

import crossIcon from '@/assets/icons/cross.svg';
import { isOnAttr } from '@/helpers/events';

import UiButtonIcon from '@/components/ui/UiButtonIcon.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import type {
  UiInputAlign,
  UiInputMod,
  UiInputMode,
  UiInputType
} from '@/components/ui/UiInput.types';
import UiInputAlert from '@/components/ui/UiInputAlert.vue';
import UiInputNote from '@/components/ui/UiInputNote.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';

const DEFAULT_HORIZONTAL_PADDING = 16;
const ICON_SIZE = 24;
const SHAKING_DURATION = 500;

defineOptions({
  inheritAttrs: false
});

const props = defineProps({
  id: {
    type: String as PropType<string>,
    default: () => `ui-input-${v4()}`
  },
  name: {
    type: String as PropType<string>,
    default: undefined
  },
  modelValue: {
    type: String as PropType<string>,
    required: true
  },
  unmasked: {
    type: String as PropType<string>,
    default: undefined
  },
  type: {
    type: String as PropType<UiInputType>,
    default: 'text'
  },
  inputmode: {
    type: String as PropType<UiInputMode>,
    default: undefined
  },
  mod: {
    type: String as PropType<UiInputMod>,
    default: 'default'
  },
  align: {
    type: String as PropType<UiInputAlign>,
    default: 'left'
  },
  placeholder: {
    type: String as PropType<string>,
    default: undefined
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  readonly: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  autocomplete: {
    type: String as PropType<string>,
    default: 'off'
  },
  /* https://imask.js.org/guide.html */
  mask: {
    type: Object as PropType<AnyMaskedOptions>,
    default: undefined
  },
  error: {
    type: String as PropType<string>,
    default: ''
  },
  success: {
    type: String as PropType<string>,
    default: ''
  },
  icon: {
    type: String as PropType<string>,
    default: undefined
  },
  prefix: {
    type: String as PropType<string>,
    default: undefined
  },
  prefixMargin: {
    type: Number as PropType<number>,
    default: 8
  },
  postfix: {
    type: String as PropType<string>,
    default: undefined
  },
  postfixMargin: {
    type: Number as PropType<number>,
    default: 8
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  spellcheck: {
    type: Boolean as PropType<boolean>,
    default: true
  },
  clear: {
    type: Boolean as PropType<boolean>,
    default: true
  },
  uppercase: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  note: {
    type: String as PropType<string>,
    default: undefined
  },
  noteIcon: {
    type: String as PropType<string>,
    default: undefined
  },
  paddingLeft: {
    type: Number as PropType<number>,
    default: 0
  },
  paddingRight: {
    type: Number as PropType<number>,
    default: 0
  }
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'change', value: string): void;
}>();

const inputElement = ref<typeof IMaskComponent>();
const prefixElement = ref<HTMLElement>();
const postfixElement = ref<HTMLElement>();
const mirrorElement = ref<HTMLElement>();

let shakingTimeout: ReturnType<typeof setTimeout> | null = null;
const isShaking = ref(false);

const { width: inputWidth } = useElementSize(inputElement);
const { width: prefixWidth } = useElementSize(prefixElement);
const { width: postfixWidth } = useElementSize(postfixElement);
const { width: mirrorWidth } = useElementSize(mirrorElement);
const attrs = useAttrs();

const listenersAttrs = computed(
  (): Record<string, ((value: string) => void) | ((event: Event) => void)> => ({
    ...Object.fromEntries(Object.entries(attrs).filter(([key]) => isOnAttr(key))),
    'onUpdate:modelValue': (eventOrValue: Event | string) => onInput(eventOrValue),
    onChange: (event: Event) => onChange(event)
  })
);

const notListenersAttrs = computed(
  (): Record<string, unknown> =>
    Object.fromEntries(Object.entries(attrs).filter(([key]) => !isOnAttr(key)))
);

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-input_filled`]: !!props.modelValue,
    [`ui-input_mod_${props.mod}`]: true,
    [`ui-input_align_${props.align}`]: true,
    'ui-input_shaking': isShaking.value
  })
);

const shakingDurationCss = computed((): string => `${SHAKING_DURATION}ms`);

const leftPadding = computed((): string => {
  if (props.prefix) {
    return `${
      (props.mod === 'default' ? DEFAULT_HORIZONTAL_PADDING : props.paddingLeft) +
      prefixWidth.value +
      props.prefixMargin
    }px`;
  }

  if (props.icon) {
    return `${
      (props.mod === 'default' ? DEFAULT_HORIZONTAL_PADDING : props.paddingLeft) +
      ICON_SIZE +
      props.prefixMargin
    }px`;
  }

  return `${props.mod === 'default' ? DEFAULT_HORIZONTAL_PADDING : props.paddingLeft}px`;
});

const rightPadding = computed((): string => {
  let value = 0;

  if (props.mod === 'default' && props.clear) {
    value += 32; // 24 (button) + 8 (margin).
  }

  if (props.postfix) {
    value +=
      (props.mod === 'default' ? DEFAULT_HORIZONTAL_PADDING : props.paddingRight) +
      (postfixWidth.value || 0) +
      props.postfixMargin;
  } else {
    value += props.mod === 'default' ? DEFAULT_HORIZONTAL_PADDING : props.paddingRight;
  }

  return `${value}px`;
});

const prefixLeftPosition = computed((): string => {
  if (props.align === 'right') {
    return 'auto';
  }

  const value = props.mod === 'default' ? DEFAULT_HORIZONTAL_PADDING : props.paddingLeft;

  return `${value}px`;
});

const prefixRightPosition = computed((): string => {
  if (props.align === 'left') {
    return 'auto';
  }

  let value = 0;

  value += props.mod === 'default' ? DEFAULT_HORIZONTAL_PADDING : props.paddingRight;
  value += props.prefixMargin;
  value += mirrorWidth.value;

  value = Math.min(value, inputWidth.value + props.paddingRight + props.prefixMargin);

  return `${value}px`;
});

const postfixLeftPosition = computed((): string => {
  if (props.align === 'right') {
    return 'auto';
  }

  let value = 0;

  value += props.mod === 'default' ? DEFAULT_HORIZONTAL_PADDING : props.paddingLeft;
  value += props.postfixMargin;
  value += mirrorWidth.value;

  value = Math.min(value, inputWidth.value + props.paddingLeft + props.postfixMargin);

  return `${value}px`;
});

const postfixRightPosition = computed((): string => {
  if (props.align === 'left') {
    return 'auto';
  }

  const value = props.mod === 'default' ? DEFAULT_HORIZONTAL_PADDING : props.paddingRight;

  return `${value}px`;
});

function onInput(eventOrValue: Event | string): void {
  let value;

  if (typeof eventOrValue !== 'string') {
    const currentInput = eventOrValue.target as HTMLInputElement;
    value = props.uppercase ? currentInput.value.toLocaleUpperCase() : currentInput.value;
  } else {
    value = props.uppercase ? eventOrValue.toLocaleUpperCase() : eventOrValue;
  }

  emit('update:modelValue', value);
}

function onChange(event: Event): void {
  const currentInput = event.target as HTMLInputElement;
  const { value } = currentInput;

  emit('change', props.uppercase ? value.toLocaleUpperCase() : value);
}

function focus(): void {
  inputElement.value.$el?.focus();
}

function onClearButtonClick(): void {
  emit('update:modelValue', '');
  focus();
}

function clearShakingTimeout(): void {
  if (shakingTimeout) {
    clearInterval(shakingTimeout);
  }
}

function shake(): void {
  clearShakingTimeout();
  isShaking.value = true;
  shakingTimeout = setTimeout(() => {
    isShaking.value = false;
    shakingTimeout = null;
  }, SHAKING_DURATION);
}

whenever(
  () => props.error,
  () => {
    shake();
  }
);

onBeforeUnmount(() => {
  clearShakingTimeout();
});

defineExpose({
  focus,
  shake
});
</script>

<template>
  <div class="ui-input" :class="classList" v-bind="notListenersAttrs">
    <div class="ui-input__body" :class="{ 'ui-input__body_disabled': props.disabled }">
      <IMaskComponent
        v-bind="{ ...listenersAttrs, ...(props.mask || {}) }"
        :id="props.id"
        ref="inputElement"
        :value="props.modelValue"
        :unmasked="props.unmasked"
        class="ui-input__input"
        :type="props.type"
        :inputmode="props.inputmode"
        :name="props.name"
        :disabled="props.disabled"
        :readonly="props.readonly"
        :placeholder="props.placeholder"
        :autocomplete="props.autocomplete"
        :spellcheck="props.spellcheck ? undefined : false"
      />
      <span v-if="props.prefix" ref="prefixElement" class="ui-input__prefix">
        {{ props.prefix }}
      </span>
      <UiIcon
        v-else-if="props.icon"
        class="ui-input__icon"
        :name="props.icon"
        :size="ICON_SIZE"
        muted
      />
      <span v-if="props.postfix" ref="postfixElement" class="ui-input__postfix">
        {{ props.postfix }}
      </span>
      <template v-if="props.mod === 'default' && props.clear">
        <Transition name="ui-input-button">
          <UiSpinner v-if="props.loading" class="ui-input__spinner" :size="20" />
          <UiButtonIcon
            v-else-if="props.modelValue && !props.disabled"
            class="ui-input__clear-button"
            size="sm"
            :icon="crossIcon"
            @click="onClearButtonClick"
          />
        </Transition>
      </template>
    </div>
    <Transition name="ui-input-note" mode="out-in">
      <UiInputAlert
        v-if="props.error || props.success"
        class="ui-input__note"
        :mod="props.error ? 'error' : 'success'"
      >
        {{ props.error || props.success }}
      </UiInputAlert>
      <UiInputNote v-else-if="props.note" class="ui-input__note" :icon="props.noteIcon">
        {{ props.note }}
      </UiInputNote>
    </Transition>
    <span ref="mirrorElement" class="ui-input__mirror" aria-hidden="true">
      {{ props.modelValue || '0' }}
    </span>
  </div>
</template>

<style scoped>
.ui-input {
  max-width: 100%;
}

.ui-input__body {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  transition: opacity var(--animation-micro) var(--animation-effect);
}

.ui-input_shaking .ui-input__body {
  animation: ui-input-shaking v-bind(shakingDurationCss);
}

.ui-input__icon {
  position: absolute;
  top: calc(50% - 12px);
  left: 16px;
}

.ui-input__body_disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ui-input__input,
.ui-input__prefix,
.ui-input__postfix,
.ui-input__mirror {
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-base);
}

.ui-input__input {
  width: 100%;
  padding: 0;
  padding-right: v-bind(rightPadding);
  padding-left: v-bind(leftPadding);
  color: var(--color-main-text);
  text-overflow: ellipsis;
  border: none;
  cursor: text;
  transition: background-color var(--animation-micro) var(--animation-effect);
  appearance: none;
}

.ui-input__input:read-only {
  cursor: default;
}

.ui-input_mod_default .ui-input__input,
.ui-input_mod_default .ui-input__prefix,
.ui-input_mod_default .ui-input__postfix,
.ui-input_mod_default .ui-input__mirror {
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
}

.ui-input_mod_default .ui-input__input {
  height: 64px;
  background-color: var(--color-main-underlay);
  border-radius: 12px;
}

.ui-input_mod_default.ui-input_filled .ui-input__input {
  background-color: var(--color-secondary-contrast);
}

.ui-input_mod_transparent .ui-input__input,
.ui-input_mod_transparent .ui-input__prefix,
.ui-input_mod_transparent .ui-input__postfix,
.ui-input_mod_transparent .ui-input__mirror {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
}

.ui-input_mod_transparent .ui-input__input {
  height: 24px;
  background-color: transparent;
  border-radius: 0;
}

.ui-input_align_left .ui-input__input {
  text-align: left;
}

.ui-input_align_right .ui-input__input {
  text-align: right;
}

.ui-input__input:focus {
  outline: none;
}

.ui-input__input::-ms-clear,
.ui-input__input::-ms-reveal,
.ui-input__input::-webkit-search-decoration,
.ui-input__input::-webkit-search-cancel-button,
.ui-input__input::-webkit-search-results-button,
.ui-input__input::-webkit-search-results-decoration {
  display: none;
}

.ui-input__input::placeholder {
  color: var(--color-main-text-disabled);
  opacity: 1;
}

.ui-input__input:disabled {
  cursor: not-allowed;
  opacity: 1;
}

.ui-input__prefix,
.ui-input__postfix {
  position: absolute;
  user-select: none;
  pointer-events: none;
}

.ui-input__prefix {
  right: v-bind(prefixRightPosition);
  left: v-bind(prefixLeftPosition);
}

.ui-input__postfix {
  right: v-bind(postfixRightPosition);
  left: v-bind(postfixLeftPosition);
}

.ui-input_mod_default .ui-input__prefix,
.ui-input_mod_default .ui-input__postfix {
  top: calc(50% - 12px);
  color: var(--color-secondary-text);
}

.ui-input_mod_transparent .ui-input__prefix,
.ui-input_mod_transparent .ui-input__postfix {
  top: 0;
  color: var(--color-main-text-disabled);
}

.ui-input_mod_transparent.ui-input_filled .ui-input__prefix,
.ui-input_mod_transparent.ui-input_filled .ui-input__postfix {
  color: var(--color-main-text);
}

.ui-input__spinner {
  position: absolute;
  top: calc(50% - 10px);
  right: 18px;
}

.ui-input__clear-button {
  position: absolute;
  top: calc(50% - 12px);
  right: 16px;
}

.ui-input__note {
  margin: 8px 0 0;
}

.ui-input_align_right .ui-input__note {
  justify-content: flex-end;
  text-align: right;
}

.ui-input__mirror {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

.ui-input-button-enter-from,
.ui-input-button-leave-to,
.ui-input-note-enter-from,
.ui-input-note-leave-to {
  opacity: 0;
}

.ui-input-button-enter-active,
.ui-input-button-leave-active,
.ui-input-note-enter-active,
.ui-input-note-leave-active {
  transition: opacity var(--animation-micro) var(--animation-effect);
}

@keyframes ui-input-shaking {
  8%,
  41% {
    transform: translateX(-8px);
  }

  25%,
  58% {
    transform: translateX(8px);
  }

  75% {
    transform: translateX(-4px);
  }

  92% {
    transform: translateX(4px);
  }

  0%,
  100% {
    transform: translateX(0);
  }
}
</style>
