<script setup lang="ts">
import type { PropType } from 'vue';
import { computed, reactive, ref } from 'vue';

import { onClickOutside } from '@vueuse/core';

import selectedIcon from '@/assets/icons/selected.svg';

import type { UiDropdownItem, UiDropdownPosition } from '@/components/ui/UiDropdown.types';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiText from '@/components/ui/UiText.vue';

const props = defineProps({
  position: {
    type: String as PropType<UiDropdownPosition>,
    default: 'bottom'
  },
  items: {
    type: Array as PropType<UiDropdownItem[]>,
    required: true
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  mutedIcons: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  maxHeight: {
    type: String as PropType<string>,
    default: undefined
  },
  distance: {
    type: Number as PropType<number>,
    default: 16
  },
  margin: {
    type: Number as PropType<number>,
    default: 0
  }
});

const rootElement = ref<HTMLElement>();

const state = reactive<{
  isOpened: boolean;
}>({
  isOpened: false
});

const classList = computed(
  (): Record<string, boolean> => ({
    'ui-dropdown_opened': state.isOpened,
    [`ui-dropdown_position_${props.position}`]: true
  })
);

const computedMaxHeight = computed((): string => props.maxHeight ?? 'none');

const marginWithPx = computed((): string => `-${props.margin}px`);

const isOpened = computed((): boolean => state.isOpened);

const distanceWithPx = computed((): string => `${props.distance}px`);

function onTriggerClick(): void {
  if (props.disabled) {
    return;
  }

  if (state.isOpened) {
    close();
  } else {
    open();
  }
}

function open(): void {
  if (state.isOpened) {
    return;
  }

  state.isOpened = true;
}

function close(): void {
  if (!state.isOpened) {
    return;
  }

  state.isOpened = false;
}

function onClick(item: UiDropdownItem): void {
  if (props.disabled) {
    return;
  }

  if (!item.isNotClosing) {
    close();
  }

  item.handler?.(item.value);
}

onClickOutside(rootElement, () => {
  close();
});

defineExpose({
  open,
  close,
  isOpened
});
</script>

<template>
  <div ref="rootElement" class="ui-dropdown" :class="classList">
    <div class="ui-dropdown__control" @click="onTriggerClick">
      <slot />
    </div>
    <Transition name="ui-dropdown">
      <ul v-if="state.isOpened" class="ui-dropdown__list" data-body-scroll-lock-ignore>
        <li v-if="props.loading" class="ui-dropdown__item">
          <UiSpinner class="ui-dropdown__spinner" />
        </li>
        <template v-else>
          <li
            v-for="item in items"
            :key="item.text"
            class="ui-dropdown__item"
            :class="{
              'ui-dropdown__item_separated': item.separated,
              'ui-dropdown__item_red': item.red
            }"
          >
            <component
              :is="item.href ? 'a' : 'button'"
              class="ui-dropdown__button"
              :href="item.href"
              :type="item.href ? undefined : 'button'"
              :disabled="item.href ? undefined : !!item.disabled"
              :target="item.href ? '_blank' : undefined"
              @click="onClick(item)"
            >
              <UiIcon
                v-if="item.selected"
                class="ui-dropdown__icon"
                :name="selectedIcon"
                :size="22"
              />
              <UiText class="ui-dropdown__text" size="md" weight="medium">{{ item.text }}</UiText>
              <UiIcon
                v-if="item.icon"
                class="ui-dropdown__icon"
                :name="item.icon"
                :muted="props.mutedIcons"
                :size="22"
              />
              <img v-else-if="item.image" class="ui-dropdown__image" :src="item.image" alt="" />
            </component>
          </li>
        </template>
      </ul>
    </Transition>
  </div>
</template>

<style scoped>
.ui-dropdown {
  position: relative;
  display: inline-flex;
}

.ui-dropdown__control {
  display: flex;
  flex-grow: 1;
}

.ui-dropdown_opened .ui-dropdown__control {
  opacity: 0.25;
  transition: opacity var(--animation-micro) var(--animation-effect);
}

.ui-dropdown__list {
  position: absolute;
  z-index: 2;
  width: 254px;
  max-width: calc(100vw - 48px);
  max-height: v-bind(computedMaxHeight);
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
  list-style-type: none;
  background-color: var(--color-main-underlay);
  border-radius: 12px;
  box-shadow: var(--shadow-base);
  -webkit-overflow-scrolling: touch;
}

@media (hover: hover) {
  .ui-dropdown__list {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ui-dropdown__list::-webkit-scrollbar {
    display: none;
  }
}

.ui-dropdown_position_top .ui-dropdown__list,
.ui-dropdown_position_top-start .ui-dropdown__list,
.ui-dropdown_position_top-end .ui-dropdown__list {
  bottom: calc(100% + v-bind(distanceWithPx));
}

.ui-dropdown_position_bottom .ui-dropdown__list,
.ui-dropdown_position_bottom-start .ui-dropdown__list,
.ui-dropdown_position_bottom-end .ui-dropdown__list {
  top: calc(100% + v-bind(distanceWithPx));
}

.ui-dropdown_position_top .ui-dropdown__list,
.ui-dropdown_position_bottom .ui-dropdown__list {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.ui-dropdown_position_top-start .ui-dropdown__list,
.ui-dropdown_position_bottom-start .ui-dropdown__list {
  left: v-bind(marginWithPx);
}

.ui-dropdown_position_top-end .ui-dropdown__list,
.ui-dropdown_position_bottom-end .ui-dropdown__list {
  right: v-bind(marginWithPx);
}

.ui-dropdown_position_top-start .ui-dropdown__list {
  transform-origin: bottom left;
}

.ui-dropdown_position_top-end .ui-dropdown__list {
  transform-origin: bottom right;
}

.ui-dropdown_position_bottom-start .ui-dropdown__list {
  transform-origin: top left;
}

.ui-dropdown_position_bottom-end .ui-dropdown__list {
  transform-origin: top right;
}

.ui-dropdown_position_top .ui-dropdown__list {
  transform-origin: bottom center;
}

.ui-dropdown_position_bottom .ui-dropdown__list {
  transform-origin: top center;
}

.ui-dropdown__item:not(:last-child) {
  border-bottom: 1px solid var(--color-main-stroke);
}

.ui-dropdown__item_separated {
  border-top: 7px solid var(--color-main-stroke);
  border-bottom: none;
}

.ui-dropdown__item_separated + .ui-dropdown__item_separated {
  border-top: none;
  border-bottom: 1px solid var(--color-main-stroke);
  box-shadow: 0 4px 16px 0 var(--color-shadow);
}

.ui-dropdown__item_separated + .ui-dropdown__item_separated:last-child {
  border-bottom: none;
}

.ui-dropdown__spinner {
  margin: 11px auto;
}

.ui-dropdown__button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding: 11px 16px;
  color: var(--color-main-text);
  text-align: left;
  text-decoration: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: opacity var(--animation-micro) var(--animation-effect);
  appearance: none;
  user-select: none;
}

.ui-dropdown__item_red .ui-dropdown__button {
  color: var(--color-red);
}

.ui-dropdown__button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ui-dropdown__item:first-child .ui-dropdown__button {
  border-radius: 12px 12px 0 0;
}

.ui-dropdown__item:last-child .ui-dropdown__button {
  border-radius: 0 0 12px 12px;
}

.ui-dropdown__text {
  margin: 0 auto 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ui-dropdown__text:not(:first-child),
.ui-dropdown__text:not(:last-child) {
  max-width: calc(100% - 30px);
}

.ui-dropdown__text:not(:first-child, :last-child) {
  max-width: calc(100% - 60px);
}

.ui-dropdown__icon {
  flex-shrink: 0;
}

.ui-dropdown__icon:first-child {
  margin: 0 8px 0 0;
}

.ui-dropdown__icon:last-child {
  margin: 0 0 0 8px;
}

.ui-dropdown__image {
  width: 22px;
  height: 22px;
  margin: 0 0 0 8px;
}

.ui-dropdown-enter-from,
.ui-dropdown-leave-to {
  opacity: 0;
}

.ui-dropdown_position_top .ui-dropdown-enter-from,
.ui-dropdown_position_top .ui-dropdown-leave-to {
  transform: translate3d(-50%, 8px, 0) scale(0.9);
}

.ui-dropdown_position_bottom .ui-dropdown-enter-from,
.ui-dropdown_position_bottom .ui-dropdown-leave-to {
  transform: translate3d(-50%, -8px, 0) scale(0.9);
}

.ui-dropdown_position_top-start .ui-dropdown-enter-from,
.ui-dropdown_position_top-start .ui-dropdown-leave-to,
.ui-dropdown_position_top-end .ui-dropdown-enter-from,
.ui-dropdown_position_top-end .ui-dropdown-leave-to {
  transform: translate3d(0, 8px, 0) scale(0.9);
}

.ui-dropdown_position_bottom-start .ui-dropdown-enter-from,
.ui-dropdown_position_bottom-start .ui-dropdown-leave-to,
.ui-dropdown_position_bottom-end .ui-dropdown-enter-from,
.ui-dropdown_position_bottom-end .ui-dropdown-leave-to {
  transform: translate3d(0, -8px, 0) scale(0.9);
}

.ui-dropdown-enter-active,
.ui-dropdown-leave-active {
  transition:
    opacity var(--animation-micro) var(--animation-effect),
    transform var(--animation-micro) var(--animation-effect);
}
</style>
