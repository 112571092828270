import { datadogLogs } from '@datadog/browser-logs';

import { HHError } from '@/references/HHError';

export interface Logger {
  debug(message: string, messageContext?: object, error?: Error): void;
  info(message: string, messageContext?: object, error?: Error): void;
  warn(message: string, messageContext?: object, error?: Error): void;
  error(message: string, messageContext?: object, error?: Error): void;
}

export function createLogger(name?: string): Logger {
  const loggers = new Array<Logger>();
  loggers.push(name === undefined ? datadogLogs.logger : datadogLogs.createLogger(name));
  loggers.push(wrapMessageLogger(console, name === undefined ? '' : `[${name}] `));

  if (loggers.length === 0) {
    return createNopLogger();
  }

  return join(...loggers);
}

function createNopLogger(): Logger {
  return {
    debug: () => {
      /**/
    },
    info: () => {
      /**/
    },
    warn: () => {
      /**/
    },
    error: () => {
      /**/
    }
  };
}

function join(...loggers: Logger[]): Logger {
  return {
    debug: (...args) => {
      loggers.forEach((l) => l.debug(...args));
    },
    info: (...args) => {
      loggers.forEach((l) => l.info(...args));
    },
    warn: (...args) => {
      loggers.forEach((l) => l.warn(...args));
    },
    error: (...args) => {
      loggers.forEach((l) => l.error(...args));
    }
  };
}

function wrapMessageLogger(logger: Logger, prefix: string): Logger {
  return {
    debug: (message, ...rest) => logger.debug(`${prefix}${message}`, ...rest),
    info: (message, ...rest) => logger.info(`${prefix}${message}`, ...rest),
    warn: (message, ...rest) => logger.warn(`${prefix}${message}`, ...rest),
    error: (message, messageContext, error) => {
      const args: Parameters<Logger['error']> = [`${prefix}${message}`];
      if (messageContext !== undefined) {
        args.push(messageContext);
      }

      if (error !== undefined) {
        if (error instanceof Error) {
          args.push(error);
        } else {
          args.push(new HHError('Non-error object provided as error', { payload: { error } }));
        }
      }

      logger.error(...args);
    }
  };
}

export const logger: Logger = createLogger();
