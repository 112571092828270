export function assert(condition: boolean, cause?: unknown): asserts condition {
  if (condition) {
    return;
  }

  throw new Error('Assertion failed', {
    cause: typeof cause !== 'string' ? cause : new Error(cause)
  });
}

export function assertUnreachable(x: never, message?: string): never {
  throw new Error(message ?? 'Reached unhandled case', { cause: { value: x } });
}
