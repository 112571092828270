<script setup lang="ts">
import { defineAsyncComponent } from 'vue';

import { useAccount } from '@/composables/useAccount';
import { settings } from '@/references/settings/globals';
import AuthView from '@/views/AuthView.vue';

// const AsyncSupportChat = defineAsyncComponent(() => import('@/components/chat/SupportChat.vue'));

const { isAuthed } = useAccount();
</script>

<template>
  <div class="root-view">
    <AuthView v-if="!isAuthed" />
    <template v-else>
      <Transition css>
        <div v-show="true" class="root-view__router">
          <!-- TODO Remove this v-show if not needed for custom effects -->
<!--          <AsyncSupportChat v-if="settings.chatEnabled" />-->
          <RouterView />
        </div>
      </Transition>
    </template>
  </div>
</template>

<style scoped>
.root-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.root-view__router {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.root-view__router > * {
  width: 100%;
}

.v-enter-from {
  opacity: 0;
}

.v-enter-active {
  transition: opacity var(--animation-medium) var(--animation-effect);
}
</style>
