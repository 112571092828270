import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { v4 } from 'uuid';

const W3M_MODAL_TAG = 'w3m-modal';

interface LockedItem {
  id: string;
  target: HTMLElement | Element;
}

let locks: LockedItem[] = [];

/* Allow scroll for some elements: https://github.com/willmcpo/body-scroll-lock#allowtouchmove */
const allowTouchMove = (el: Element | HTMLElement) => {
  while (el && el !== document.body) {
    if (
      el.getAttribute('data-body-scroll-lock-ignore') !== null ||
      el.tagName.toLowerCase() === W3M_MODAL_TAG
    ) {
      return true;
    }

    el = el.parentElement as HTMLElement;
  }

  return false;
};

const lock = (target: HTMLElement | Element): string => {
  const id = v4();
  locks.push({ id, target });

  clearAllBodyScrollLocks();
  disableBodyScroll(target, { allowTouchMove });

  return id;
};

const unlock = (id: string): void => {
  const targetItem = locks.find((item) => item.id === id);
  if (!targetItem) {
    return;
  }

  locks = locks.filter((item) => item.id !== id);

  clearAllBodyScrollLocks();

  if (!locks.length) {
    return;
  }

  const lastTargetItem = locks[locks.length - 1];

  disableBodyScroll(lastTargetItem.target, { allowTouchMove });
};

const unlockAll = (): void => {
  locks = [];
  clearAllBodyScrollLocks();
};

export { lock, unlock, unlockAll };
