<script setup lang="ts">
import { computed } from 'vue';
import { type RouteLocationRaw } from 'vue-router';

import cardIcon from '@/assets/icons/card.svg';
import cardFilledIcon from '@/assets/icons/card-filled.svg';
import settingsIcon from '@/assets/icons/settings.svg';
import { useAccount } from '@/composables/useAccount';
import { useViewBreakpoints } from '@/composables/useViewBreakpoints';
import { useI18n } from '@/references/i18n';
import { RouteNames } from '@/router';

import AvatarImage from '@/components/AvatarImage.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiIndicator from '@/components/ui/UiIndicator.vue';
import UiLink from '@/components/ui/UiLink.vue';

interface MainNavigationLink {
  text: string;
  icon: string;
  activeIcon: string;
  route: RouteLocationRaw;
  isExactActive: boolean;
  alertsCounter?: number;
  isActive?: boolean;
}

const { t } = useI18n();
const { avatarUrl, name } = useAccount();
const { isMobile } = useViewBreakpoints();

const settingsButtonText = computed((): string => name.value ?? t('navigation.settings'));

const links = computed((): MainNavigationLink[] => {
  const ret: MainNavigationLink[] = [
    {
      text: t('navigation.brrr'),
      route: { name: RouteNames.Home },
      icon: cardIcon,
      activeIcon: cardFilledIcon,
      isExactActive: true
    }
  ];

  // if (settings.activityViewEnabled) {
  //   ret.push({
  //     text: t('navigation.activity'),
  //     route: { name: RouteNames.Activity },
  //     icon: clockIcon,
  //     activeIcon: clockFilledIcon,
  //     isExactActive: false
  //   });
  // }

  return ret;
});
</script>

<template>
  <header class="main-navigation">
    <nav class="main-navigation__nav">
      <ul class="main-navigation__list">
        <li v-if="!isMobile" class="main-navigation__item">
          <RouterLink :to="{ name: RouteNames.Settings }" custom>
            <template #default="{ href, navigate, isExactActive }">
              <UiLink
                class="main-navigation__link"
                :class="{ 'main-navigation__link_active': isExactActive }"
                :href="href"
                size="md"
                weight="semibold"
                :icon="settingsIcon"
                :gap="18"
                @click="navigate"
              >
                <template v-if="avatarUrl" #prefix>
                  <AvatarImage class="main-navigation__avatar" :src="avatarUrl" />
                </template>
                <template #default>
                  {{ settingsButtonText }}
                </template>
              </UiLink>
            </template>
          </RouterLink>
        </li>

        <li v-for="link in links" :key="link.text" class="main-navigation__item">
          <RouterLink :to="link.route" custom>
            <template #default="{ href, navigate, isExactActive, isActive }">
              <a
                v-if="isMobile"
                class="main-navigation__mobile-button"
                :href="href"
                :title="link.text"
                @click="navigate"
              >
                <UiIcon
                  :name="
                    (link.isExactActive ? isExactActive : isActive) || link.isActive
                      ? link.activeIcon
                      : link.icon
                  "
                  :muted="!(link.isExactActive ? isExactActive : isActive) || link.isActive"
                  :size="28"
                />
                <UiIndicator
                  v-if="link.alertsCounter"
                  class="main-navigation__indicator"
                  color="green"
                />
              </a>
              <UiLink
                v-else
                class="main-navigation__link"
                :class="{
                  'main-navigation__link_active':
                    (link.isExactActive ? isExactActive : isActive) || link.isActive
                }"
                :href="href"
                size="md"
                weight="semibold"
                :icon="
                  (link.isExactActive ? isExactActive : isActive) || link.isActive
                    ? link.activeIcon
                    : link.icon
                "
                :gap="18"
                @click="navigate"
              >
                {{ link.text }}
                <UiIndicator
                  v-if="link.alertsCounter"
                  class="main-navigation__indicator"
                  color="green"
                />
              </UiLink>
            </template>
          </RouterLink>
        </li>
      </ul>
    </nav>
  </header>
</template>

<style scoped>
.main-navigation {
  position: relative;
  z-index: 1;
  padding: 40px 32px;
  background-color: var(--color-background);
}

@media (max-width: 919px) {
  .main-navigation {
    z-index: 5;
    padding: 4px 0 16px;
    border-top: 1px solid var(--color-main-stroke);
  }
}

.main-navigation__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 919px) {
  .main-navigation__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.main-navigation__item:not(:last-child) {
  margin: 0 0 8px;
}

.main-navigation__item:first-child {
  margin: 0 0 40px;
}

@media (max-width: 919px) {
  .main-navigation__item,
  .main-navigation__item:not(:last-child),
  .main-navigation__item:first-child {
    margin: 0 14px;
  }
}

@media (max-width: 919px) {
  .main-navigation__item:nth-child(2) {
    order: -1;
  }
}

.main-navigation__link {
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  transition: background-color var(--animation-micro) var(--animation-effect);
}

.main-navigation__link_active {
  background-color: var(--color-secondary-contrast);
}

.main-navigation__indicator {
  position: absolute;
}

.main-navigation__link .main-navigation__indicator {
  top: 16px;
  left: 28px;
}

.main-navigation__mobile-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin: 0;
  padding: 0;
  color: var(--color-main-text);
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  appearance: none;
  user-select: none;
}

.main-navigation__mobile-button .main-navigation__indicator {
  top: 16px;
  right: 16px;
}

.main-navigation__avatar {
  margin: -8px;
}
</style>
