import { fade, type MotionVariants } from '@vueuse/motion';

export const slideLeft = {
  initial: {
    x: -24,
    opacity: 0
  },
  enter: {
    x: 0,
    opacity: 1
  }
};

export const slideRight = {
  initial: {
    x: 24,
    opacity: 0
  },
  enter: {
    x: 0,
    opacity: 1
  }
};

export { fade };
export type { MotionVariants };
