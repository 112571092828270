<script setup lang="ts">
import { reactive } from 'vue';

import { fade, slideLeft } from '@/helpers/motion';

import AuthEmail from '@/components/AuthEmail.vue';
import AuthOtp from '@/components/AuthOtp.vue';
import AuthWelcome from '@/components/AuthWelcome.vue';
import AuthLayout from '@/components/layouts/AuthLayout.vue';

type Step = 'welcome' | 'email' | 'otp';

const state = reactive<{
  isMounted: boolean;
  step: Step;
  email: string;
}>({
  isMounted: false,
  step: 'welcome',
  email: ''
});

function continueToEmail() {
  state.step = 'email';
}

async function continueToOTP(email: string): Promise<void> {
  state.email = email;
  state.step = 'otp';
}
</script>

<template>
  <AuthLayout class="auth-view">
    <AuthWelcome
      v-if="state.step === 'welcome'"
      v-motion="state.isMounted ? slideLeft : fade"
      @next="continueToEmail"
    />
    <AuthEmail
      v-if="state.step === 'email'"
      v-motion="state.isMounted ? slideLeft : fade"
      @next="continueToOTP"
    />
    <AuthOtp v-if="state.step === 'otp'" :email="state.email" />
  </AuthLayout>
</template>
