import { createRouter, createWebHistory } from 'vue-router';

import { logger } from '@/logs/datadog';
import HomeRootView from '@/views/HomeRootView.vue';
import HomeView from '@/views/HomeView.vue';
import KYCView from '@/views/KYCView.vue';
import RootView from '@/views/RootView.vue';
import SettingsView from '@/views/SettingsView.vue';

import HomeInfoBrrr from '@/components/HomeInfoBrrr.vue';

export enum RouteNames {
  Root = 'root',
  Home = 'home',
  Activity = 'activity',
  Settings = 'settings',
  KYC = 'kyc',
  Brrr = 'brrr',
  Error404 = '404'
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: RootView,
      children: [
        /* Home */
        {
          path: '',
          component: HomeRootView,
          children: [
            {
              path: '',
              name: RouteNames.Home,
              component: HomeView,
              children: [
                {
                  name: RouteNames.Brrr,
                  path: '/brrr',
                  component: HomeInfoBrrr
                }
              ]
            },
            {
              path: 'settings',
              name: RouteNames.Settings,
              component: SettingsView
            }
          ]
        },
        {
          path: 'kyc',
          name: RouteNames.KYC,
          component: KYCView,
          props: true
        }
      ]
    },

    /* 404. */
    {
      name: RouteNames.Error404,
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/Error404View.vue')
    }
  ]
});

router.afterEach((to, _, failure) => {
  if (!failure) {
    logger.info(`navigation changed to ${to.fullPath}`, {
      routeName: to.name
    });
  }
});

export default router;
