export enum KYCStatus {
  UNKNOWN = 'unknown',
  NOT_STARTED = 'not_started',
  STARTED = 'in_process',
  REVIEW = 'pending',
  SUCCESS = 'approved',
  FAILED = 'failed'
}

export type GetKYCStatusResponse = {
  kycStatus: KYCStatus;
};

export type GetKYCSessionIdResponse = {
  sessionId: string;
};
