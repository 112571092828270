<script setup lang="ts">
import { computed, defineAsyncComponent, unref } from 'vue';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

import { type BasicColorSchema, useWindowScroll } from '@vueuse/core';

import crossLargeIcon from '@/assets/icons/cross-large.svg';
import disconnectIcon from '@/assets/icons/disconnect.svg';
import languageIcon from '@/assets/icons/language.svg';
import themeIcon from '@/assets/icons/theme.svg';
import { useAccount } from '@/composables/useAccount';
import { useTheme } from '@/composables/useTheme';
import { useViewBreakpoints } from '@/composables/useViewBreakpoints';
import { i18n, type LocaleKey, Locales, setLocale, useI18n } from '@/references/i18n';
import { RouteNames } from '@/router';
import { type SettingsLinkItem } from '@/views/SettingsView.types';

import ConfirmationModal from '@/components/ConfirmationModal.vue';
import UiButtonIcon from '@/components/ui/UiButtonIcon.vue';
import UiContainer from '@/components/ui/UiContainer.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLink from '@/components/ui/UiLink.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

import pkg from '@/../package.json';

type SettingsViewGroup = {
  name?: string;
  items: SettingsLinkItem[];
};

const state = reactive<{
  isDisconnectModalOpened: boolean;
  isDisconnectLoading: boolean;
}>({
  isDisconnectModalOpened: false,
  isDisconnectLoading: false
});

const { t } = useI18n();
const { y } = useWindowScroll();
const { availableThemes, theme, setTheme } = useTheme();
const { isMobile } = useViewBreakpoints();
const { replace } = useRouter();
const { logout } = useAccount();

const isScrolled = computed((): boolean => y.value > 0);

const groups = computed((): SettingsViewGroup[] =>
  (
    [
      {
        items: [
          {
            name: t('settings.theme'),
            icon: themeIcon,
            select: {
              options: availableThemes.map((item) => ({
                text: t(`settings.${item.key}`),
                value: item.schema,
                icon: `${item.schema}-theme`,
                selected: theme.value === item.schema,
                handler: (value: string) => {
                  setTheme(value as BasicColorSchema);
                }
              }))
            }
          },
          {
            name: t('settings.language'),
            icon: languageIcon,
            select: {
              options: i18n.global.availableLocales.map((value) => ({
                text: Locales[value],
                value: value,
                selected: value === unref(i18n.global.locale),
                handler: (v: string) => setLocale(v as LocaleKey)
              })),
              maxHeight: '212px'
            },
            hidden: i18n.global.availableLocales.length < 2
          }
        ]
      },
      {
        items: [
          {
            name: t('buttons.disconnect'),
            icon: disconnectIcon,
            negative: true,
            handler() {
              state.isDisconnectModalOpened = true;
            }
          }
        ]
      }
    ] as SettingsViewGroup[]
  )
    .map((group) => ({
      ...group,
      items: group.items.filter((item) => ('hidden' in item ? !item.hidden : true))
    }))
    .filter((item) => !!item.items.length)
);

function getVersion(): string {
  const gitVersion = import.meta.env.VITE_APP_VERSION;
  let value = pkg.version;

  if (gitVersion) {
    value = `${value} (${gitVersion.slice(0, 7)})`;
  }

  return value;
}

function onCloseClick(): void {
  replace({ name: RouteNames.Home });
}

async function disconnect(): Promise<void> {
  state.isDisconnectLoading = true;

  try {
    await logout();
  } catch {
    state.isDisconnectLoading = false;
  }
}

const AsyncAvatarSelector = defineAsyncComponent(() => import('@/components/AvatarSelector.vue'));
const AsyncAvatarCropperModal = defineAsyncComponent(
  () => import('@/components/AvatarCropperModal.vue')
);
</script>

<template>
  <section class="settings-view" :class="{ 'settings-view_scrolled': isScrolled }">
    <UiContainer>
      <div class="settings-view__header">
        <UiTitle class="settings-view__title" :level="1">
          {{ t('settings.title') }}
        </UiTitle>
        <UiText
          class="settings-view__title-double"
          aria-hidden="true"
          size="md"
          weight="bold"
          align="center"
        >
          {{ t('settings.title') }}
        </UiText>
        <UiButtonIcon
          v-if="isMobile"
          class="settings-view__close"
          :icon="crossLargeIcon"
          mod="transparent"
          @click="onCloseClick"
        />
      </div>
      <AsyncAvatarSelector class="settings-view__avatar" />
      <ul class="settings-view__group-list">
        <li v-for="(group, i) in groups" :key="i" class="settings-view__group-item">
          <UiText v-if="group.name" class="settings-view__group-title" size="md" tag="h3" muted>
            {{ group.name }}
          </UiText>
          <ul class="settings-view__list">
            <li v-for="item in group.items" :key="item.name" class="settings-view__item">
              <UiDropdown
                v-if="item.select"
                class="settings-view__dropdown"
                :items="item.select.options"
                :max-height="item.select.maxHeight"
                position="bottom-start"
                muted-icons
              >
                <UiLink
                  class="settings-view__link"
                  :icon="item.icon"
                  :icon-size="24"
                  :gap="16"
                  weight="medium"
                >
                  {{ item.name }}
                </UiLink>
              </UiDropdown>
              <UiLink
                v-else
                class="settings-view__link"
                :class="{ 'settings-view__link_negative': item.negative }"
                :icon="item.icon"
                :icon-size="24"
                :gap="16"
                weight="medium"
                :route="item.route ? { name: item.route } : undefined"
                @click="item.handler ? item.handler() : () => {}"
              >
                {{ item.name }}
              </UiLink>
            </li>
          </ul>
        </li>
      </ul>
      <UiText class="settings-view__version" size="sm" muted tag="p" align="center">
        {{ `${t('settings.version')} ${getVersion()}` }}
      </UiText>
    </UiContainer>
    <AsyncAvatarCropperModal />
    <ConfirmationModal
      v-model="state.isDisconnectModalOpened"
      :loading="state.isDisconnectLoading"
      mod="alert"
      @confirm="disconnect"
    >
      {{ t('settings.disconnectAndLogOut') }}
    </ConfirmationModal>
  </section>
</template>

<style scoped>
.settings-view {
  position: relative;
}

.settings-view__header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  margin: -40px 0 24px;
  padding: 40px 0 16px;
  background-color: var(--color-background);
  box-shadow: var(--shadow-background);
}

@media (max-width: 919px) {
  .settings-view__header {
    margin: -24px 0 24px;
    padding: 24px 0 16px;
  }
}

.settings-view__close {
  position: absolute;
  top: 24px;
  right: -8px;
}

.settings-view__title,
.settings-view__title-double {
  transition: opacity var(--animation-micro) var(--animation-effect);
}

.settings-view__title-double {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.settings-view_scrolled .settings-view__title {
  opacity: 0;
  pointer-events: none;
}

.settings-view:not(.settings-view_scrolled) .settings-view__title-double {
  opacity: 0;
  pointer-events: none;
}

.settings-view .settings-view__avatar {
  margin: 0 auto 40px;
}

.settings-view__group-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.settings-view__group-item {
  padding: 16px;
  background-color: var(--color-main-underlay);
  border-radius: 12px;
}

.settings-view__group-item:not(:last-child) {
  margin: 0 0 40px;
}

.settings-view__group-title {
  margin: 0 0 16px;
}

.settings-view__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.settings-view__item {
  display: flex;
}

.settings-view__item:not(:last-child) {
  margin: 0 0 16px;
}

.settings-view__dropdown {
  width: 100%;
}

.settings-view__link {
  flex-grow: 1;
  padding: 8px 0;
}

.settings-view__link_negative {
  color: var(--color-red);
}

.settings-view__version {
  margin: 80px 0 0;
}

@media (max-width: 919px) {
  .settings-view__version {
    margin: 40px 0 0;
  }
}
</style>
