import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { ApiVersionResponse, ClientConfig } from '@/references/axios/settings/types';
import type { RequestOptions } from '@/references/axios/types';
import type { ClientType } from '@/references/base';

export class HHAPISettingsService extends HHAPIService {
  constructor(
    private baseURL: string,
    private clientType: ClientType
  ) {
    super('settings.service');
  }

  /**
   * Obtains references config (de-facto the global config which controls how client app behaves)
   */
  public async getClientConfig(
    clientType: ClientType,
    opts?: RequestOptions
  ): Promise<ClientConfig> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const res = await instance.request<ClientConfig>({
      method: 'GET',
      url: '/v1/public/client/references',
      opts
    });
    return res.data.payload;
  }

  async getApiVersion(opts?: RequestOptions): Promise<ApiVersionResponse> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const res = await instance.request<ApiVersionResponse>({
      method: 'GET',
      url: '/v1/public/version',
      opts
    });
    return res.data.payload;
  }
}
