export const ROOT_DOMAIN = 'holyheld.com';
export const MILLISECOND = 1;
export const SECOND = 1000 * MILLISECOND;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const IDLE_TIMEOUT = 5 * MINUTE; // 5 min
export const GLOBAL_UPDATE_RATE = 30 * SECOND; // 1 min
export const TOASTS_TIMEOUT = 3 * SECOND; // 3s
export const KB = 1024;
export const MB = 1024 * KB;
export const TOKEN_INFO_MAX_VISIBLE_VALUE = 1000000;
export const MAX_FIAT_AMOUNT = 100000;
export const NATIVE_OPTIONS = [10000, 50000];

export const HEARTBEAT_CHECK_INTERVAL = 5 * SECOND;
export const HEARTBEAT_SEND_THROTTLE_TIMEOUT = MINUTE;
export const FORM_SET_AMOUNT_DEBOUNCE = 400 * MILLISECOND;
export const CHAT_SEEN_MESSAGES_DEBOUNCE = SECOND;

export const DELTA_BETWEEN_PAGE_RELOADS_THRESHOLD = 15;
export const MAX_TOKEN_VISIBLE_DECIMALS = 6;

export const DEFAULT_GET_DURATION = 500 * MILLISECOND;
