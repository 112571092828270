import { computed, readonly } from 'vue';

import { type BasicColorSchema, useColorMode, usePreferredDark } from '@vueuse/core';

const theme = useColorMode();

const prefersDark = usePreferredDark();
const resolvedToDark = computed(
  () => (theme.value === 'auto' && prefersDark.value) || theme.value === 'dark'
);

export const useTheme = () => {
  const setTheme = (schema: BasicColorSchema) => {
    theme.value = schema;
  };

  const availableThemes: {
    key: string;
    schema: BasicColorSchema;
  }[] = [
    { key: 'light', schema: 'light' },
    { key: 'dark', schema: 'dark' },
    { key: 'system', schema: 'auto' }
  ];

  return {
    theme: readonly(theme),
    availableThemes,
    setTheme,
    resolvedToDark: readonly(resolvedToDark)
  };
};
