import { currentClientType } from '@/helpers/utils';
import { HHAPIAuthService } from '@/references/axios/auth/HHAPIAuthService';
import { getEndpoint } from '@/references/endpoints';

let service: HHAPIAuthService | undefined;
export const useAuthAPIService = (): HHAPIAuthService => {
  if (service === undefined) {
    service = new HHAPIAuthService(getEndpoint('apiBrrr'), currentClientType());
  }

  return service;
};
