import { computed, reactive, readonly, toRefs } from 'vue';

import dayjs from 'dayjs';

import type { ServerSettings } from '@/references/axios/auth/types';

const state = reactive<{
  limits: ServerSettings;
}>({
  limits: {
    isBrrrEnabled: false,
    minBrrrAmountInEUR: '250000',
    brrrPurchaseStartTs: dayjs().add(1, 'year').unix(),
    brrrPurchaseStopTs: 0
  }
});

const setLimits = (newLimits: Partial<ServerSettings>): void => {
  state.limits = {
    ...state.limits,
    ...newLimits
  };
};

const minBrrrAmountEur = computed(() => {
  return state.limits.minBrrrAmountInEUR;
});

const brrrStartsAt = computed<number>(() => {
  return state.limits.brrrPurchaseStartTs;
});

const brrrStopsAt = computed<number>(() => {
  return state.limits.brrrPurchaseStopTs;
});

export const useSettings = () => {
  return {
    ...toRefs(readonly(state)),
    setLimits,
    minBrrrAmountEur,
    brrrStartsAt,
    brrrStopsAt
  };
};
