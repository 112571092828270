<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';

import type { UiTextAlign, UiTextSize, UiTextWeight } from '@/components/ui/UiText.types';

const props = defineProps({
  tag: {
    type: String as PropType<string>,
    default: 'span'
  },
  size: {
    /* Desktop&Mobile | [Desktop, Mobile]. */
    type: [String, Array] as PropType<UiTextSize | [UiTextSize, UiTextSize]>,
    default: undefined
  },
  weight: {
    /* Desktop&Mobile | [Desktop, Mobile]. */
    type: [String, Array] as PropType<UiTextWeight | [UiTextWeight, UiTextWeight]>,
    default: undefined
  },
  align: {
    /* Desktop&Mobile | [Desktop, Mobile]. */
    type: [String, Array] as PropType<UiTextAlign | [UiTextAlign, UiTextAlign]>,
    default: undefined
  },
  uppercase: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  muted: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  lineThrough: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const desktopSize = computed((): string | undefined =>
  Array.isArray(props.size) ? props.size[0] : props.size
);

const mobileSize = computed((): string | undefined =>
  Array.isArray(props.size) ? props.size[1] : undefined
);

const desktopWeight = computed((): string | undefined =>
  Array.isArray(props.weight) ? props.weight[0] : props.weight
);

const mobileWeight = computed((): string | undefined =>
  Array.isArray(props.weight) ? props.weight[1] : undefined
);

const desktopAlign = computed((): string | undefined =>
  Array.isArray(props.align) ? props.align[0] : props.align
);

const mobileAlign = computed((): string | undefined =>
  Array.isArray(props.align) ? props.align[1] : undefined
);

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-text_size_${desktopSize.value}`]: !!desktopSize.value,
    [`ui-text_mobile-size_${mobileSize.value}`]: !!mobileSize.value,
    [`ui-text_weight_${desktopWeight.value}`]: !!desktopWeight.value,
    [`ui-text_mobile-weight_${mobileWeight.value}`]: !!mobileWeight.value,
    [`ui-text_align_${desktopAlign.value}`]: !!desktopAlign.value,
    [`ui-text_mobile-align_${mobileAlign.value}`]: !!mobileAlign.value,
    'ui-text_uppercase': props.uppercase,
    'ui-text_muted': props.muted,
    'ui-text_line_through': props.lineThrough
  })
);
</script>

<template>
  <component :is="props.tag" class="ui-text" :class="classList">
    <slot />
  </component>
</template>

<style scoped>
.ui-text {
  margin: 0;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  text-align: inherit;
}

.ui-text_size_xxl {
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
}

.ui-text_size_xl {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
}

.ui-text_size_lg {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
}

.ui-text_size_md {
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
}

.ui-text_size_sm {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.ui-text_size_xs {
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
}

.ui-text_size_xxs {
  font-size: var(--font-size-xxs);
  line-height: var(--line-height-xxs);
}

@media (max-width: 919px) {
  .ui-text_mobile-size_xxl {
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-xxl);
  }

  .ui-text_mobile-size_xl {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
  }

  .ui-text_mobile-size_lg {
    font-size: var(--font-size-lg);
    line-height: var(--line-height-lg);
  }

  .ui-text_mobile-size_md {
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
  }

  .ui-text_mobile-size_sm {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
  }

  .ui-text_mobile-size_xs {
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
  }

  .ui-text_mobile-size_xxs {
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-xxs);
  }
}

.ui-text_weight_bold {
  font-weight: var(--font-weight-bold);
}

.ui-text_weight_semibold {
  font-weight: var(--font-weight-semibold);
}

.ui-text_weight_medium {
  font-weight: var(--font-weight-medium);
}

.ui-text_weight_regular {
  font-weight: var(--font-weight-regular);
}

@media (max-width: 919px) {
  .ui-text_mobile-weight_bold {
    font-weight: var(--font-weight-bold);
  }

  .ui-text_mobile-weight_semibold {
    font-weight: var(--font-weight-semibold);
  }

  .ui-text_mobile-weight_medium {
    font-weight: var(--font-weight-medium);
  }

  .ui-text_mobile-weight_regular {
    font-weight: var(--font-weight-regular);
  }
}

.ui-text_align_left {
  text-align: left;
}

.ui-text_align_center {
  text-align: center;
}

.ui-text_align_right {
  text-align: right;
}

@media (max-width: 919px) {
  .ui-text_mobile-align_left {
    text-align: left;
  }

  .ui-text_mobile-align_center {
    text-align: center;
  }

  .ui-text_mobile-align_right {
    text-align: right;
  }
}

.ui-text_uppercase {
  text-transform: uppercase;
}

.ui-text_muted {
  color: var(--color-secondary-text);
}

.ui-text_line_through {
  text-decoration-line: line-through;
}
</style>
