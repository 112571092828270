<script setup lang="ts">
import { computed, ref, watch } from 'vue';

import { vElementSize } from '@vueuse/components';

import { useErrorModal } from '@/composables/useErrorModal';

import ToastListToast from '@/components/ToastListToast.vue';

const { toasts, close } = useErrorModal();

const toastHeight = ref<Record<string, number>>({});

const topPositions = computed((): number[] => {
  const values: number[] = [];

  toasts.value.forEach((item, index) => {
    if (!index) {
      values.push(0);
      return;
    }

    const prevTop = values[values.length - 1];
    const prevHeight = toastHeight.value[toasts.value[index - 1].id];
    const currentHeight = toastHeight.value[toasts.value[index].id];

    values.push(prevTop + prevHeight - currentHeight / 2);
  });

  return values;
});

function onResize(id: string, { height }: { height: number }): void {
  toastHeight.value[id] = height;
}

watch(toasts, () => {
  Object.keys(toastHeight).forEach((id) => {
    if (!toasts.value.find((item) => item.id === id)) {
      delete toastHeight.value[id];
    }
  });
});
</script>

<template>
  <ul class="toast-list">
    <TransitionGroup name="toast">
      <li
        v-for="(toast, index) in toasts"
        :key="toast.id"
        v-element-size="(elementSize) => onResize(toast.id, elementSize)"
        :style="{ transform: `translate3d(0, ${topPositions[index]}px, 0)` }"
        class="toast-list__item"
      >
        <ToastListToast
          :id="toast.id"
          class="toast-list__toast"
          :error="toast.error"
          @click="close(toast.id)"
        />
      </li>
    </TransitionGroup>
  </ul>
</template>

<style scoped>
.toast-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.toast-list__item {
  position: fixed;
  top: 56px;
  left: calc(50% - 400px);
  z-index: 300;
  display: flex;
  justify-content: center;
  width: 800px;
  transition:
    transform var(--animation-medium) var(--animation-effect),
    opacity var(--animation-medium) var(--animation-effect);
  pointer-events: none;
}

@media (max-width: 879px) {
  .toast-list__item {
    left: 16px;
    width: calc(100% - 32px);
  }
}

.toast-list__toast {
  pointer-events: auto;
}
</style>
