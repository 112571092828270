<script setup lang="ts">
import { useI18n } from '@/references/i18n';

import type { UiButtonMod } from '@/components/ui/UiButton.types';
import UiButton from '@/components/ui/UiButton.vue';
import type { UiImportantMod } from '@/components/ui/UiImportant.types';
import UiImportant from '@/components/ui/UiImportant.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

interface Props {
  modelValue: boolean;
  loading?: boolean;
  disabled?: boolean;
  title?: string;
  mod?: UiImportantMod;
  cancelButtonMode?: UiButtonMod;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  disabled: false,
  title: undefined,
  mod: 'default',
  cancelButtonMode: 'secondary',
  cancelButtonText: undefined,
  confirmButtonText: undefined
});

const { t } = useI18n();

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
  (event: 'confirm'): void;
}>();

function onClick(): void {
  emit('confirm');
}

function close(): void {
  emit('update:modelValue', false);
}
</script>

<template>
  <UiModal class="confirmation-modal" :model-value="props.modelValue" @update:model-value="close">
    <UiImportant class="confirmation-modal__icon" :mod="props.mod" />
    <UiTitle class="confirmation-modal__title" :level="2" size="xl" align="center">
      {{ props.title ?? t('phrases.areYouSure') }}
    </UiTitle>
    <UiText class="confirmation-modal__description" tag="p" size="md" align="center" muted>
      <slot />
    </UiText>
    <div class="confirmation-modal__buttons">
      <UiButton class="confirmation-modal__button" :mod="props.cancelButtonMode" @click="close">
        {{ props.cancelButtonText ?? t('buttons.cancel') }}
      </UiButton>
      <UiButton
        class="confirmation-modal__button"
        :mod="props.mod === 'alert' ? 'negative' : 'primary'"
        :loading="props.loading"
        :disabled="props.disabled"
        @click="onClick"
      >
        {{ props.confirmButtonText ?? t('buttons.confirm') }}
      </UiButton>
    </div>
  </UiModal>
</template>

<style scoped>
.confirmation-modal__icon {
  margin: 0 auto 24px;
}

.confirmation-modal__title {
  margin: 0 0 8px;
}

.confirmation-modal__description {
  margin: 0 0 40px;
}

.confirmation-modal__description strong {
  color: var(--color-main-text);
}

.confirmation-modal__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 413px) {
  .confirmation-modal__buttons {
    flex-direction: column-reverse;
  }
}

.confirmation-modal__button {
  width: calc(50% - 12px);
}

@media (max-width: 413px) {
  .confirmation-modal__button {
    width: 100%;
  }
}

@media (max-width: 413px) {
  .confirmation-modal__button:not(:first-child) {
    margin: 0 0 16px;
  }
}
</style>
