import { sameAddress } from '@/helpers/addresses';
import type { Address } from '@/references/base';
import { getConfig } from '@/references/config';

import { isBaseAssetByNetwork, Network } from './network';

export interface Token {
  address: Address;
  decimals: number;
  symbol: string;
  network: Network;
  name: string;
  iconURL: string;
}

export interface PermitData {
  hasPermit: boolean;
  permitVersion?: string;
  permitType?: string;
}

export type TokenWithPrice = Token & {
  priceUSD: string;
};

export type TokenWithEurPrice = Token & {
  priceEUR: string;
};

export type TokenWithBalance = Token & {
  balance: string;
};

export type TokenWithPriceAndBalance = TokenWithBalance & TokenWithPrice;

export const isTokenHasPermitData = (
  token: Token | (Token & PermitData)
): token is Token & PermitData => {
  return 'hasPermit' in token && token.hasPermit !== undefined;
};

export const substituteAssetAddressIfNeeded = (
  address: Address,
  network: Network,
  replacement: Address = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
): Address => {
  if (isBaseAssetByNetwork(address, network)) {
    return replacement;
  }

  return address;
};

export const requiresZeroAllowanceBeforeApprove = (address: Address, network: Network): boolean => {
  const config = getConfig();

  if (
    config === undefined ||
    config[network] === undefined ||
    config[network].tokensRequiresZeroAllowance === undefined
  ) {
    return false;
  }

  return (
    config[network].tokensRequiresZeroAllowance?.some((address_) =>
      sameAddress(address_, address)
    ) ?? false
  );
};

export const getBrrrToken = (network: Network): Token => {
  const config = getConfig();

  if (config === undefined) {
    throw new Error('Config is not defined');
  }

  if (config[network] === undefined) {
    throw new Error(`Config for network ${network} is not defined`);
  }

  if (config[network].brrrToken === undefined) {
    throw new Error(`BRRR token for network ${network} is not defined`);
  }

  return config[network].brrrToken;
};

export const getBrrrSwapTarget = (network: Network): Token => {
  const config = getConfig();

  if (config === undefined) {
    throw new Error('Config is not defined');
  }

  if (config[network] === undefined) {
    throw new Error(`Config for network ${network} is not defined`);
  }

  if (config[network].swapTargetForBrrr === undefined) {
    throw new Error(`BRRR swap target token for network ${network} is not defined`);
  }

  return config[network].swapTargetForBrrr;
};

export const isBrrrSwapTarget = (address: Address, network: Network): boolean => {
  try {
    return sameAddress(getBrrrSwapTarget(network).address, address);
  } catch {
    return false;
  }
};

export const getAdditionalGasBufferPercent = (address: Address, network: Network): bigint => {
  const config = getConfig();

  if (
    config === undefined ||
    config[network] === undefined ||
    config[network].tokensWithAdditionalGasBuffer === undefined
  ) {
    return 20n;
  }

  const customGasBuffer = config[network].tokensWithAdditionalGasBuffer?.[address];
  if (customGasBuffer === undefined) {
    return 20n;
  }

  return BigInt(customGasBuffer);
};
