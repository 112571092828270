<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';

import type { UiContainerSize } from '@/components/ui/UiContainer.types';

const props = defineProps({
  size: {
    type: String as PropType<UiContainerSize>,
    default: 'md'
  }
});

const classList = computed((): string[] => [`ui-container_size_${props.size}`]);
</script>

<template>
  <div class="ui-container" :class="classList">
    <slot />
  </div>
</template>

<style scoped>
.ui-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

.ui-container_size_md {
  max-width: 640px;
}

.ui-container_size_sm {
  max-width: 496px;
}

@media (max-width: 919px) {
  .ui-container {
    padding: 0 24px;
  }

  .ui-container_size_md {
    max-width: none;
  }

  .ui-container_size_sm {
    max-width: none;
  }
}
</style>
