import { currentClientType } from '@/helpers/utils';
import { HHAPISettingsService } from '@/references/axios/settings/HHAPISettingsService';
import { getEndpoint } from '@/references/endpoints';

let service: HHAPISettingsService | undefined;
export const useSettingsService = (): HHAPISettingsService => {
  if (service === undefined) {
    service = new HHAPISettingsService(getEndpoint('apiBrrr'), currentClientType());
  }

  return service;
};
