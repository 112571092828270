const maxInitialsLength = 2;
export function getInitials(name: string): string {
  return name
    .trim()
    .replaceAll(/\s+/g, ' ')
    .split(' ')
    .map((item) => (item.length > 0 ? item[0].toUpperCase() : ''))
    .filter((item) => item !== '')
    .join('')
    .slice(0, maxInitialsLength);
}
