<script setup lang="ts">
import { RouterView } from 'vue-router';

import { useInitFlow } from '@/composables/useInitFlow';
import { fade } from '@/helpers/motion';
import Error500View from '@/views/Error500View.vue';

import ToastList from '@/components/ToastList.vue';
import UiPreloader from '@/components/ui/UiPreloader.vue';
const { init, uiUnlocked, globalErrorState } = useInitFlow();

init();
</script>

<template>
  <Error500View v-if="globalErrorState" v-motion="fade" :closable="false" @update="init" />
  <UiPreloader v-else-if="!uiUnlocked" v-motion="fade" />
  <RouterView v-else />
  <ToastList />
</template>
