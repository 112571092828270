<script setup lang="ts">
import type { ComponentPublicInstance } from 'vue';
import { onBeforeUnmount, ref, useAttrs, watch } from 'vue';

import crossIcon from '@/assets/icons/cross.svg';
import { lock, unlock } from '@/helpers/bodyScrollLock';
import { KeyboardKey } from '@/helpers/events';

import UiButtonIcon from '@/components/ui/UiButtonIcon.vue';

const FOCUSABLE_ELEMENTS = [
  'area[href]',
  'a[href]:not([tabindex^="-"])',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden]):not([tabindex^="-"])',
  'select:not([disabled]):not([aria-hidden]):not([tabindex^="-"])',
  'textarea:not([disabled]):not([aria-hidden]):not([tabindex^="-"])',
  'button:not([disabled]):not([aria-hidden]):not([tabindex^="-"])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])'
];

defineOptions({
  inheritAttrs: false
});

interface Props {
  modelValue?: boolean;
  focusFirstElement?: boolean;
  padding?: 'md' | 'sm';
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  focusFirstElement: true,
  padding: 'md'
});

const emit = defineEmits<(e: 'update:modelValue', value: boolean) => void>();

let activeElement: Element | HTMLElement | null = null;
let lockId: string | null = null;

const root = ref<HTMLElement | null>(null);
const inner = ref<HTMLElement | null>(null);
const closeButton = ref<ComponentPublicInstance | null>(null);

const attrs = useAttrs();

function closeModal(): void {
  emit('update:modelValue', false);
}

function getFocusableNodes(): (Element | HTMLElement)[] {
  const nodes = root.value?.querySelectorAll(FOCUSABLE_ELEMENTS.join(',')) || [];
  return Array(...nodes);
}

function setFocusToFirstNode(): void {
  const focusableNodes = getFocusableNodes();

  if (!focusableNodes.length) {
    return;
  }

  const nodesWithoutCloseButton = focusableNodes.filter(
    (node: Element | HTMLElement) => !node.isEqualNode(closeButton.value?.$el)
  );

  const target = (
    nodesWithoutCloseButton.length ? nodesWithoutCloseButton[0] : focusableNodes[0]
  ) as HTMLElement;

  target.focus();
  target.classList.remove('focus-visible');
}

function retainFocus(event: KeyboardEvent): void {
  let focusableNodes = getFocusableNodes();

  if (focusableNodes.length === 0) {
    return;
  }

  focusableNodes = focusableNodes.filter(
    (node: Element | HTMLElement) =>
      (node as HTMLElement).offsetParent !== null || node.isEqualNode(closeButton.value?.$el)
  );

  const focusedItemIndex = focusableNodes.indexOf(document.activeElement as HTMLElement);

  if (focusedItemIndex === -1) {
    (focusableNodes[0] as HTMLElement).focus();
    event.preventDefault();
  }

  if (event.shiftKey && focusedItemIndex === 0) {
    (focusableNodes[focusableNodes.length - 1] as HTMLElement).focus();
    event.preventDefault();
  }

  if (
    !event.shiftKey &&
    focusableNodes.length > 0 &&
    focusedItemIndex === focusableNodes.length - 1
  ) {
    (focusableNodes[0] as HTMLElement).focus();
    event.preventDefault();
  }
}

function disableScroll(): void {
  if (!inner.value) {
    return;
  }

  lockId = lock(inner.value as HTMLElement);
}

function enableScroll(): void {
  if (!inner.value) {
    return;
  }

  if (lockId) {
    unlock(lockId);
    lockId = null;
  }
}

function onKeydown(event: KeyboardEvent): void {
  if (event.key === KeyboardKey.Esc) {
    closeModal();
  }
  if (event.key === KeyboardKey.Tab) {
    retainFocus(event);
  }
}

function onInnerClick(event: Event): void {
  if (inner.value === event.target) {
    closeModal();
  }
}

function onOpened(): void {
  document.addEventListener('keydown', onKeydown);
  activeElement = document.activeElement;
  setTimeout(() => {
    disableScroll();
    if (props.focusFirstElement) {
      setFocusToFirstNode();
    } else if (activeElement && (activeElement as HTMLElement).focus) {
      (activeElement as HTMLElement).blur();
    }
  });
}

function onClosed(): void {
  document.removeEventListener('keydown', onKeydown);
  enableScroll();
  if (activeElement && (activeElement as HTMLElement).focus) {
    (activeElement as HTMLElement).focus();
    activeElement.classList.remove('focus-visible');
  }
}

onBeforeUnmount(() => {
  enableScroll();

  if (props.modelValue) {
    closeModal();
  }
});

watch(
  () => props.modelValue,
  (value: boolean): void => {
    if (value) {
      onOpened();
    } else {
      onClosed();
    }
  }
);
</script>

<template>
  <Teleport to="body">
    <Transition name="ui-modal">
      <div v-if="props.modelValue" ref="root" class="ui-modal" v-bind="attrs">
        <div ref="inner" class="ui-modal__inner" @click="onInnerClick">
          <div
            class="ui-modal__dialog"
            :class="`ui-modal__dialog_padding_${props.padding}`"
            role="dialog"
            aria-modal="true"
          >
            <UiButtonIcon
              ref="closeButton"
              class="ui-modal__close-button"
              size="sm"
              mod="secondary"
              :icon="crossIcon"
              @click="closeModal"
            />
            <slot name="default" />
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.ui-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.ui-modal__inner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--color-modal-background);
}

@media (hover: hover) {
  .ui-modal__inner {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ui-modal__inner::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 919px) {
  .ui-modal__inner {
    padding: 24px 16px;
  }
}

.ui-modal__dialog {
  position: relative;
  width: 100%;
  max-width: 496px;
  margin: 96px auto auto;
  background-color: var(--color-modal);
  border-radius: 30px;
  box-shadow: var(--shadow-base);
}

@media (max-width: 919px) {
  .ui-modal__dialog {
    max-width: 100%;
    margin: auto auto 0;
  }
}

.ui-modal__dialog_padding_md {
  padding: 48px 40px;
}

@media (max-width: 919px) {
  .ui-modal__dialog_padding_md {
    padding: 48px 24px;
  }
}

.ui-modal__dialog_padding_sm {
  padding: 24px;
}

.ui-modal__close-button {
  position: absolute;
  top: 24px;
  right: 24px;
}

.ui-modal-enter-from,
.ui-modal-leave-to {
  opacity: 0;
}

.ui-modal-enter-from .ui-modal__dialog,
.ui-modal-leave-to .ui-modal__dialog {
  transform: scale(0.9);
}

@media (max-width: 919px) {
  .ui-modal-enter-from .ui-modal__dialog,
  .ui-modal-leave-to .ui-modal__dialog {
    transform: translate3d(0, 40px, 0);
  }
}

.ui-modal-enter-active,
.ui-modal-leave-active {
  transition: opacity var(--animation-micro) var(--animation-effect);
  will-change: opacity;
}

.ui-modal-enter-active .ui-modal__dialog,
.ui-modal-leave-active .ui-modal__dialog {
  transition: transform var(--animation-micro) var(--animation-effect);
  will-change: transform;
}

.ui-modal-enter-active .ui-modal__inner {
  overflow-x: visible;
  overflow-y: visible;
}
</style>
