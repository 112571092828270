import { unref, watch } from 'vue';
import { createI18n, useI18n as useI18n_ } from 'vue-i18n';

import messages from '@intlify/unplugin-vue-i18n/messages';
import { useLocalStorage } from '@vueuse/core';
import axios from 'axios';
import dayjs from 'dayjs';

import type en from '@/locales/en.json';

export type LocaleKey = 'en';

export const Locales: Record<LocaleKey, string> = {
  en: 'English'
};

const selectedLocale = useLocalStorage<LocaleKey>('locale', 'en');

export const i18n = createI18n<[typeof en], LocaleKey>({
  locale: selectedLocale.value,
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: false,
  messages: messages as { en: typeof en }
});

export function setLocale(locale: LocaleKey) {
  i18n.global.locale = locale;
}

watch(
  () => unref(i18n.global.locale),
  (value) => {
    axios.defaults.headers.common['Accept-Language'] = value;
    document.querySelector('html')?.setAttribute('lang', value);
    dayjs.locale(value);
  },
  {
    immediate: true
  }
);

export const t = i18n.global.t;
export const n = i18n.global.n;

export const useI18n = () => useI18n_<{ message: typeof en }, LocaleKey>({ useScope: 'global' });
