<script setup lang="ts">
import { computed, onMounted, type PropType, ref } from 'vue';

import { useClipboard, useLocalStorage } from '@vueuse/core';

import arrowLongDownIcon from '@/assets/icons/arrow-long-down.svg';
import arrowLongUpIcon from '@/assets/icons/arrow-long-up.svg';
import { useErrorModal } from '@/composables/useErrorModal';
import { useViewBreakpoints } from '@/composables/useViewBreakpoints';
import { placeholderBackgroundColor } from '@/helpers/colors';
import { getInitials } from '@/helpers/formatters';
import { useI18n } from '@/references/i18n';

import TokenImage from '@/components/TokenImage.vue';
import UiBadge from '@/components/ui/UiBadge.vue';
import UiBlinker from '@/components/ui/UiBlinker.vue';
import UiColoredCircle from '@/components/ui/UiColoredCircle.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiLink from '@/components/ui/UiLink.vue';
import type { UiListPairItem } from '@/components/ui/UiListPair.types';
import UiText from '@/components/ui/UiText.vue';
import UiTip from '@/components/ui/UiTip.vue';

const props = defineProps({
  item: {
    type: Object as PropType<UiListPairItem>,
    required: true
  }
});

const emit = defineEmits<(event: 'click:item') => void>();

const tipComponent = ref<typeof UiTip>();

const isTipClosed = useLocalStorage<boolean | null>(props.item.tip?.key || '', null);
const { copy, isSupported } = useClipboard({ legacy: true });
const { notify } = useErrorModal();

const { t } = useI18n();
const { lessThanSM } = useViewBreakpoints();

const isValueWithLink = computed(
  (): boolean =>
    !!props.item.clickable || !!props.item.link || (!!props.item.copy && isSupported.value)
);

function onTipHide(): void {
  isTipClosed.value = true;
  tipComponent.value?.hide();
}

async function onValueClick(): Promise<void> {
  if (props.item.tip?.key && !isTipClosed.value) {
    tipComponent.value?.hide();
  }

  if (props.item.copy && isSupported.value) {
    await copy(props.item.copy);
    notify(t('toasts.copyResultSuccess'));
  }

  if (!props.item.clickable) {
    return;
  }

  emit('click:item');
}

onMounted(() => {
  if (props.item.tip?.key && !isTipClosed.value) {
    tipComponent.value?.show();
  }
});
</script>

<template>
  <li
    :key="props.item.label"
    class="ui-list-pair-item"
    :class="{ 'ui-list-pair-item_error': !!props.item.error }"
  >
    <UiText class="ui-list-pair-item__label" size="sm" weight="medium" :muted="!props.item.error">
      <span class="ui-list-pair-item__label-inner">{{ props.item.label }}</span>
    </UiText>
    <UiText
      class="ui-list-pair-item__value"
      :class="{
        'ui-list-pair-item__value_nowrap': !!props.item.nowrap,
        'ui-list-pair-item__value_positive': !!props.item.positive,
        'ui-list-pair-item__value_negative': !!props.item.negative
      }"
      size="sm"
      weight="medium"
    >
      <UiBadge v-if="props.item.bordered || !props.item.value" :muted="!props.item.value">
        {{ props.item.value || t('phrases.notAvailable') }}
      </UiBadge>
      <template v-else>
        <img
          v-if="props.item.image"
          class="ui-list-pair-item__value-image"
          :src="props.item.image"
          alt=""
        />
        <TokenImage
          v-else-if="props.item.token"
          class="ui-list-pair-item__value-token"
          :token="props.item.token"
          size="sm"
        />
        <UiColoredCircle
          v-else-if="props.item.coloredCircleText || props.item.coloredCircleColor"
          class="ui-list-pair-item__value-colored-circle"
          :color="
            props.item.coloredCircleColor ??
            placeholderBackgroundColor(props.item.coloredCircleText)
          "
          :text="
            props.item.coloredCircleText
              ? getInitials(props.item.coloredCircleText.slice(0))
              : undefined
          "
          :icon="props.item.coloredCircleIcon"
          size="sm"
        />
        <UiIcon
          v-else-if="props.item.positive || props.item.negative"
          class="ui-list-pair-item__arrow"
          :name="props.item.positive ? arrowLongUpIcon : arrowLongDownIcon"
          :size="16"
        />
        <UiBlinker class="ui-list-pair-item__value-text" :blinking="!!props.item.blink">
          <component
            :is="isValueWithLink ? UiLink : 'span'"
            :weight="isValueWithLink ? 'medium' : undefined"
            :mod="isValueWithLink ? 'contrasty' : undefined"
            :href="props.item.link"
            :target="props.item.link ? '_blank' : undefined"
            @click="onValueClick"
          >
            <UiIcon
              v-if="props.item.icon"
              class="ui-list-pair-item__icon"
              :name="props.item.icon"
              :size="17"
            />
            {{ props.item.value }}
          </component>
        </UiBlinker>
        <div class="ui-list-pair-item__tip-wrapper">
          <UiTip
            v-if="props.item.tip"
            ref="tipComponent"
            :title="props.item.tip.title"
            :description="props.item.tip.description"
            :position="lessThanSM ? 'bottom-start' : 'bottom-end'"
            :distance="16"
            @hide="onTipHide"
          />
        </div>
      </template>
    </UiText>
  </li>
</template>

<style scoped>
.ui-list-pair-item {
  display: flex;
}

@media (max-width: 374px) {
  .ui-list-pair-item {
    display: block;
  }
}

.ui-list-pair-item_error {
  color: var(--color-red);
}

.ui-list-pair-item__label {
  margin: 0 16px 0 0;
}

.ui-list-pair-item__label-inner {
  vertical-align: middle;
}

@media (max-width: 374px) {
  .ui-list-pair-item__label {
    display: block;
    margin: 0 0 4px;
  }
}

.ui-list-pair-item__value {
  position: relative;
  max-width: 75%;
  margin: 0 0 0 auto;
  text-align: right;
  overflow-wrap: break-word;
}

.ui-list-pair-item__value_nowrap {
  white-space: nowrap;
}

@media (max-width: 374px) {
  .ui-list-pair-item__value {
    display: block;
    max-width: none;
    margin: 0;
    text-align: left;
  }

  .ui-list-pair-item__value_nowrap {
    white-space: normal;
  }
}

.ui-list-pair-item__value_positive {
  color: var(--color-green);
}

.ui-list-pair-item__value_negative {
  color: var(--color-red);
}

.ui-list-pair-item__value-image {
  display: inline-block;
  width: 16px;
  min-width: 16px;
  height: 16px;
  vertical-align: middle;
  border-radius: 50%;
}

.ui-list-pair-item__value-token {
  display: inline-flex;
  vertical-align: middle;
}

.ui-list-pair-item__value-colored-circle {
  display: inline-flex;
  vertical-align: middle;
}

.ui-list-pair-item__arrow {
  display: inline-flex;
  margin: 0 -2px 0 0;
  vertical-align: middle;
}

.ui-list-pair-item__icon {
  display: inline-flex;
  vertical-align: text-top;
}

.ui-list-pair-item__value-text {
  vertical-align: middle;
}

.ui-list-pair-item__value-text:not(:first-child) {
  margin: 0 0 0 4px;
}

.ui-list-pair-item__tip-wrapper {
  position: absolute;
  top: 100%;
  right: 24px;
  pointer-events: none;
}

@media (max-width: 374px) {
  .ui-list-pair-item__tip-wrapper {
    right: auto;
    left: 24px;
  }
}
</style>
