<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';

import type {
  UiTitleAlign,
  UiTitleLevel,
  UiTitleSize,
  UiTitleWeight
} from '@/components/ui/UiTitle.types';

const props = defineProps({
  level: {
    type: Number as PropType<UiTitleLevel>,
    required: true
  },
  weight: {
    /* Desktop&Mobile | [Desktop, Mobile]. */
    type: [String, Array] as PropType<UiTitleWeight | [UiTitleWeight, UiTitleWeight]>,
    default: 'bold'
  },
  size: {
    /* Desktop&Mobile | [Desktop, Mobile]. */
    type: [String, Array] as PropType<UiTitleSize | [UiTitleSize, UiTitleSize]>,
    default: undefined
  },
  align: {
    /* Desktop&Mobile | [Desktop, Mobile]. */
    type: [String, Array] as PropType<UiTitleAlign | [UiTitleAlign, UiTitleAlign]>,
    default: undefined
  }
});

const tag = computed((): string => `h${props.level}`);

const desktopWeight = computed((): string =>
  Array.isArray(props.weight) ? props.weight[0] : props.weight
);

const mobileWeight = computed((): string | undefined =>
  Array.isArray(props.weight) ? props.weight[1] : undefined
);

const desktopSize = computed((): string | undefined =>
  Array.isArray(props.size) ? props.size[0] : props.size
);

const mobileSize = computed((): string | undefined =>
  Array.isArray(props.size) ? props.size[1] : undefined
);

const desktopAlign = computed((): string | undefined =>
  Array.isArray(props.align) ? props.align[0] : props.align
);

const mobileAlign = computed((): string | undefined =>
  Array.isArray(props.align) ? props.align[1] : undefined
);

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-title_weight_${desktopWeight.value}`]: true,
    [`ui-title_mobile-weight_${mobileWeight.value}`]: !!mobileWeight.value,
    [`ui-title_size_${desktopSize.value}`]: !!desktopSize.value,
    [`ui-title_mobile-size_${mobileSize.value}`]: !!mobileSize.value,
    [`ui-title_align_${desktopAlign.value}`]: !!desktopAlign.value,
    [`ui-title_mobile-align_${mobileAlign.value}`]: !!mobileAlign.value
  })
);
</script>

<template>
  <component :is="tag" class="ui-title" :class="classList">
    <slot />
  </component>
</template>

<style scoped>
.ui-title {
  margin: 0;
  color: var(--color-main-text);
}

.ui-title_weight_bold {
  font-weight: var(--font-weight-bold);
}

.ui-title_weight_semibold {
  font-weight: var(--font-weight-semibold);
}

.ui-title_weight_medium {
  font-weight: var(--font-weight-medium);
}

.ui-title_weight_regular {
  font-weight: var(--font-weight-regular);
}

@media (max-width: 919px) {
  .ui-title_mobile-weight_bold {
    font-weight: var(--font-weight-bold);
  }

  .ui-title_mobile-weight_semibold {
    font-weight: var(--font-weight-semibold);
  }

  .ui-title_mobile-weight_medium {
    font-weight: var(--font-weight-medium);
  }

  .ui-title_mobile-weight_regular {
    font-weight: var(--font-weight-regular);
  }
}

.ui-title_size_xxl {
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
}

.ui-title_size_xl {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
}

.ui-title_size_lg {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-lg);
}

.ui-title_size_md {
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
}

.ui-title_size_sm {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.ui-title_size_xs {
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
}

.ui-title_size_xxs {
  font-size: var(--font-size-xxs);
  line-height: var(--line-height-xxs);
}

@media (max-width: 919px) {
  .ui-title_mobile-size_xxl {
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-xxl);
  }

  .ui-title_mobile-size_xl {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
  }

  .ui-title_mobile-size_lg {
    font-size: var(--font-size-lg);
    line-height: var(--line-height-lg);
  }

  .ui-title_mobile-size_md {
    font-size: var(--font-size-md);
    line-height: var(--line-height-md);
  }

  .ui-title_mobile-size_sm {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
  }

  .ui-title_mobile-size_xs {
    font-size: var(--font-size-xs);
    line-height: var(--line-height-xs);
  }

  .ui-title_mobile-size_xxs {
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-xxs);
  }
}

.ui-title_align_left {
  text-align: left;
}

.ui-title_align_center {
  text-align: center;
}

.ui-title_align_right {
  text-align: right;
}

@media (max-width: 919px) {
  .ui-title_mobile-align_left {
    text-align: left;
  }

  .ui-title_mobile-align_center {
    text-align: center;
  }

  .ui-title_mobile-align_right {
    text-align: right;
  }
}

h1 {
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
}

h2 {
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
}

h3 {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
}

h4 {
  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
}

h5 {
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
}

h6 {
  font-size: var(--font-size-h6);
  line-height: var(--line-height-h6);
}
</style>
