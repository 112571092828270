<script setup lang="ts">
import { useI18n } from '@/references/i18n';

import UiButton from '@/components/ui/UiButton.vue';
import type { UiImportantMod } from '@/components/ui/UiImportant.types';
import UiImportant from '@/components/ui/UiImportant.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

interface Props {
  title: string;
  modelValue: boolean;
  iconMod?: UiImportantMod;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
}>();

const { t } = useI18n();

function close(): void {
  emit('update:modelValue', false);
}
</script>

<template>
  <UiModal class="tip-modal" :model-value="props.modelValue" @update:model-value="close">
    <UiImportant class="tip-modal__icon" :mod="props.iconMod" />
    <UiTitle class="tip-modal__title" :level="2" size="xl" align="center">
      {{ props.title }}
    </UiTitle>
    <UiText class="tip-modal__description" tag="p" size="md" align="center" muted>
      <slot />
    </UiText>
    <UiButton class="tip-modal__button" mod="secondary" @click="close">
      {{ t('buttons.gotIt') }}
    </UiButton>
  </UiModal>
</template>

<style scoped>
.tip-modal__icon {
  margin: 0 auto 24px;
}

.tip-modal__title {
  margin: 0 0 8px;
}

.tip-modal__description {
  margin: 0 0 40px;
  white-space: pre-wrap;
}
</style>
