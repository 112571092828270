<script setup lang="ts">
import { computed } from 'vue';

import logoFullIcon from '@/assets/icons/logo-full.svg';

import UiIcon from '@/components/ui/UiIcon.vue';

interface Props {
  overlayed?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  overlayed: true
});

const zIndex = computed((): number => (props.overlayed ? 100 : 0));
</script>

<template>
  <div class="ui-preloader">
    <UiIcon class="ui-preloader__icon" :name="logoFullIcon" />
  </div>
</template>

<style scoped>
.ui-preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: v-bind(zIndex);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-background);
}

.ui-preloader__icon {
  width: 132px;
  height: 32px;
  animation: ui-preloader-pulse 1s ease-in-out infinite;
}

@keyframes ui-preloader-pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
</style>
