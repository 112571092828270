<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core';

import { useI18n } from '@/references/i18n';

import UiButton from '@/components/ui/UiButton.vue';
import UiLink from '@/components/ui/UiLink.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

const emit = defineEmits<(event: 'next') => void>();

const { t } = useI18n();

onKeyStroke('Enter', () => emit('next'));
</script>

<template>
  <section class="auth-welcome">
    <div class="auth-welcome__body">
      <img class="auth-welcome__logo" src="@/assets/images/logo@3x.png" alt="logo" />
      <UiTitle class="auth-welcome__title" :level="1" align="center">{{
        t('onboarding.landing.title')
      }}</UiTitle>
      <UiText class="auth-welcome__description" tag="p" weight="medium" muted align="center">{{
        t('onboarding.landing.description')
      }}</UiText>
      <UiButton class="auth-welcome__button" @click="emit('next')">
        {{ t('buttons.signIn') }}
      </UiButton>
    </div>
    <div class="auth-welcome__footer">
      <UiText tag="p" size="xs" muted align="center">
        <i18n-t keypath="onboarding.landing.disclaimer" scope="global">
          <UiLink href="https://holyheld.com/legal/terms-and-conditions" target="_blank">
            {{ t('onboarding.landing.termsAndConditions') }}
          </UiLink>
        </i18n-t>
      </UiText>
    </div>
  </section>
</template>

<style scoped>
.auth-welcome {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.auth-welcome__body {
  width: 334px;
  max-width: 100%;
  margin: auto 0 40px;
}

.auth-welcome__logo {
  display: block;
  width: 64px;
  height: 64px;
  margin: 0 auto 40px;
}

.auth-welcome__title {
  margin: 0 0 16px;
}

.auth-welcome__description {
  margin: 0 0 40px;
}

.auth-welcome__button {
  width: 100%;
  max-width: 334px;
}

.auth-welcome__button:not(:last-child) {
  margin: 0 0 24px;
}

.auth-welcome__footer {
  max-width: 256px;
  margin: auto auto 0;
}
</style>
