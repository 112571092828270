import { type Address, defineChain } from 'viem';

import { getPureBaseAssetAddress, sameAddress } from '@/helpers/addresses';
import type { AddressMapKey } from '@/references/addresses';
import { HHError } from '@/references/HHError';

import { getConfig } from './config';
import type { Token } from './tokens';

export enum Network {
  unknown = 'unknown',
  ethereum = 'ethereum',
  polygon = 'polygon',
  avalanche = 'avalanche',
  arbitrum = 'arbitrum',
  optimism = 'optimism',
  gnosis = 'gnosis',
  zkevm = 'zkevm',
  base = 'base',
  zksyncera = 'zksyncera',
  blast = 'blast',
  mode = 'mode',
  bsc = 'bsc',
  manta = 'manta',
  alephzero = 'alephzero'
}

export interface NetworkInfo {
  network: Network;
  name: string;
  chainId: number;
  explorerURL: string;
  explorerName: string;
  baseAsset: Token;
  rpcUrls: string[];
  privateRpcUrl: string;
  iconURL: string;
  displayedName: string;
  orderIdx: number;
  isPopular: boolean;
}

const DefaultAddress = '0x1' as const;
export const getNetworkAddress = <K extends AddressMapKey, N extends Network>(
  network: N,
  key: K
): Address => {
  const config = getConfig();

  if (
    config === undefined ||
    config[network] === undefined ||
    config[network].addresses === undefined
  ) {
    return DefaultAddress;
  }

  return config[network].addresses[key] ?? DefaultAddress;
};

export const isDefaultAddress = (address?: string | null): boolean => {
  return address === DefaultAddress;
};

export const getNetworkByChainId = (chainId: number): NetworkInfo | undefined => {
  const config = getConfig();

  if (config === undefined) {
    return undefined;
  }

  const configItems = Object.values(config);
  return configItems.find((item) => item.networkInfo.chainId === chainId)?.networkInfo;
};

export const getNetwork = (network: Network): NetworkInfo | undefined => {
  const config = getConfig();

  return config?.[network]?.networkInfo;
};

export const getChainId = (network: Network): number => {
  const ni = getNetwork(network);
  if (ni === undefined) {
    throw new HHError('Network is not defined in config', { payload: { network } });
  }

  return ni.chainId;
};

export const getBaseAssetData = (network: Network): Token => {
  const ni = getNetwork(network);
  if (ni === undefined) {
    throw new HHError('Network is not defined in config', { payload: { network } });
  }

  return ni.baseAsset;
};
export const isBaseAssetByNetwork = (addr: string, network: Network): boolean => {
  return sameAddress(getBaseAssetData(network).address, addr) || addr === getPureBaseAssetAddress();
};

export const getAvailableNetworks = (): Network[] => {
  const config = getConfig();

  if (config === undefined) {
    throw new HHError('No config present');
  }

  return Object.values(config)
    .sort((a, b) => a.networkInfo.orderIdx - b.networkInfo.orderIdx)
    .map((v) => v.networkInfo.network);
};

export const AlephZero = defineChain({
  id: 41455,
  name: 'Aleph Zero EVM',
  nativeCurrency: {
    decimals: 9,
    name: 'AZERO',
    symbol: 'AZERO'
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.alephzero.raas.gelato.cloud'],
      webSocket: ['wss://ws.alephzero.raas.gelato.cloud']
    }
  },
  blockExplorers: {
    default: { name: 'Aleph Zero EVM explorer', url: 'https://evm-explorer.alephzero.org' }
  }
});
