export type HHAPISuccessfulResponse<Payload> = {
  status: 'ok';
  payload: Payload;
};
export type HHAPIErrorResponse<ErrorPayload = void> = {
  status: 'error';
  errorCode: string;
  error: string;
  payload: ErrorPayload;
};
export type HHAPIResponse<Payload, ErrorPayload = void> =
  | HHAPISuccessfulResponse<Payload>
  | HHAPIErrorResponse<ErrorPayload>;

export function isErrorResponse<Payload, ErrorPayload = void>(
  response: HHAPIResponse<Payload, ErrorPayload>
): response is HHAPIErrorResponse<ErrorPayload> {
  return response.status === 'error';
}

export type RequestOptions = AbortOptions & PaginateOptions;

export type AbortOptions = {
  signal?: AbortSignal;
};

export type PaginateOptions = {
  paginate?:
    | {
        paginateBy: 'cursor';
        pageSize?: number;
        cursor?: string;
      }
    | {
        paginateBy: 'pages';
        pageSize?: number;
        page: number;
      };
};
