import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

import { t } from '@/references/i18n';

dayjs.extend(calendar);

/**
 * Formats unix timestamp to relative date string in format of `Today | Yesterday | DD MMMM`
 * @param v - unix timestamp
 * @param format - custom format for non-relative cases
 */
export function formatTimestampRelativeOrUTC(v: number, format = 'DD MMMM'): string {
  const now = dayjs();
  return dayjs.unix(v).calendar(now, {
    sameDay: `[${t('phrases.today')}]`,
    lastDay: `[${t('phrases.yesterday')}]`,
    nextDay: format,
    nextWeek: format,
    lastWeek: format,
    sameElse: format
  });
}
