import { APIError } from '@/references/axios/APIError';
import type { SelfData, SetAvatarResponse } from '@/references/axios/auth/types';
import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import type { RequestOptions } from '@/references/axios/types';
import type { ClientType } from '@/references/base';

export class HHAPIAuthService extends HHAPIService {
  constructor(
    private baseURL: string,
    private clientType: ClientType
  ) {
    super('auth.service');
  }

  async sendOTP(email: string, initial = true): Promise<void> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });
    await instance.request<never, { initial: boolean; email: string }>({
      url: '/v1/init/auth/send',
      method: 'POST',
      withCredentials: true,
      data: { initial, email }
    });
  }

  async login(email: string, otp: string, opts?: RequestOptions): Promise<SelfData> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });
    const resp = await instance.request<
      SelfData,
      {
        email: string;
        otp: string;
      }
    >({
      method: 'POST',
      data: {
        email,
        otp
      },
      url: `/v1/init/auth/check`,
      withCredentials: true,
      opts
    });

    return {
      ...resp.data.payload,
      accountData: {
        ...resp.data.payload.accountData,
        name: resp.data.payload.accountData.email,
        avatarUrl: ''
      }
    };
  }

  async logout(): Promise<void> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    try {
      await instance.request<never, undefined>({
        method: 'POST',
        url: '/v1/private/disconnect',
        data: undefined,
        withCredentials: true
      });
    } catch (error) {
      if (error instanceof APIError && (error.status === 403 || error.status === 401)) {
        // Ignore the error. Logout was performed by other means
        return;
      }

      throw error;
    }
  }

  async self(): Promise<SelfData | null> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    try {
      const resp = await instance.request<SelfData, undefined>({
        method: 'GET',
        url: '/v1/init/self',
        withCredentials: true,
        skipSessionErrorHandling: true
      });

      return {
        ...resp.data.payload,
        accountData: {
          ...resp.data.payload.accountData,
          name: resp.data.payload.accountData.email,
          avatarUrl: ''
        }
      };
    } catch (error) {
      if (error instanceof APIError && error.status === 403) {
        return null;
      }

      throw error;
    }
  }

  public async setAvatar(imageBlob: Blob, fileName: string | undefined): Promise<string> {
    const form = new FormData();
    form.append('image', imageBlob, fileName);

    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const resp = await instance.request<SetAvatarResponse, FormData>({
      method: 'POST',
      url: '/v1/private/avatar',
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true
    });

    return resp.data.payload.publicLink;
  }
}
