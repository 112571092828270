<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';

import type {
  UiButtonLinkTarget,
  UiButtonMod,
  UiButtonSize,
  UiButtonTextSize,
  UiButtonType
} from '@/components/ui/UiButton.types';
import UiSpinner from '@/components/ui/UiSpinner.vue';
import UiText from '@/components/ui/UiText.vue';

const props = defineProps({
  href: {
    type: String as PropType<string>,
    default: undefined
  },
  target: {
    type: String as PropType<UiButtonLinkTarget>,
    default: '_self'
  },
  type: {
    type: String as PropType<UiButtonType>,
    default: 'button'
  },
  mod: {
    type: String as PropType<UiButtonMod>,
    default: 'primary'
  },
  size: {
    type: String as PropType<UiButtonSize>,
    default: 'md'
  },
  textSize: {
    type: String as PropType<UiButtonTextSize>,
    default: 'md'
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-button_mod_${props.mod}`]: true,
    [`ui-button_size_${props.size}`]: true,
    [`ui-button_disabled`]: props.disabled,
    [`ui-button_loading`]: props.loading
  })
);

const tag = computed((): 'a' | 'button' => (props.href ? 'a' : 'button'));
</script>

<template>
  <component
    :is="tag"
    class="ui-button"
    :class="classList"
    :href="props.disabled ? undefined : props.href"
    :target="tag === 'a' ? props.target : undefined"
    :type="tag === 'button' ? props.type : undefined"
    :disabled="props.disabled || props.loading"
  >
    <UiText
      class="ui-button__content"
      :size="props.size === 'sm' ? 'sm' : props.textSize"
      weight="semibold"
    >
      <slot />
    </UiText>
    <UiSpinner v-if="props.loading" class="ui-button__spinner" />
  </component>
</template>

<style scoped>
.ui-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  margin: 0;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition:
    opacity var(--animation-micro) var(--animation-effect),
    background var(--animation-micro) var(--animation-effect),
    color var(--animation-micro) var(--animation-effect),
    transform var(--animation-micro) var(--animation-effect);
  appearance: none;
  user-select: none;
}

.ui-button_size_md {
  height: 40px;
  padding: 0 24px;
}

.ui-button_size_sm {
  height: 28px;
  padding: 0 8px;
}

.ui-button_mod_primary {
  color: var(--color-white);
  background: var(--color-purple);
}

.ui-button_mod_secondary {
  color: var(--color-main-text);
  background: var(--color-secondary-contrast);
}

.ui-button_mod_muted {
  color: var(--color-secondary-text);
  background: var(--color-secondary-contrast);
}

.ui-button_mod_contrast {
  color: var(--color-background);
  background: var(--color-main-text);
}

.ui-button_mod_positive {
  color: var(--color-white);
  background: var(--color-green);
}

.ui-button_mod_positive-gradient {
  color: var(--color-white);
  background: linear-gradient(90deg, #4ac94c, #65b352, #4ac94c) 0 0 / 400% 100%;
  animation: ui-button-positive 8s linear infinite;
}

@keyframes ui-button-positive {
  to {
    background-position: 400% 0;
  }
}

.ui-button_mod_negative {
  color: var(--color-white);
  background: var(--color-red);
}

.ui-button_mod_transparent {
  color: var(--color-white);
  background: var(--color-white-transparent);
}

.ui-button:not(:disabled):active {
  transform: scale(0.97);
}

.ui-button_loading {
  cursor: wait;
}

.ui-button:disabled:not(.ui-button_loading),
.ui-button_disabled:not(.ui-button_loading) {
  cursor: not-allowed;
  opacity: 0.25;
}

.ui-button_mod_muted:disabled:not(.ui-button_loading),
.ui-button_mod_transparent:disabled:not(.ui-button_loading) {
  opacity: 1;
}

.ui-button_mod_transparent:disabled:not(.ui-button_loading) .ui-button__content {
  opacity: 0.5;
}

.ui-button__content {
  max-width: 100%;
  overflow: hidden;
  font-family: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: opacity var(--animation-micro) var(--animation-effect);
}

.ui-button_loading .ui-button__content {
  opacity: 0;
}

.ui-button__spinner {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  color: currentcolor;
}
</style>
