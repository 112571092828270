type PromiseParts<ReturnType, RejectType = string> = {
  resolve?: (result: ReturnType) => void;
  reject?: (cause: RejectType) => void;
  wait?: () => Promise<ReturnType>;
};

type PromisePartsWithAbortSignal<ReturnType, RejectType = string> = {
  resolve?: (result: ReturnType) => void;
  reject?: (cause: RejectType) => void;
  onSignal?: (fn: () => void) => void;
  wait?: () => Promise<ReturnType>;
};

export function createPromise<ReturnType, RejectType = string>(): Required<
  PromiseParts<ReturnType, RejectType>
> {
  const parts: PromiseParts<ReturnType, RejectType> = {};
  const promise = new Promise<ReturnType>((resolve, reject) => {
    parts.resolve = (result: ReturnType) => {
      resolve(result);
    };
    parts.reject = (cause: RejectType) => {
      reject(cause);
    };
  });

  parts.wait = async (): Promise<ReturnType> => {
    return await promise;
  };

  return parts as Required<PromiseParts<ReturnType, RejectType>>;
}

export function createPromiseWithAbortSignal<ReturnType, RejectType = string>(
  signal: AbortSignal
): Required<PromisePartsWithAbortSignal<ReturnType, RejectType>> {
  let externalSignalListener: () => void = () => undefined;
  const parts: PromisePartsWithAbortSignal<ReturnType, RejectType> = {
    onSignal: (fn) => {
      externalSignalListener = fn;
    }
  };

  const promise = new Promise<ReturnType>((resolve, reject) => {
    let settled: boolean = false;

    const signalListener = () => {
      if (settled) {
        return;
      }
      externalSignalListener();
      reject('aborted');
    };

    signal.addEventListener('abort', signalListener, { once: true });

    parts.resolve = (result: ReturnType) => {
      signal.removeEventListener('abort', signalListener);
      if (signal.aborted) {
        return;
      }
      settled = true;
      resolve(result);
    };
    parts.reject = (cause: RejectType) => {
      signal.removeEventListener('abort', signalListener);
      if (signal.aborted) {
        return;
      }
      settled = true;
      reject(cause);
    };
  });

  parts.wait = async (): Promise<ReturnType> => {
    return await promise;
  };

  return parts as Required<PromisePartsWithAbortSignal<ReturnType, RejectType>>;
}

export type MaybePromise<T> = T | Promise<T>;

export function asyncSleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export type AwaitedNestedPromise<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;
