import '@/composables/useTheme';

import { createApp } from 'vue';

import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/vue';
import { MotionPlugin } from '@vueuse/motion';
import { v4 } from 'uuid';

import { caesarCipher } from '@/helpers/utils';
import { i18n } from '@/references/i18n';

import '@/styles/index.css';

import App from './App.vue';
import router from './router';

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [],
  enabled: import.meta.env.VITE_SENTRY_ENABLE ?? false,
  environment: import.meta.env.VITE_SENTRY_ENV ?? 'localhost'
});

if (import.meta.env.VITE_DATADOG_ENABLE ?? false) {
  const browserPageUid = v4();
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: import.meta.env.VITE_DATADOG_SITE,
    service: import.meta.env.VITE_DATADOG_SERVICE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: import.meta.env.VITE_DATADOG_ENV,
    version: import.meta.env.VITE_APP_VERSION,
    proxy: (options) => {
      const encodedPath = encodeURIComponent(caesarCipher(options.path, 1));
      const encodedParameters = encodeURIComponent(caesarCipher(options.parameters, 1));
      return `${import.meta.env.VITE_LOG_ENDPOINT}/?path=${encodedPath}&parameters=${encodedParameters}`;
    }
  });

  datadogLogs.setGlobalContextProperty('app_session_id', browserPageUid);

  Sentry.setContext('datadog_session_id', {
    app_session_id: browserPageUid,
    sessionId: datadogLogs.getInternalContext()?.session_id
  });
}

app.use(i18n).use(MotionPlugin).use(router);
app.mount('#app');
