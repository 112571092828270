<script setup lang="ts">
import { computed } from 'vue';

import UiIcon from '@/components/ui/UiIcon.vue';
import type { UiTextSize } from '@/components/ui/UiText.types';
import UiText from '@/components/ui/UiText.vue';

interface Props {
  text?: string;
  icon?: string;
  iconSize?: string;
  color?: string;
  size?: 'md' | 'sm';
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  icon: undefined,
  iconSize: undefined,
  color: 'var(--color-grey)',
  size: 'md'
});

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-colored-circle_size_${props.size}`]: true
  })
);

const textSize = computed((): UiTextSize => {
  switch (props.size) {
    case 'sm':
      return 'xxs';
    default:
      return 'sm';
  }
});

const iconSize = computed((): number => {
  switch (props.size) {
    case 'sm':
      return 10;
    default:
      return 24;
  }
});
</script>

<template>
  <span class="ui-colored-circle" :class="classList">
    <UiIcon v-if="props.icon" :name="props.icon" :size="iconSize" />
    <UiText v-else class="ui-colored-circle__text" :size="textSize" weight="medium">
      {{ props.text }}
    </UiText>
  </span>
</template>

<style scoped>
.ui-colored-circle {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  background-color: v-bind(color);
  border-radius: 50%;
}

.ui-colored-circle_size_md {
  width: 44px;
  height: 44px;
  padding: 4px;
}

.ui-colored-circle_size_sm {
  width: 16px;
  height: 16px;
  padding: 2px;
}

.ui-colored-circle__text {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}
</style>
