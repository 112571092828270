<script setup lang="ts">
import type { PropType } from 'vue';

import { type InfoLayoutButton } from '@/components/layouts/InfoLayout.types';
import UiButton from '@/components/ui/UiButton.vue';
import UiContainer from '@/components/ui/UiContainer.vue';
import UiText from '@/components/ui/UiText.vue';

const props = defineProps({
  buttons: {
    type: Array as PropType<InfoLayoutButton[]>,
    default: () => []
  }
});
</script>

<template>
  <main class="info-layout">
    <UiContainer class="info-layout__container" size="sm">
      <div class="info-layout__image">
        <slot name="image" />
      </div>
      <UiText class="info-layout__text" tag="p" weight="medium" size="md" muted align="center">
        <slot />
      </UiText>
      <div v-if="props.buttons.length" class="info-layout__footer">
        <ul class="info-layout__buttons-list">
          <li v-for="button in props.buttons" :key="button.text" class="info-layout__buttons-item">
            <UiButton
              class="info-layout__button"
              :mod="button.mod"
              :disabled="button.disabled"
              :loading="button.loading"
              @click="button.handler()"
            >
              {{ button.text }}
            </UiButton>
          </li>
        </ul>
        <slot name="footer" />
      </div>
    </UiContainer>
  </main>
</template>

<style scoped>
.info-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.info-layout__image {
  display: flex;
  justify-content: center;
  margin: 0 0 24px;
}

.info-layout__text {
  white-space: pre-wrap;
}

@media (max-width: 919px) {
  .info-layout__text {
    max-width: 416px;
    margin: 0 auto;
  }
}

.info-layout__footer {
  width: 100%;
  margin: 40px 0 0;
}

.info-layout__buttons-list {
  display: flex;
  margin: 0 -12px;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 919px) {
  .info-layout__buttons-list {
    display: block;
    margin: 0;
  }
}

.info-layout__buttons-item {
  width: 50%;
  padding: 0 12px;
}

@media (max-width: 919px) {
  .info-layout__buttons-item {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 919px) {
  .info-layout__buttons-item:not(:last-child) {
    margin: 0 0 16px;
  }
}

.info-layout__buttons-item:first-child:last-child {
  width: 100%;
}

.info-layout__buttons-item:first-child:last-child .info-layout__button {
  max-width: 334px;
  margin: 0 auto;
}
</style>
