import { currentClientType } from '@/helpers/utils';
import { HHAPIKYCService } from '@/references/axios/kyc/HHAPIKYCService';
import { getEndpoint } from '@/references/endpoints';

let service: HHAPIKYCService | undefined;
export const useKYCApiService = (): HHAPIKYCService => {
  if (service === undefined) {
    service = new HHAPIKYCService(getEndpoint('apiBrrr'), currentClientType());
  }

  return service;
};
