import { createHHAxios } from '@/references/axios/axios';
import { HHAPIService } from '@/references/axios/base';
import {
  type GetKYCSessionIdResponse,
  type GetKYCStatusResponse,
  KYCStatus
} from '@/references/axios/kyc/types';
import type { RequestOptions } from '@/references/axios/types';
import type { ClientType } from '@/references/base';

export class HHAPIKYCService extends HHAPIService {
  constructor(
    private baseURL: string,
    private clientType: ClientType
  ) {
    super('kyc.service');
  }

  public async getKYCStatus(opts?: RequestOptions): Promise<KYCStatus> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const res = await instance.request<GetKYCStatusResponse>({
      method: 'GET',
      url: '/v1/private/kyc/state',
      withCredentials: true,
      opts
    });
    return res.data.payload.kycStatus;
  }

  public async getKYCSessionId(opts?: RequestOptions): Promise<string> {
    const instance = createHHAxios({
      baseURL: this.baseURL,
      headers: this.getClientTypeHeaders(this.clientType)
    });

    const res = await instance.request<GetKYCSessionIdResponse, undefined>({
      method: 'POST',
      url: '/v1/private/kyc/start',
      data: undefined,
      withCredentials: true,
      opts
    });
    return res.data.payload.sessionId;
  }
}
