<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { useCounter, useIntervalFn, useThrottleFn } from '@vueuse/core';

import { SECOND } from '@/references/constants';

import type { InfoLayoutButton } from '@/components/layouts/InfoLayout.types';
import InfoLayout from '@/components/layouts/InfoLayout.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

interface Props {
  throttle?: number;
  closable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  throttle: 30 * SECOND,
  closable: true
});

const emit = defineEmits<{
  (e: 'update'): void;
}>();

const { t } = useI18n();
const clicks = useCounter();

const throttledEmit = useThrottleFn(() => emit('update'), props.throttle);
useIntervalFn(throttledEmit, SECOND, { immediate: true });

const title = computed((): string =>
  clicks.count.value > 0 ? `${clicks.count.value}` : t('error500.title')
);

const buttons = computed((): InfoLayoutButton[] => [
  {
    text: t('error500.cta'),
    mod: 'secondary',
    handler: () => {
      clicks.inc(1);
      throttledEmit();
    }
  }
]);
</script>

<template>
  <InfoLayout :buttons="buttons">
    <template #image>
      <UiTitle :level="1">{{ title }}</UiTitle>
    </template>
    <template #default>
      {{ t('error500.description') }}
    </template>
  </InfoLayout>
</template>
