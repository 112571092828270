<script setup lang="ts">
import UiText from '@/components/ui/UiText.vue';

interface Props {
  muted?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  muted: false
});
</script>

<template>
  <UiText class="ui-badge" size="xs" :muted="props.muted" weight="semibold">
    <slot />
  </UiText>
</template>

<style scoped>
.ui-badge {
  padding: 0 4px;
  white-space: nowrap;
  border: 1px solid var(--color-secondary-stroke);
  border-radius: 6px;
}
</style>
