import { HHError } from './HHError';

export class ExpectedError<T extends string = string> extends HHError {
  override name = 'ExpectedError';
  protected sentryHandle = false;

  constructor(
    protected readonly code: T,
    {
      cause,
      payload,
      sentryHandle
    }: { cause?: unknown; payload?: Record<string, unknown>; sentryHandle?: boolean } = {}
  ) {
    super(`Expected error with code: ${code}`, { cause, payload });
    this.sentryHandle = !!sentryHandle;
  }

  public getCode(): T {
    return this.code;
  }

  public isHandledBySentry(): boolean {
    return this.sentryHandle;
  }
}
