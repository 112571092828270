<script setup lang="ts">
import { computed, ref } from 'vue';

import personIcon from '@/assets/icons/person.svg';

import UiIcon from '@/components/ui/UiIcon.vue';

interface Props {
  size?: 'md' | 'lg' | 'xl';
  src?: string;
  icon?: string;
  contrast?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: 'md',
  src: undefined,
  icon: undefined,
  contrast: false
});

const isFailed = ref(false);

const classList = computed(
  (): Record<string, boolean> => ({
    [`avatar-image_size_${props.size}`]: true,
    'avatar-image_contrast': props.contrast
  })
);

function handleLoadError(): void {
  isFailed.value = true;
}
</script>

<template>
  <span class="avatar-image" :class="classList">
    <span class="avatar-image__body">
      <img
        v-if="props.src && !isFailed"
        class="avatar-image__img"
        :src="props.src"
        alt=""
        @error="handleLoadError"
      />
      <UiIcon v-else class="avatar-image__icon" :name="personIcon" />
    </span>
    <span v-if="props.icon" class="avatar-image__sup">
      <UiIcon class="avatar-image__sup-icon" :name="props.icon" />
    </span>
  </span>
</template>

<style scoped>
.avatar-image {
  position: relative;
  display: block;
  flex-shrink: 0;
}

.avatar-image_size_xl {
  width: 80px;
  height: 80px;
}

.avatar-image_size_lg {
  width: 44px;
  height: 44px;
}

.avatar-image_size_md {
  width: 40px;
  height: 40px;
}

.avatar-image__body {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-main-underlay);
  border-radius: 50%;
}

.avatar-image_contrast .avatar-image__body {
  background-color: var(--color-secondary-contrast);
}

.avatar-image__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.avatar-image__icon {
  width: 24px;
  height: 24px;
  fill: var(--color-secondary-text);
}

.avatar-image__sup {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-secondary-contrast);
  border-radius: 50%;
}

.avatar-image_size_md .avatar-image__sup {
  width: 16px;
  height: 16px;
}

.avatar-image_size_lg .avatar-image__sup,
.avatar-image_size_xl .avatar-image__sup {
  width: 24px;
  height: 24px;
}

.avatar-image__sup-icon {
  fill: var(--color-secondary-text);
}

.avatar-image_size_md .avatar-image__sup-icon {
  width: 8px;
  height: 8px;
}

.avatar-image_size_lg .avatar-image__sup-icon,
.avatar-image_size_xl .avatar-image__sup-icon {
  width: 16px;
  height: 16px;
}
</style>
