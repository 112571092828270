<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  name: string;
  size?: number | [number, number] /* Width&Height | [Width, Height]. */;
  muted?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  size: 24,
  muted: false
});

// const xlinkHref = computed((): string => `#icon_${props.name}`);
const xlinkHref = computed((): string => `#${props.name}`);

const width = computed((): string => `${Array.isArray(props.size) ? props.size[0] : props.size}px`);

const height = computed(
  (): string => `${Array.isArray(props.size) ? props.size[1] : props.size}px`
);

const classList = computed(
  (): Record<string, boolean> => ({
    'ui-icon_muted': props.muted
  })
);
</script>

<template>
  <svg class="ui-icon" :class="classList" aria-hidden="true" role="img">
    <use :xlink:href="xlinkHref" />
  </svg>
</template>

<style scoped>
.ui-icon {
  display: block;
  width: v-bind(width);
  height: v-bind(height);
  fill: currentcolor;
}

.ui-icon_muted {
  fill: var(--color-secondary-text);
}
</style>
