import BigNumber from 'bignumber.js';

import { addSentryBreadcrumb } from '@/logs/sentry';
import type { Address, ClientType, Hex } from '@/references/base';

import { isNaN } from './bigmath';

export function isString(data: unknown): data is string {
  return typeof data === 'string';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isRecord(data: unknown): data is Record<string, any> {
  return data instanceof Object;
}

export const isSomeEnum =
  <T extends ArrayLike<unknown> | Record<string, unknown>>(e: T) =>
  (token: unknown): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);

const addressRegex = /^0x[a-fA-F0-9]{40}$/;

export function isAddress(address: string): address is Address {
  if (!addressRegex.test(address)) {
    return false;
  }
  if (address.toLowerCase() === address) {
    return true;
  }
  return true;
}

export function isHash(hash: string): hash is Hex {
  return isHex(hash) && size(hash) === 32;
}

export function isHex(
  value: unknown,
  { strict = true }: { strict?: boolean | undefined } = {}
): value is Hex {
  if (!value) {
    return false;
  }
  if (typeof value !== 'string') {
    return false;
  }
  return strict ? /^0x[0-9a-fA-F]*$/.test(value) : value.startsWith('0x');
}

/**
 * @description Retrieves the size of the value (in bytes).
 *
 * @param value The value (hex or byte array) to retrieve the size of.
 * @returns The size of the value (in bytes).
 */
export function size(value: Hex | string) {
  if (isHex(value, { strict: false })) {
    return Math.ceil((value.length - 2) / 2);
  }
  return value.length;
}

/**
 * Custom JSON replacer to allow Error objects to be serialized properly
 *
 * As Error object has `.enumerable` as `false` for all the fields,
 *  standard serializers can not process the object,
 *  and `JSON.stringify(new Error('my error', { cause: 'some cause' }))`
 *  yields `"{}"`, we need to patch this behavior
 * @param key - an object key
 * @param value - an object value
 * @returns replaced value
 */
function jsonReplacer(key: string, value: unknown): unknown {
  if (!(value instanceof Error)) {
    return value;
  }

  return {
    ...value,
    name: value.name,
    message: value.message,
    stack: value.stack,
    cause: value.cause
  };
}

export function cloneObject<T>(v: T): T {
  try {
    return JSON.parse(JSON.stringify(v, jsonReplacer));
  } catch (e) {
    return { copyError: e } as T;
  }
}

export function caesarCipher(str: string, shift: number): string {
  let res = '';
  for (let i = 0; i < str.length; i++) {
    res += String.fromCharCode(str[i].charCodeAt(0) + shift);
  }
  return res;
}

export const roundToPrecision = (value: BigNumber.Value, decimals = 18): string => {
  return new BigNumber(value).toFixed(decimals);
};

export const isNullish = (value: string | undefined | null): value is undefined | null => {
  return value === undefined || value === null || value == '' || value === '';
};

export const max = (numberOne: BigNumber.Value, numberTwo: BigNumber.Value): string => {
  const num = new BigNumber(numberOne);
  const num2 = new BigNumber(numberTwo);
  return (num.isLessThan(num2) ? num2 : num).toString(10);
};

export const min = (numberOne: BigNumber.Value, numberTwo: BigNumber.Value): string => {
  const num = new BigNumber(numberOne);
  const num2 = new BigNumber(numberTwo);
  return (num.isLessThan(num2) ? num : num2).toString(10);
};

/**
 * gets the value according to the proportion of `source` and `base` values.
 * Applies a proportion to `target` value
 *
 * @param sourceValue great than 0
 * @param baseValue great than 0
 * @param targetValue great than 0
 */
export const getValueBySourceAndBase = (
  sourceValue: BigNumber.Value,
  baseValue: BigNumber.Value,
  targetValue: BigNumber.Value
): string => {
  const source = new BigNumber(sourceValue);
  const base = new BigNumber(baseValue);
  const target = new BigNumber(targetValue);

  if (base.isLessThanOrEqualTo(0)) {
    return '0';
  }

  if (source.isLessThanOrEqualTo(0)) {
    return '0';
  }

  const kf = base.div(source);
  const result = target.multipliedBy(kf).toString(10);
  if (isNaN(result)) {
    addSentryBreadcrumb({
      level: 'warning',
      message: 'getValueBySourceAndBase got NaN',
      data: {
        sourceValue,
        baseValue,
        targetValue
      }
    });
    return '0';
  }
  return result;
};

export const isMobile = (): boolean => {
  if (typeof window !== 'undefined') {
    return Boolean(
      window.matchMedia('(pointer:coarse)').matches ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|Opera Mini/u.test(navigator.userAgent)
    );
  }
  return false;
};

export const isAndroid = (): boolean => {
  return isMobile() && navigator.userAgent.toLowerCase().includes('android');
};

export const isIos = (): boolean => {
  const ua = navigator.userAgent.toLowerCase();
  return isMobile() && (ua.includes('iphone') || ua.includes('ipad'));
};

export const currentClientType = (): ClientType => {
  if (!isMobile()) {
    return 'web';
  }

  if (isAndroid()) {
    return 'web-android';
  }

  if (isIos()) {
    return 'web-ios';
  }

  return 'web';
};
