import dayjs, { Dayjs } from 'dayjs';

import { i18n } from '@/references/i18n';

export const toHHMMSS = (sec: number): string => {
  if (sec < 0) {
    return '00:00';
  }
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.round(sec - hours * 3600 - minutes * 60);

  const h = hours < 10 ? `0${hours}` : `${hours}`;
  const m = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const s = seconds < 10 ? `0${seconds}` : `${seconds}`;

  if (hours) {
    return `${h}:${m}:${s}`;
  }

  return `${m}:${s}`;
};

export function toDaysHoursMinutesSeconds(seconds: number) {
  let value = seconds;

  const days = Math.floor(value / (24 * 60 * 60));
  value %= 24 * 60 * 60;
  const hours = Math.floor(value / (60 * 60));
  value %= 60 * 60;
  const minutes = Math.floor(value / 60);
  value %= 60;

  const parts = [];

  if (days > 0) {
    parts.push(`${days}${i18n.global.t('timeLabels.d')}`);
  }

  if (hours > 0) {
    parts.push(`${hours}${i18n.global.t('timeLabels.h')}`);
  }

  if (minutes > 0) {
    parts.push(`${minutes}${i18n.global.t('timeLabels.m')}`);
  }

  if (value > 0) {
    parts.push(`${value}${i18n.global.t('timeLabels.s')}`);
  }

  return parts.join(' ');
}

export const isWeekday = (date: Dayjs): boolean => {
  const dayOnWeek = date.day();
  switch (dayOnWeek) {
    case 0:
    case 6:
      return false;
    default:
      return true;
  }
};

const holidays = [
  '2024-03-25T12:00:00.000+01:00',
  '2024-04-01T12:00:00.000+01:00',
  '2024-05-01T12:00:00.000+01:00',
  '2024-05-03T12:00:00.000+01:00',
  '2024-05-06T12:00:00.000+01:00',
  '2024-05-07T12:00:00.000+01:00',
  '2024-06-24T12:00:00.000+01:00',
  '2024-08-15T12:00:00.000+01:00',
  '2024-10-01T12:00:00.000+01:00',
  '2024-10-28T12:00:00.000+01:00',
  '2024-12-25T12:00:00.000+01:00',
  '2024-12-26T12:00:00.000+01:00'
];

export const isHoliday = (date: Dayjs): boolean => {
  const holiday = holidays.find((h) => date.isSame(dayjs(h), 'day'));
  if (holiday !== undefined) {
    return true;
  }
  return false;
};

export const isWorkday = (date: Dayjs): boolean => {
  const res = isWeekday(date) && !isHoliday(date);
  return res;
};

export const getNextWorkday = (date: Dayjs): Dayjs => {
  const tomorrow = date.add(1, 'day');
  if (isWorkday(tomorrow)) {
    return tomorrow;
  }
  return getNextWorkday(tomorrow);
};
