<script setup lang="ts">
import importantIcon from '@/assets/icons/important.svg';
import questionIcon from '@/assets/icons/question.svg';

import UiIcon from '@/components/ui/UiIcon.vue';
import type { UiImportantMod } from '@/components/ui/UiImportant.types';

interface Props {
  mod?: UiImportantMod;
}

const props = withDefaults(defineProps<Props>(), {
  mod: 'default'
});
</script>

<template>
  <div class="ui-important" :class="`ui-important_mod_${props.mod}`">
    <UiIcon
      class="ui-important__icon"
      :name="mod === 'question' ? questionIcon : importantIcon"
      :size="40"
    />
  </div>
</template>

<style scoped>
.ui-important {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.ui-important_mod_default,
.ui-important_mod_question {
  background-color: var(--color-secondary-contrast);
}

.ui-important_mod_alert {
  background-color: var(--color-red);
}

.ui-important__icon {
  color: var(--color-secondary-text);
}

.ui-important_mod_default .ui-important__icon {
  color: var(--color-secondary-text);
}

.ui-important_mod_alert .ui-important__icon {
  color: var(--color-white);
}
</style>
