<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useViewBreakpoints } from '@/composables/useViewBreakpoints';
import { RouteNames } from '@/router';

import MainNavigation from '@/components/MainNavigation.vue';

const { isMobile } = useViewBreakpoints();
const route = useRoute();

const isNavigationVisible = computed((): boolean => {
  if (!isMobile.value) {
    return true;
  }

  return route.name !== RouteNames.Settings;
});
</script>

<template>
  <div
    class="default-layout"
    :class="{ 'default-layout_without-navigation': !isNavigationVisible }"
  >
    <Transition>
      <MainNavigation v-if="isNavigationVisible" class="default-layout__navigation" />
    </Transition>
    <main class="default-layout__main">
      <slot />
    </main>
  </div>
</template>

<style scoped>
.default-layout {
  display: flex;
  flex-grow: 1;
}

.default-layout__navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
}

@media (max-width: 919px) {
  .default-layout__navigation {
    top: auto;
    bottom: 0;
    width: 100%;
  }
}

.default-layout__main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 40px 280px;
}

@media (max-width: 919px) {
  .default-layout__main {
    padding: 24px 0 120px;
  }
}

@media (max-width: 919px) {
  .default-layout_without-navigation .default-layout__main {
    padding: 24px 0 40px;
  }
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active {
  transition: opacity var(--animation-medium) var(--animation-effect);
}
</style>
