import { computed, reactive } from 'vue';

import { useKYCApiService } from '@/composables/services/useKYCApiService';
import { useAccount } from '@/composables/useAccount';
import { createPromise } from '@/helpers/promises';
import { addSentryBreadcrumb } from '@/logs/sentry';
import { KYCStatus } from '@/references/axios/kyc/types';
import { HHError } from '@/references/HHError';

const state = reactive<{
  status: KYCStatus;
  sessionId: string;
}>({
  status: KYCStatus.UNKNOWN,
  sessionId: ''
});

const KYCstatus = computed((): KYCStatus => {
  const { isAuthed } = useAccount();
  if (!isAuthed) {
    return KYCStatus.UNKNOWN;
  }
  return state.status;
});

const synapsSessionId = computed((): string => {
  const { isAuthed } = useAccount();
  if (!isAuthed) {
    return '';
  }
  return state.sessionId;
});

let updateKYCStatusPromise: Promise<void> | undefined = undefined;

const loadKYCStatus = async (): Promise<void> => {
  try {
    const service = useKYCApiService();

    state.status = await service.getKYCStatus();
  } catch (e) {
    addSentryBreadcrumb({
      level: 'error',
      message: 'fail to load KYC status',
      data: {
        error: e
      }
    });
    throw new HHError('fail to load kyc status', { cause: e });
  }
};

const updateKYCStatus = async (): Promise<void> => {
  if (updateKYCStatusPromise !== undefined) {
    return updateKYCStatusPromise;
  }

  const { wait, resolve, reject } = createPromise<void, void>();
  updateKYCStatusPromise = wait();

  loadKYCStatus()
    .then(() => {
      resolve();
      updateKYCStatusPromise = undefined;
    })
    .catch(() => {
      reject();
      updateKYCStatusPromise = undefined;
    });

  return updateKYCStatusPromise;
};

let updateSynapsSessionIdPromise: Promise<void> | undefined = undefined;

const loadSynapsSessionId = async (): Promise<void> => {
  try {
    const service = useKYCApiService();

    state.sessionId = await service.getKYCSessionId();
  } catch (e) {
    addSentryBreadcrumb({
      level: 'error',
      message: 'fail to load KYC status',
      data: {
        error: e
      }
    });
    throw new HHError('fail to load kyc status', { cause: e });
  }
};

const updateSynapsSessionId = async (): Promise<void> => {
  if (updateSynapsSessionIdPromise !== undefined) {
    return updateSynapsSessionIdPromise;
  }

  const { wait, resolve, reject } = createPromise<void, void>();
  updateSynapsSessionIdPromise = wait();

  loadSynapsSessionId()
    .then(() => {
      resolve();
      updateSynapsSessionIdPromise = undefined;
    })
    .catch(() => {
      reject();
      updateKYCStatusPromise = undefined;
    });

  return updateSynapsSessionIdPromise;
};

export const useKYC = () => {
  return {
    updateKYCStatus,
    updateSynapsSessionId,
    KYCstatus,
    synapsSessionId
  };
};
