<script setup lang="ts">
import spinnerLottieData from '@/assets/lottie/spinner.json';

import UiLottie from '@/components/ui/UiLottie.vue';

interface Props {
  size?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: 24
});
</script>

<template>
  <UiLottie class="ui-spinner" :data="spinnerLottieData" :size="props.size" autoplay loop />
</template>

<style scoped>
.ui-spinner {
  display: block;
}

.ui-spinner:deep(path) {
  stroke: currentcolor;
}

.ui-spinner:deep(path[stroke-linecap='butt']) {
  opacity: 0.25;
}
</style>
