<script setup lang="ts">
import { computed } from 'vue';

import { ClientEECodeMap, type ErrorModal } from '@/composables/useErrorModal';
import { useI18n } from '@/references/i18n';

import UiText from '@/components/ui/UiText.vue';

interface Props {
  error: ErrorModal;
}

const props = defineProps<Props>();

const { t } = useI18n();

const message = computed(() => {
  switch (props.error.type) {
    case 'Expected':
      return t(ClientEECodeMap[props.error.code]);
    case 'Notify':
      return props.error.message;
    case 'Manual':
      return props.error.message;
    default:
      return '';
  }
});
</script>

<template>
  <UiText class="toast-list-toast" size="sm" weight="medium" tag="p" role="alert">
    <span v-if="'emoji' in props.error && props.error.emoji" class="toast-list-toast__emoji">
      {{ props.error.emoji }}
    </span>
    <span>{{ message }}</span>
  </UiText>
</template>

<style scoped>
.toast-list-toast {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 12px 16px;
  overflow: hidden;
  color: var(--color-main-text);
  word-break: break-word;
  background: var(--color-toast-background);
  border-radius: 23px;
  box-shadow: var(--shadow-base);
  cursor: default;
  transition:
    padding var(--animation-medium) var(--animation-effect),
    opacity var(--animation-medium) var(--animation-effect),
    transform var(--animation-medium) var(--animation-effect);
}

@media (max-width: 919px) {
  .toast-list-toast {
    padding: 8px 16px;
    border-radius: 19px;
  }
}

.toast-list-toast::before,
.toast-list-toast::after {
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  transition: width var(--animation-medium) var(--animation-effect);
  content: '';
}

.toast-list-toast::before {
  left: 0;
  background: linear-gradient(90deg, var(--color-toast-background) 0%, rgb(255 255 255 / 0%) 100%);
}

.toast-list-toast::after {
  right: 0;
  background: linear-gradient(90deg, rgb(255 255 255 / 0%) 0%, var(--color-toast-background) 100%);
}

.toast-list-toast__emoji {
  flex-shrink: 0;
  margin: 0 8px 0 0;
}
</style>

<style>
.toast-enter-from .toast-list-toast,
.toast-leave-to .toast-list-toast {
  padding-right: 0;
  padding-left: 0;
  transform: translate3d(0, -12px, 0);
  opacity: 0;
}

.toast-enter-from .toast-list-toast::before,
.toast-leave-to .toast-list-toast::before,
.toast-enter-from .toast-list-toast::after,
.toast-leave-to .toast-list-toast::after {
  width: 50%;
}
</style>
