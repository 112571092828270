import {
  addBreadcrumb as originalAddSentryBreadcrumb,
  captureException as originalCaptureException
} from '@sentry/vue';

import { cloneObject } from '@/helpers/utils';
import { createLogger, type Logger } from '@/logs/datadog';
import { APIError } from '@/references/axios/APIError';

const logger = createLogger('sentryRelayer');

const addSentryBreadcrumbFunc: typeof originalAddSentryBreadcrumb = (breadcrumb) => {
  const relayerArgs: Parameters<Logger['debug']> = [breadcrumb.message ?? ''];
  if (breadcrumb.data !== undefined && breadcrumb.data !== null) {
    relayerArgs.push(cloneObject(breadcrumb.data));
  }

  switch (breadcrumb.level) {
    case 'error':
      logger.error(...relayerArgs);
      break;
    case 'info':
      logger.info(...relayerArgs);
      break;
    case 'warning':
      logger.warn(...relayerArgs);
      break;
    case 'debug':
      logger.debug(...relayerArgs);
      break;
    default:
      logger.info(...relayerArgs);
  }

  originalAddSentryBreadcrumb(breadcrumb);
};

const captureSentryExceptionFunc: typeof originalCaptureException = (exception, ...rest) => {
  if (exception instanceof APIError && exception.code === 'INVALID_SESSION') {
    logger.warn(
      'capture exception, do not send to bugtracking',
      {
        errorRaw: cloneObject(exception)
      },
      exception
    );
    return '';
  }

  const sentryId = originalCaptureException(exception, ...rest);
  logger.error(
    'capture exception',
    {
      errorRaw: cloneObject(exception),
      sentryId: sentryId
    },
    exception
  );

  return sentryId;
};

export const addSentryBreadcrumb = addSentryBreadcrumbFunc;
export const captureSentryException = captureSentryExceptionFunc;
