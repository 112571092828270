<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';

import useVuelidate from '@vuelidate/core';
import { email as emailValidator, required as requiredValidator } from '@vuelidate/validators';

import securityIcon from '@/assets/icons/security.svg';
import { useAccount } from '@/composables/useAccount';
import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { ExpectedError } from '@/references/ExpectedError';
import { useI18n } from '@/references/i18n';

import UiButton from '@/components/ui/UiButton.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

const { t } = useI18n();
const emit = defineEmits<(e: 'next', v: string) => void>();

const state = reactive<{
  email: string;
  isSubmitting: boolean;
}>({
  email: '',
  isSubmitting: false
});

const inputComponent = ref<typeof UiInput>();

const rules = computed(() => ({
  email: {
    required: requiredValidator,
    email: emailValidator,
    $lazy: true
  }
}));

const validate = useVuelidate(rules, state);

function onCodeInput(value: string): void {
  validate.value.$reset();
  state.email = value;
}

function focus(): void {
  inputComponent.value?.focus();
}

async function onSubmit(): Promise<void> {
  if (state.isSubmitting) {
    return;
  }

  state.isSubmitting = true;

  validate.value.$touch();

  if (validate.value.$invalid) {
    state.isSubmitting = false;
    return;
  }

  try {
    await useAccount().sendOtpLogin(state.email);
  } catch (error) {
    useErrorModal().auto(
      new ExpectedError<ClientEECode>('sendOTP', { cause: error, sentryHandle: true })
    );
    return;
  } finally {
    state.isSubmitting = false;
  }

  emit('next', state.email);
}

onMounted(() => {
  focus();
});

const error = computed(() => {
  if (validate.value.email.required.$invalid) {
    return t('errors.requiredEmail');
  }

  if (validate.value.email.email.$invalid) {
    return t('errors.invalidEmail');
  }

  return undefined;
});
</script>

<template>
  <section class="auth-email">
    <UiTitle class="auth-email__title" :level="1">
      {{ t('onboarding.email.title') }}
    </UiTitle>
    <UiText class="auth-email__description" tag="p" muted>
      {{ t('onboarding.email.description') }}
    </UiText>

    <form class="auth-email__form" @submit.prevent="onSubmit">
      <UiInput
        ref="inputComponent"
        class="auth-email__input"
        :model-value="state.email"
        type="email"
        inputmode="numeric"
        :placeholder="t('onboarding.email.placeholder')"
        :note="t('onboarding.email.tip')"
        :note-icon="securityIcon"
        :clear="false"
        autocomplete="email"
        :error="error"
        @update:model-value="onCodeInput"
      />
      <UiButton
        class="auth-email__submit"
        type="submit"
        :disabled="state.email.length < 1"
        :loading="state.isSubmitting"
        @click="onSubmit"
      >
        {{ t('buttons.continue') }}
      </UiButton>
    </form>
  </section>
</template>

<style scoped>
.auth-email {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.auth-email__title {
  margin: 0 0 16px;
}

.auth-email__description {
  margin: 0 0 40px;
}

.auth-email__form:last-child {
  padding: 0 0 46px;
}

.auth-email__input {
  margin: 0 0 40px;
}
</style>
