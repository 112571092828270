<script setup lang="ts">
import { computed, type PropType } from 'vue';

import type {
  UiListPairGroup,
  UiListPairItem as UiListPairItemInfo,
  UiListPairMod
} from '@/components/ui/UiListPair.types';
import UiListPairItem from '@/components/ui/UiListPairItem.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

type UiListPairGroupExtended = UiListPairGroup & {
  radius: number;
};

const props = defineProps({
  details: {
    type: Array as PropType<UiListPairGroup[]>,
    default: () => []
  },
  mod: {
    type: String as PropType<UiListPairMod>,
    default: 'list'
  },
  inverted: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const emit = defineEmits<(event: 'click:item', value: UiListPairItemInfo) => void>();

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-list-pair_mod_${props.mod}`]: true,
    'ui-list-pair_inverted': props.inverted
  })
);

const processedDetails = computed((): UiListPairGroupExtended[] =>
  props.details.map((group) => {
    let radius = 0;

    switch (group.items.length) {
      case 0:
      case 1:
        radius = 12;
        break;
      case 2:
        radius = 14;
        break;
      case 3:
        radius = 16;
        break;
      case 4:
        radius = 18;
        break;
      default:
        radius = 20;
    }

    return {
      ...group,
      radius
    };
  })
);

function handleItemClick(item: UiListPairItemInfo): void {
  emit('click:item', item);
}
</script>

<template>
  <div class="ui-list-pair" :class="classList">
    <ul class="ui-list-pair__group-list">
      <li
        v-for="(group, i) in processedDetails"
        :key="group.name || i"
        class="ui-list-pair__group-item"
        :style="`--group-radius: ${group.radius}px`"
      >
        <UiTitle
          v-if="group.name && props.mod === 'table'"
          class="ui-list-pair__group-title"
          :level="3"
          size="sm"
          weight="medium"
        >
          {{ group.name }}
        </UiTitle>
        <ul class="ui-list-pair__list">
          <UiListPairItem
            v-for="item in group.items"
            :key="item.label"
            class="ui-list-pair__item"
            :item="item"
            @click:item="handleItemClick(item)"
          />
        </ul>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.ui-list-pair__group-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ui-list-pair__group-item:not(:last-child) {
  margin: 0 0 24px;
}

.ui-list-pair_mod_list .ui-list-pair__group-item:not(:last-child) {
  margin: 0 0 40px;
}

.ui-list-pair__group-title {
  padding: 8px 16px;
  color: var(--color-secondary-text);
  text-transform: uppercase;
  background-color: var(--color-secondary-contrast);
  border-radius: var(--group-radius) var(--group-radius) 0 0;
}

.ui-list-pair__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ui-list-pair_mod_table .ui-list-pair__list {
  padding: 0 16px;
  background-color: var(--color-main-underlay);
  border-radius: var(--group-radius);
}

.ui-list-pair_mod_table .ui-list-pair__list:not(:first-child) {
  border-radius: 0 0 var(--group-radius) var(--group-radius);
}

.ui-list-pair_mod_table.ui-list-pair_inverted .ui-list-pair__list {
  background-color: var(--color-main-stroke);
}

.ui-list-pair_mod_list .ui-list-pair__item:not(:last-child) {
  margin: 0 0 12px;
}

.ui-list-pair_mod_table .ui-list-pair__item {
  padding: 8px 0;
}

.ui-list-pair_mod_table .ui-list-pair__item:not(:last-child) {
  border-bottom: 1px solid var(--color-main-stroke);
}
</style>
