<script setup lang="ts">
import { ref } from 'vue';

import UiButtonIcon from '@/components/ui/UiButtonIcon.vue';

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const rootElement = ref<HTMLElement>();

function onClose(): void {
  emit('close');
}

function scrollTop(): void {
  if (!rootElement.value) {
    return;
  }

  rootElement.value.scrollTop = 0;
}

defineExpose({
  scrollTop
});
</script>

<template>
  <div ref="rootElement" class="card-info-details-wrapper">
    <div class="card-info-details-wrapper__inner">
      <UiButtonIcon
        class="card-info-details-wrapper__close-button"
        size="sm"
        mod="secondary"
        icon="cross"
        @click="onClose"
      />
      <slot />
    </div>
  </div>
</template>

<style scoped>
.card-info-details-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card-info-details-wrapper__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 80px 16px 24px;
  background-color: var(--color-main-underlay);
  border-radius: 30px;
}

.card-info-details-wrapper__close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
}
</style>
