import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
/**
 * Formats unix timestamp to string in format of `MMMM DD[,] HH:mm [UTC]`
 * @example
 * // outputs: "March 07, 07:47 UTC"
 * formatTimestampUTC(1709797633);
 * @param v - unix timestamp
 */
export function formatTimestampUTC(v: number): string {
  return dayjs.unix(v).utc().format('MMMM DD[,] HH:mm [UTC]');
}

/**
 * Formats unix timestamp to string in format of `MMMM DD`
 * @example
 * // outputs: "March 07"
 * formatTimestampUTC(1709797633);
 * @param v - unix timestamp
 */
export function formatTimestampDate(v: number): string {
  return dayjs.unix(v).format('MMMM DD');
}

export function formatAsHHMMSS(sec: number): string {
  if (sec < 0) {
    return '00:00';
  }
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.round(sec - hours * 3600 - minutes * 60);

  const h = hours < 10 ? `0${hours}` : `${hours}`;
  const m = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const s = seconds < 10 ? `0${seconds}` : `${seconds}`;

  if (hours) {
    return `${h}:${m}:${s}`;
  }

  return `${m}:${s}`;
}
