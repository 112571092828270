<script setup lang="ts">
import type { PropType } from 'vue';

import { type InfoBlockButton } from '@/components/InfoBlock.types';
import UiButton from '@/components/ui/UiButton.vue';
import UiButtonIcon from '@/components/ui/UiButtonIcon.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

const props = defineProps({
  title: {
    type: String as PropType<string>,
    default: ''
  },
  description: {
    type: String as PropType<string>,
    default: ''
  },
  closable: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  buttons: {
    type: Array as PropType<InfoBlockButton[]>,
    default: () => []
  }
});

const emit = defineEmits<{
  (event: 'hide'): void;
}>();
</script>

<template>
  <section class="info-block">
    <UiButtonIcon
      v-if="props.closable"
      class="info-block__icon-button"
      size="sm"
      mod="secondary"
      icon="cross"
      @click="emit('hide')"
    />
    <div class="info-block__body">
      <UiTitle class="info-block__title" :level="3">
        {{ props.title }}
      </UiTitle>
      <UiText class="info-block__description" size="sm" tag="p" muted>
        {{ props.description }}
      </UiText>
    </div>
    <ul class="info-block__buttons-list">
      <li v-for="button in props.buttons" :key="button.text" class="info-block__buttons-item">
        <UiButton
          class="info-block__button"
          :mod="button.mod"
          :disabled="button.disabled"
          :loading="button.loading"
          :href="button.href"
          :target="button.href ? '_blank' : undefined"
          @click="button.handler ? button.handler() : () => {}"
        >
          {{ button.text }}
        </UiButton>
      </li>
    </ul>
  </section>
</template>

<style scoped>
.info-block {
  position: relative;
}

.info-block__icon-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.info-block__icon-button ~ .info-block__body {
  padding: 0 56px 0 0;
}

.info-block__description {
  white-space: pre-wrap;
}

.info-block__buttons-list {
  display: flex;
  flex-wrap: wrap;
  margin: 24px -24px -16px 0;
  padding: 0;
  list-style-type: none;
}

@media (max-width: 919px) {
  .info-block__buttons-list {
    justify-content: space-between;
    margin: 24px 0 -16px;
  }
}

.info-block__buttons-item {
  margin: 0 24px 16px 0;
}

@media (max-width: 919px) {
  .info-block__buttons-item {
    width: calc(50% - 12px);
    margin: 0 0 16px;
  }
}

@media (max-width: 413px) {
  .info-block__buttons-item {
    width: 100%;
  }
}

@media (max-width: 919px) {
  .info-block__buttons-item:first-child:last-child {
    width: 100%;
  }
}
</style>
