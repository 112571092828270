<script setup lang="ts">
import type { PropType } from 'vue';
import { computed, useSlots } from 'vue';
import { type RouteLocationRaw, RouterLink, useRouter } from 'vue-router';

import UiIcon from '@/components/ui/UiIcon.vue';
import type { UiLinkButtonType, UiLinkMod, UiLinkTarget } from '@/components/ui/UiLink.types';
import type { UiTextSize, UiTextWeight } from '@/components/ui/UiText.types';
import UiText from '@/components/ui/UiText.vue';

const props = defineProps({
  href: {
    type: String as PropType<string>,
    default: undefined
  },
  route: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    default: undefined
  },
  icon: {
    type: String as PropType<string>,
    default: undefined
  },
  gap: {
    type: Number as PropType<number>,
    default: 8
  },
  target: {
    type: String as PropType<UiLinkTarget>,
    default: '_self'
  },
  type: {
    type: String as PropType<UiLinkButtonType>,
    default: 'button'
  },
  size: {
    /* Desktop&Mobile | [Desktop, Mobile]. */
    type: [String, Array] as PropType<UiTextSize | [UiTextSize, UiTextSize]>,
    default: undefined
  },
  iconSize: {
    /* Width&Height | [Width, Height]. */
    type: [Number, Array] as PropType<number | [number, number]>,
    default: undefined
  },
  mod: {
    type: String as PropType<UiLinkMod>,
    default: 'default'
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  weight: {
    type: String as PropType<UiTextWeight>,
    default: 'bold'
  },
  underlined: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const slots = useSlots();
const router = useRouter();

const tag = computed((): 'a' | 'button' | typeof RouterLink =>
  props.route ? RouterLink : props.href ? 'a' : 'button'
);

const link = computed((): string | undefined =>
  props.route ? router.resolve(props.route).href : props.href
);

const gapWithPx = computed((): string => `${props.gap}px`);

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-link_mod_${props.mod}`]: true,
    'ui-link_underlined': props.underlined
  })
);
</script>

<template>
  <component
    :is="tag"
    class="ui-link"
    :class="classList"
    :href="link"
    :to="props.route"
    :target="tag === 'a' ? props.target : undefined"
    :rel="props.target === '_blank' ? 'noopener norefferer' : undefined"
    :type="tag === 'button' ? props.type : undefined"
    :disabled="tag === 'button' ? props.disabled : undefined"
  >
    <slot v-if="slots.prefix" name="prefix" />
    <UiIcon
      v-else-if="props.icon"
      class="ui-link__icon"
      :name="props.icon"
      :size="props.iconSize"
    />
    <UiText class="ui-link__content" :size="props.size" :weight="props.weight">
      <slot />
    </UiText>
    <slot v-if="slots.postfix" name="postfix" />
  </component>
</template>

<style scoped>
.ui-link {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-decoration: none;
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: opacity var(--animation-micro) var(--animation-effect);
  appearance: none;
}

.ui-link:disabled {
  cursor: not-allowed;
  opacity: 0.25;
}

.ui-link_underlined:not(:disabled):hover {
  text-decoration: underline;
}

.ui-link_mod_default {
  color: inherit;
}

.ui-link_mod_contrasty {
  color: var(--color-purple);
}

.ui-link_mod_negative {
  color: var(--color-red);
}

.ui-link__icon {
  flex-shrink: 0;
}

.ui-link__content {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-link__content:not(:first-child) {
  margin-left: v-bind(gapWithPx);
}

.ui-link__content:not(:last-child) {
  margin-right: v-bind(gapWithPx);
}
</style>
