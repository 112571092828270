<script setup lang="ts">
import type { PropType } from 'vue';
import { computed } from 'vue';

import type {
  UiButtonIconLinkTarget,
  UiButtonIconMod,
  UiButtonIconSize
} from '@/components/ui/UiButtonIcon.types';
import UiIcon from '@/components/ui/UiIcon.vue';

const props = defineProps({
  icon: {
    type: String as PropType<string>,
    required: true
  },
  href: {
    type: String as PropType<string>,
    default: undefined
  },
  target: {
    type: String as PropType<UiButtonIconLinkTarget>,
    default: '_self'
  },
  mod: {
    type: String as PropType<UiButtonIconMod>,
    default: 'primary'
  },
  size: {
    type: String as PropType<UiButtonIconSize>,
    default: 'md'
  },
  iconSize: {
    type: Number as PropType<number>,
    default: 24
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const tag = computed((): 'a' | 'button' => (props.href ? 'a' : 'button'));

const classList = computed(
  (): Record<string, boolean> => ({
    [`ui-button-icon_mod_${props.mod}`]: true,
    [`ui-button-icon_size_${props.size}`]: true,
    [`ui-button-icon_disabled`]: props.disabled
  })
);
</script>

<template>
  <component
    :is="tag"
    class="ui-button-icon"
    :class="classList"
    :href="props.href"
    :target="tag === 'a' ? props.target : undefined"
    :type="tag === 'button' ? 'button' : undefined"
    :disabled="props.disabled"
  >
    <UiIcon class="ui-button-icon__icon" :name="props.icon" :size="props.iconSize" />
    <slot />
  </component>
</template>

<style scoped>
.ui-button-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity var(--animation-micro) var(--animation-effect);
  appearance: none;
  user-select: none;
}

.ui-button-icon_size_md {
  width: 40px;
  height: 40px;
}

.ui-button-icon_size_sm {
  width: 24px;
  height: 24px;
}

.ui-button-icon_mod_primary {
  color: var(--color-secondary-text);
  background: var(--color-main-underlay);
}

.ui-button-icon_mod_secondary {
  color: var(--color-secondary-text);
  background: var(--color-secondary-contrast);
}

.ui-button-icon_mod_transparent {
  color: var(--color-main-text);
  background: transparent;
}

.ui-button-icon_mod_transparent-muted {
  color: var(--color-secondary-text);
  background: transparent;
}

.ui-button-icon_mod_inverted {
  color: var(--color-background);
  background: var(--color-main-text);
  box-shadow: var(--shadow-base);
}

.ui-button-icon_mod_white-transparent {
  color: var(--color-white);
  background: var(--color-white-transparent);
}

.ui-button-icon:disabled,
.ui-button-icon_disabled {
  cursor: not-allowed;
  opacity: 0.25;
}
</style>
