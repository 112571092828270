import { currentClientType } from '@/helpers/utils';
import { HHAPIBrrrService } from '@/references/axios/brrr/HHAPIBrrrService';
import { getEndpoint } from '@/references/endpoints';

let service: HHAPIBrrrService | undefined;
export const useBrrrAPIService = (): HHAPIBrrrService => {
  if (service === undefined) {
    service = new HHAPIBrrrService({
      baseURL: getEndpoint('apiBrrr'),
      dataProviderBaseURL: 'todo', //TODO
      clientType: currentClientType()
    });
  }

  return service;
};
