<script setup lang="ts">
interface Props {
  blinking?: boolean;
}

const props = defineProps<Props>();
</script>

<template>
  <span class="ui-blinker" :class="{ 'ui-blinker_animated': props.blinking }">
    <slot />
  </span>
</template>

<style scoped>
.ui-blinker_animated {
  transition: opacity var(--animation-long) var(--animation-effect);
  animation: blink 1s infinite linear;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}
</style>
