<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import type { AnimationItem } from 'lottie-web';
import lottie from 'lottie-web';

import type { UiLottieData } from '@/components/ui/UiLottie.types';

interface Props {
  size?: number;
  data: UiLottieData;
  autoplay?: boolean;
  loop?: boolean;
  speed?: number;
  name?: string;
  reverse?: boolean;
  lastFrame?: number;
}

const props = withDefaults(defineProps<Props>(), {
  name: 'lottie-' + Math.random(),
  speed: 1,
  size: 24,
  autoplay: false,
  loop: false,
  lastFrame: undefined
});

const emit = defineEmits<{
  (e: 'init', value: AnimationItem): void;
  (e: 'complete'): void;
}>();

let instance: AnimationItem | null = null;

const root = ref<HTMLElement | null>(null);

const sizeInPx = computed((): string => `${props.size}px`);

onMounted((): void => {
  instance = lottie.loadAnimation({
    container: root.value as HTMLElement,
    renderer: 'svg',
    name: props.name,
    loop: props.loop,
    autoplay: props.autoplay,
    animationData: props.data
  });

  instance.setDirection(props.reverse ? -1 : 1);
  instance.setSpeed(props.speed);

  if (!props.autoplay && props.reverse && props.lastFrame) {
    instance.goToAndStop(props.lastFrame, true);
  }

  instance.addEventListener('complete', () => {
    emit('complete');
  });

  emit('init', instance);
});

function play(): void {
  instance?.play();
}

function pause(): void {
  instance?.play();
}

function stop(): void {
  instance?.play();
}

defineExpose({
  play,
  pause,
  stop
});
</script>

<template>
  <span ref="root" class="ui-lottie"></span>
</template>

<style scoped>
.ui-lottie {
  display: block;
  width: v-bind(sizeInPx);
  height: v-bind(sizeInPx);
}

.ui-lottie:deep(svg) {
  display: block;
  margin: 0 auto;
}
</style>
