import BigNumber from 'bignumber.js';

export type NumericValue = BigNumber.Value | bigint;

export function toBigNumber(value: NumericValue): BigNumber {
  if (typeof value === 'bigint') {
    return new BigNumber(value.toString(10), 10);
  }

  if (value instanceof BigNumber) {
    return value;
  }

  return BigNumber(value);
}

export const add = (numberOne: NumericValue, numberTwo: NumericValue): string =>
  toBigNumber(numberOne).plus(toBigNumber(numberTwo)).toFixed();
export const sub = (numberOne: NumericValue, numberTwo: NumericValue): string =>
  toBigNumber(numberOne).minus(toBigNumber(numberTwo)).toFixed();

export const multiply = (x: NumericValue, y: NumericValue): string =>
  toBigNumber(x).times(toBigNumber(y)).toFixed();

export const divide = (numberOne: NumericValue, numberTwo: NumericValue): string => {
  if (!(numberOne || numberTwo)) return '0';
  return toBigNumber(numberOne).dividedBy(toBigNumber(numberTwo)).toFixed();
};

export const greaterThan = (numberOne: NumericValue, numberTwo: NumericValue): boolean =>
  toBigNumber(numberOne).gt(toBigNumber(numberTwo));

export const greaterThanOrEqual = (numberOne: NumericValue, numberTwo: NumericValue): boolean =>
  toBigNumber(numberOne).gte(toBigNumber(numberTwo));

export const lessThan = (numberOne: NumericValue, numberTwo: NumericValue): boolean =>
  toBigNumber(numberOne).lt(toBigNumber(numberTwo));

export const lessThanOrEqual = (numberOne: NumericValue, numberTwo: NumericValue): boolean =>
  toBigNumber(numberOne).lte(toBigNumber(numberTwo));

export const isZero = (value: NumericValue): boolean => toBigNumber(value).isZero();

export const notZero = (numberOne: NumericValue): boolean => toBigNumber(numberOne).gt('0');

export const toWei = (value: NumericValue, decimals: number | string): string =>
  toBigNumber(value).times(toBigNumber(10).pow(decimals)).toFixed();

export const fromWei = (value: NumericValue, decimals: number | string): string =>
  toBigNumber(value).dividedBy(toBigNumber(10).pow(decimals)).toFixed();
export const isNaN = (num: NumericValue): boolean => {
  return toBigNumber(num).isNaN();
};
export const roundToPrecision = (value: NumericValue, decimals = 18): string => {
  return toBigNumber(value).toFixed(decimals, BigNumber.ROUND_DOWN);
};

export const convertAmountFromNativeValue = (
  value: NumericValue,
  priceUnit: NumericValue,
  decimals = 18
): string => {
  if (isZero(priceUnit)) return '0';
  return toBigNumber(
    toBigNumber(value).dividedBy(toBigNumber(priceUnit)).toFixed(decimals, BigNumber.ROUND_DOWN)
  ).toFixed();
};

export const convertNativeAmountFromAmount = (
  value: NumericValue,
  priceUnit: NumericValue
): string => {
  if (isZero(priceUnit)) return '0';
  return toBigNumber(
    toBigNumber(value).times(toBigNumber(priceUnit)).toFixed(18, BigNumber.ROUND_DOWN)
  ).toFixed();
};

export function roundDown(value: NumericValue, decimals: number): string {
  return toBigNumber(value).toFixed(decimals, BigNumber.ROUND_DOWN);
}

export function roundUp(value: NumericValue, decimals: number): string {
  return toBigNumber(value).toFixed(decimals, BigNumber.ROUND_UP);
}

export function isEqual(left: NumericValue, right: NumericValue): boolean {
  return toBigNumber(left).eq(toBigNumber(right));
}

/**
 * Sorts the elements of an array in place
 *  and returns the reference to the same array, now sorted
 * @param values - items
 */
export function sort<T extends NumericValue>(values: T[]): T[] {
  if (values.length === 0) {
    return values;
  }

  return values.sort((a, b) => {
    if (isEqual(a, b)) {
      return 0;
    }

    return lessThan(a, b) ? -1 : 1;
  });
}

/**
 * Returns max (by value) item of provided array.
 *
 * Does not modify an array
 * @throws Error if array is empty
 * @param values - items
 */
export function max<T extends NumericValue>(values: T[]): T {
  if (values.length === 0) {
    throw new Error('An array must have at least 1 item');
  }

  return sort(values.slice()).at(-1)!;
}

/**
 * Returns min (by value) item of provided array.
 *
 * Does not modify an array
 *
 * @throws Error if array is empty
 * @param values - items
 */
export function min<T extends NumericValue>(values: T[]): T {
  if (values.length === 0) {
    throw new Error('An array must have at least 1 item');
  }

  return sort(values.slice())[0];
}

/**
 * Converts {@link NumericValue} to {@link BigInt} instance by truncating the value down
 *
 * Loses decimals (precision)
 * @param value - value to convert
 */
export function toBigInt(value: NumericValue): bigint {
  return BigInt(roundDown(value, 0));
}
