<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';

import resendIcon from '@/assets/icons/resend.svg';
import securityIcon from '@/assets/icons/security.svg';
import { useAccount } from '@/composables/useAccount';
import { type ClientEECode, useErrorModal } from '@/composables/useErrorModal';
import { APIError } from '@/references/axios/APIError';
import { SECOND } from '@/references/constants';
import { ExpectedError } from '@/references/ExpectedError';
import { useI18n } from '@/references/i18n';

import UiButton from '@/components/ui/UiButton.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiLink from '@/components/ui/UiLink.vue';
import UiText from '@/components/ui/UiText.vue';
import UiTitle from '@/components/ui/UiTitle.vue';

const MASK = '000 - 000';
const START_TIMER_VALUE_FOR_RESEND = 59;

const { t } = useI18n();
const toast = useErrorModal();

interface Props {
  email: string;
}

const props = defineProps<Props>();

const state = reactive<{
  code: string;
  isSending: boolean;
  isResending: boolean;
  isError: boolean;
  timer: number;
}>({
  code: '',
  isSending: false,
  isResending: false,
  isError: false,
  timer: 0
});

let resendInterval: ReturnType<typeof setTimeout> | null = null;

const resendText = computed(() =>
  state.timer
    ? t('buttons.resendIn', { time: `00:${`${state.timer}`.padStart(2, '0')}` })
    : t('buttons.resend')
);

function reset(): void {
  state.code = '';
  inputComponent.value?.focus();
}

function stopTimer(): void {
  state.timer = 0;
  if (resendInterval) {
    clearInterval(resendInterval);
    resendInterval = null;
  }
}

function startTimer(): void {
  stopTimer();
  state.timer = START_TIMER_VALUE_FOR_RESEND;
  resendInterval = setInterval(() => {
    if (state.timer) {
      state.timer -= 1;
    } else {
      stopTimer();
    }
  }, SECOND);
}

function restart(): void {
  reset();
  startTimer();
}

async function resendOtp(): Promise<void> {
  try {
    state.isResending = true;
    await useAccount().resendOtpLogin(props.email);
    toast.notify(t('toasts.codeResent'));
    restart();
  } catch (error) {
    useErrorModal().auto(
      new ExpectedError<ClientEECode>('resendOTP', { cause: error, sentryHandle: true })
    );
  } finally {
    state.isResending = false;
  }
}

const inputComponent = ref<typeof UiInput>();

function setError(): void {
  setTimeout(() => {
    focus();

    if (!state.isError) {
      state.isError = true;
    } else {
      inputComponent.value?.shake();
    }
  });
}

async function sendCode(): Promise<void> {
  const { auth } = useAccount();

  try {
    state.isSending = true;
    await auth(props.email, state.code.replace(/[^0-9]+/g, ''));
  } catch (error) {
    reset();

    if (error instanceof APIError) {
      if (error.code === 'WRONG_PASSWORD') {
        setError();
        return;
      }
    }
    toast.auto('Alarma! Something went wrong');
  } finally {
    state.isSending = false;
  }
}

function focus(): void {
  inputComponent.value?.focus();
}

function onSubmit(): void {
  if (state.code.length !== MASK.length) {
    return;
  }

  sendCode();
}

onMounted(() => {
  restart();
  inputComponent.value?.focus();
});
</script>

<template>
  <section class="auth-otp">
    <UiTitle class="auth-otp__title" :level="1">
      {{ t('onboarding.code.title') }}
    </UiTitle>
    <UiText class="auth-otp__description" tag="p" muted>
      {{ t('onboarding.code.description') }}
    </UiText>

    <form class="auth-otp__form" @submit.prevent="onSubmit">
      <UiInput
        ref="inputComponent"
        v-model="state.code"
        class="auth-otp__input"
        :placeholder="t('onboarding.code.placeholder')"
        :mask="{ mask: MASK }"
        :disabled="state.isSending || state.isResending"
        :note="t('onboarding.code.tip')"
        :note-icon="securityIcon"
        :clear="false"
        autocomplete="one-time-code"
        :error="state.isError ? t('errors.invalidConfirmationCode') : undefined"
      />
      <UiButton
        class="auth-otp__submit"
        type="submit"
        :disabled="state.code.length < MASK.length"
        :loading="state.isSending || state.isResending"
        @click="onSubmit"
      >
        {{ t('buttons.continue') }}
      </UiButton>
    </form>
    <UiLink
      class="auth-otp__link"
      :icon="resendIcon"
      :icon-size="22"
      size="sm"
      :disabled="!!state.timer || state.isResending"
      weight="semibold"
      mod="contrasty"
      @click="resendOtp"
    >
      {{ resendText }}
    </UiLink>
  </section>
</template>

<style scoped>
.auth-otp {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.auth-otp__title {
  margin: 0 0 16px;
}

.auth-otp__description {
  margin: 0 0 40px;
}

.auth-otp__form {
  margin: 0 0 24px;
}

.auth-otp__form:last-child {
  padding: 0 0 46px;
}

.auth-otp__input {
  margin: 0 0 40px;
}

.auth-otp__link {
  margin: 0 auto;
}
</style>
